import React from "react";
import { List, Datagrid, TextField, Filter, DateInput, DateField } from "react-admin";

import { UserField } from "../../fields/user-field";
import { ILetterListRecord } from "../../../model/graphql/adaptor/letter/records";

import { LetterListFilter } from "./list-filter";

// import { OrderListFilter } from "./list-filter";

/**
 * List top filters
 * @param props component props
 */
const ListFilters: React.FC = props => (
  <Filter {...props}>
    <DateInput source="dateFrom" label="From" alwaysOn />
    <DateInput source="dateTo" label="To" alwaysOn />
  </Filter>
);

/**
 * List of letters
 * @param props Component props
 */
export const LetterList: React.FC<{ record: ILetterListRecord }> = props => (
  <List
    {...props}
    aside={<LetterListFilter />}
    filters={<ListFilters />}
    perPage={25}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <UserField label="Customer" />
      {/* <ProductListField label="Products" />
      <NumberField source="total" label="Total" options={{ style: "currency", currency: "GBP" }} /> */}
      <DateField source="sendDate" label="Send Date" />
      <DateField source="timeCreated" label="Time Booked" showTime />
    </Datagrid>
  </List>
);
