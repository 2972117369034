import React from "react";
import { Show, SimpleShowLayout, DateField, TextField, useShowController } from "react-admin";
import { makeStyles, CircularProgress } from "@material-ui/core";

import { UserField } from "../../fields/user-field";
import { ProductTableField } from "../../fields/product-table-field";
import { IOrderRecord } from "../../../model/graphql/adaptor/order/records";

const useStyles = makeStyles({
  container: {
    "& div.MuiFormControl-root": {
      minWidth: "100%",
    },
  },
});

interface IProps {
  record: IOrderRecord;
}

/**
 * Show component for order
 * @param props Component props
 */
export const OrderShow: React.FC<IProps> = props => {
  const classes = useStyles();
  const { loading } = useShowController(props);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField label="ID" source="id" />
        <UserField />
        <DateField source="timeCreated" label="Time placed" showTime />
        <ProductTableField className={classes.container} label="Details" addLabel />
      </SimpleShowLayout>
    </Show>
  );
};
