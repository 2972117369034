import { GetTimezonesDocument, GetTimezonesQuery } from "../../../../types/graphql";
import { AnyQueryArgs, ProviderRequestMethod, GetListQueryResolver } from "../types";

export interface ITimezoneRecord {
  id: string;
  timezone: string;
}

const timezoneListQuery: GetListQueryResolver<GetTimezonesQuery, ITimezoneRecord> = () => ({
  query: GetTimezonesDocument,
  variables: {},
  parseResponse: ({ data }) => ({
    data:
      data.timezones?.map(t => ({
        id: t.urn,
        timezone: t.timezone,
      })) || [],
    total: data.timezones?.length || 0,
  }),
});

export const timezoneQuery = (fetchType: ProviderRequestMethod): AnyQueryArgs => {
  switch (fetchType) {
    case "GET_LIST":
      return timezoneListQuery({});
    default:
      throw new Error(`fetchType ${fetchType} not recognised for calls`);
  }
};
