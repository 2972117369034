import * as datefns from "date-fns";
import { extractIdFromUrn, UrnResource } from "@santa/common/lib/utils/urn";

import {
  ProductAlphaId,
  SearchLettersQuery,
  SearchLettersQueryVariables,
} from "../../../../../types/graphql";

import { ILetterListRecord } from "../records";
import { IQueryGetListVariables, GetListQueryResolver } from "../../types";
import { SearchLettersDocument } from "../../../../../types/graphql";

interface ILetterFilter {
  q: string;
  product?: ProductAlphaId;
  dateFrom?: string;
  dateTo?: string;
}

export type LetterGetListVariables = IQueryGetListVariables<ILetterFilter>;

/**
 * Get letters query adaptor
 * @param variables query variables
 */
export const letterGetListQuery: GetListQueryResolver<
  SearchLettersQuery,
  ILetterListRecord,
  SearchLettersQueryVariables,
  LetterGetListVariables
> = variables => ({
  query: SearchLettersDocument,
  variables: {
    ...(variables?.filter.q && { query: variables.filter.q }),
    ...(variables?.filter.product && { products: [variables.filter.product] }),
    ...(variables?.filter.dateFrom && { dateFrom: variables.filter.dateFrom }),
    ...(variables?.filter.dateTo && { dateTo: variables.filter.dateTo }),
    ...(variables?.pagination.perPage && { size: variables.pagination.perPage }),
  },
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  parseResponse: response => ({
    data: response.data.letters.edges.map(o => {
      const letter = o.node;
      return {
        id: extractIdFromUrn(UrnResource.LETTER, letter.urn),
        urn: letter.urn,
        user: {
          urn: letter.user.urn,
          email: letter.user.email,
          firstName: letter.user.firstName || "",
          lastName: letter.user.lastName || "",
        },
        title: letter.orderItem.product.title || "",
        price: letter.orderItem.price,
        sendDate: datefns.parseISO(letter.date),
        description: "-",
        timeCreated: datefns.parseISO(letter.timeCreated),
      };
    }),
    total: response.data.letters.totalCount || 0,
  }),
});
