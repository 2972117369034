import Amplify from "aws-amplify";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import { App } from "./components/app";
import { config } from "./config";

const originalLog = console.error;

/**
 * Custom error logger
 * @param args Args for logger
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
console.error = (...args: [any?, ...any[]]): void => {
  if (
    args.length > 0 &&
    typeof args[0] === "string" &&
    /^Warning: Missing translation/.test(args[0])
  ) {
    return;
  }
  originalLog.apply(console, args);
};

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.region,
    userPoolId: config.cognito.userPoolId,
    userPoolWebClientId: config.cognito.appClientId,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
});

ReactDOM.render(<App />, document.getElementById("root"));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((module as any).hot) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (module as any).hot.accept("./components/app", () => {
    // eslint-disable-next-line global-require
    const NextApp = require("./components/app").App;
    ReactDOM.render(<NextApp />, document.getElementById("root"));
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// eslint-disable-next-line no-console
serviceWorker.unregister().catch(console.error);
