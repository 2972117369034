import { createUrn, UrnResource } from "@santa/common/lib/utils/urn";

import { ICallRecord, mapToCall } from "../record";
import { GetOneQueryResolver } from "../../types";
import { GetCallDocument, GetCallQuery, GetCallQueryVariables } from "../../../../../types/graphql";

/**
 * Get call query adaptor
 * @param variables query variables
 */
export const getCallQuery: GetOneQueryResolver<GetCallQuery, ICallRecord, GetCallQueryVariables> =
  ({ id }) => ({
    query: GetCallDocument,
    variables: {
      urn: createUrn(UrnResource.CALL, id),
    },
    parseResponse: response => {
      const { call } = response.data;

      if (!call) {
        return Promise.reject(new Error("Call not found"));
      }

      return { data: mapToCall(call) };
    },
  });
