import { extractIdFromUrn, UrnResource } from "@santa/common/lib/utils/urn";
import * as dateFns from "date-fns";

import {
  SearchOrdersQueryVariables,
  SearchOrdersQuery,
  ProductAlphaId,
  SearchOrdersDocument,
} from "../../../../../types/graphql";
import { IOrderRecord, createBraintreeUrl } from "../records";
import { IQueryGetListVariables, GetListQueryResolver } from "../../types";

export interface IOrderFilter {
  q: string;
  product?: ProductAlphaId;
  dateFrom?: string;
  dateTo?: string;
}

export type OrderGetListVariables = IQueryGetListVariables<IOrderFilter>;

/**
 * Get orders query
 * @param variables query variables
 */
export const orderGetListQuery: GetListQueryResolver<
  SearchOrdersQuery,
  IOrderRecord,
  SearchOrdersQueryVariables,
  OrderGetListVariables
> = variables => ({
  query: SearchOrdersDocument,
  variables: {
    ...(variables?.filter.q && { query: variables.filter.q }),
    ...(variables?.filter.product && { products: [variables.filter.product] }),
    ...(variables?.filter.dateFrom && { dateFrom: variables.filter.dateFrom }),
    ...(variables?.filter.dateTo && { dateTo: variables.filter.dateTo }),
    ...(variables?.pagination.perPage && { size: variables.pagination.perPage }),
    ...(variables?.pagination.perPage &&
      variables?.pagination.page && {
        from: (variables.pagination.page - 1) * variables.pagination.perPage,
      }),
  },
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  parseResponse: response => ({
    data: response.data.orders.edges.map(o => ({
      id: extractIdFromUrn(UrnResource.ORDER, o.node.urn),
      urn: o.node.urn,
      user: {
        urn: o.node.user.urn,
        email: o.node.user.email,
        name: {
          first: o.node.user.firstName || "",
          last: o.node.user.lastName || "",
        },
      },
      total: o.node.total,
      products: o.node.orderItems.map(i => ({
        alphaId: i.productAlphaId as ProductAlphaId,
        title: "Santa Letter",
        description: "",
        price: 0,
        addOns: [],
        link: "",
        id: "",
      })),
      ...(o.node.paymentTransaction && {
        payment: {
          url: createBraintreeUrl(o.node.paymentTransaction.urn),
          type: o.node.paymentTransaction.type,
          description: o.node.paymentTransaction.description,
          amount: o.node.paymentTransaction.amount,
        },
      }),
      refunds: [],
      timeCreated: dateFns.parseISO(o.node.timeCreated),
    })),
    total: response.data.orders.totalCount || 0,
  }),
});
