import { ICallRecord, mapToCall } from "../record";
import { IQueryGetListVariables, GetListQueryResolver } from "../../types";
import {
  SearchCallsDocument,
  SearchCallsQuery,
  SearchCallsQueryVariables,
} from "../../../../../types/graphql";

export interface ICallFilter {
  q: string;
  timeFrom?: string;
  timeTo?: string;
  santaUrn?: string;
}

export type CallGetListVariables = IQueryGetListVariables<ICallFilter>;

/**
 * Get calls query adaptor
 * @param variables query variables
 */
export const callGetListQuery: GetListQueryResolver<
  SearchCallsQuery,
  ICallRecord,
  SearchCallsQueryVariables,
  CallGetListVariables
> = variables => ({
  query: SearchCallsDocument,
  variables: {
    ...(variables?.filter.q && { query: variables.filter.q }),
    ...(variables?.filter.timeFrom && { timeFrom: variables.filter.timeFrom }),
    ...(variables?.filter.timeTo && { timeTo: variables.filter.timeTo }),
    ...(variables?.pagination.perPage && { size: variables.pagination.perPage }),
    ...(variables?.filter.santaUrn && { santaUrn: variables.filter.santaUrn }),
    ...(variables?.pagination.perPage &&
      variables?.pagination.page && {
        from: (variables.pagination.page - 1) * variables.pagination.perPage,
      }),
  },
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  parseResponse: response => ({
    data: response.data.calls.edges.map(o => mapToCall(o.node)),
    total: response.data.calls.totalCount || 0,
  }),
});
