import { extractIdFromUrn, UrnResource } from "@santa/common/lib/utils/urn";

import { ProductAlphaId } from "../../../../../types/graphql";
import { config } from "../../../../../config";
import { IRecordObject } from "../../types";

export interface IOrderProduct {
  title?: string;
  alphaId: ProductAlphaId;
  description?: string;
  price: number;
  addOns: string[];
  link: string;
  id: string;
}

export interface ITransaction {
  url: string;
  type: string;
  description: string;
  amount: number;
}

export interface IOrderRecord extends IRecordObject {
  urn: string;
  user: {
    urn: string;
    email: string;
    name: {
      first: string;
      last: string;
    };
  };
  total: number;
  products: IOrderProduct[];
  payment?: ITransaction;
  promoCode?: {
    code: string;
    discount: number;
  };
  refunds: ITransaction[];
  timeCreated: Date;
}

/**
 * Get a Braintree URL from a transaction URN
 * @param urn URN of transaction
 */
export const createBraintreeUrl = (urn: string): string => {
  const id = extractIdFromUrn(UrnResource.TRANSACTION, urn);

  const hostnamePrefix = config.braintree.environment === "sandbox" ? "sandbox." : "";

  return `https://${hostnamePrefix}braintreegateway.com/merchants/${config.braintree.accountId}/transactions/${id}`;
};

export interface ILinkOrderItem {
  productAlphaId: string;
  letter?: {
    urn: string;
  } | null;
  call?: {
    urn: string;
  } | null;
  text?: {
    urn: string;
  } | null;
  sack?: {
    urn: string;
  } | null;
  video?: {
    urn: string;
  } | null;
}

/**
 * Get the id for a product
 * @param i Item to get id for
 */
export const getProductId = (i: ILinkOrderItem): string => {
  switch (i.productAlphaId) {
    case ProductAlphaId.SANTA_LETTER: {
      if (i.letter) {
        return extractIdFromUrn(UrnResource.LETTER, i.letter?.urn);
      }
      break;
    }
    case ProductAlphaId.SANTA_CALL:
    case ProductAlphaId.SANTA_CALL_VIDEO: {
      if (i.call) {
        return extractIdFromUrn(UrnResource.CALL, i.call?.urn);
      }
      break;
    }
    // no default
  }

  // TODO: change this to throw in default
  return "";
};

/**
 * Get a link to the view page for a product
 * @param i Item to make link for
 */
export const getProductLink = (i: ILinkOrderItem): string => {
  switch (i.productAlphaId) {
    case ProductAlphaId.SANTA_LETTER: {
      if (i.letter) {
        return `/letters/${extractIdFromUrn(UrnResource.LETTER, i.letter?.urn)}`;
      }
      break;
    }
    case ProductAlphaId.SANTA_CALL:
    case ProductAlphaId.SANTA_CALL_VIDEO: {
      if (i.call) {
        return `/calls/${extractIdFromUrn(UrnResource.CALL, i.call?.urn)}/show`;
      }
      break;
    }
    // no default
  }

  // TODO: change this to throw in default
  return "";
};
