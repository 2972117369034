import * as React from "react";
import { Chip } from "@material-ui/core";
import { useRecordContext } from "react-admin";
import { CallType } from "../../types/graphql";

interface IRecord {
  type: CallType;
}

interface IProps {
  label?: string;
  sortable?: boolean;
}

export const CallTypeField: React.FC<IProps> = () => {
  const record: IRecord = useRecordContext();

  if (!record) {
    return null;
  }

  const { type } = record;

  return <Chip size="small" label={type.toUpperCase()} />;
};
