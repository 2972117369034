import * as React from "react";
import { Chip, Avatar } from "@material-ui/core";
import { useRecordContext, Labeled } from "react-admin";

interface IRecord {
  user?: {
    name?: {
      first: string;
      last: string;
    };
    email: string;
  };
}

interface IProps {
  label?: string;
}

/**
 * User field component
 * @param props component props
 */
export const UserField: React.FC<IProps> = () => {
  const record: IRecord = useRecordContext();
  const { user } = record;

  if (!user) {
    return null;
  }
  if (user.name?.first && user.name?.last) {
    return (
      <Labeled resource="users" source="name">
        <Chip
          size="small"
          avatar={
            <Avatar>{`${user.name.first.substr(0, 1).toUpperCase()}${user.name.last
              .substr(0, 1)
              .toUpperCase()}`}</Avatar>
          }
          label={`${user.name.first} ${user.name.last}`}
        />
      </Labeled>
    );
  }

  return (
    <Chip
      size="small"
      avatar={<Avatar>{`${user.email.substr(0, 1).toUpperCase()}`}</Avatar>}
      label={`${user.email}`}
    />
  );
};
