import { GetLetterTemplatesDocument, GetLetterTemplatesQuery } from "../../../../types/graphql";
import { AnyQueryArgs, ProviderRequestMethod, GetListQueryResolver, IRecordObject } from "../types";

export interface ILetterTemplate extends IRecordObject {
  label: string;
}

const listQuery: GetListQueryResolver<GetLetterTemplatesQuery, ILetterTemplate> = () => ({
  query: GetLetterTemplatesDocument,
  variables: {},
  parseResponse: ({ data }) => ({
    data: data.letterTemplates.map(({ id, label }) => ({ id, label: label || "" })),
    total: data.letterTemplates.length,
  }),
});

export const letterTemplateQuery = (fetchType: ProviderRequestMethod): AnyQueryArgs => {
  switch (fetchType) {
    case ProviderRequestMethod.GET_LIST:
      return listQuery();
    default:
      throw new Error(`fetchType ${fetchType} not recognised for calls`);
  }
};
