export enum Resource {
  ORDERS = "orders",
  LETTERS = "letters",
  CALLS = "calls",
  SANTAS = "santas",
  LETTER_SIGNATURES = "letter-signatures",
  LETTER_TEMPLATES = "letter-templates",
  FRONT_DOORS = "front-doors",
  PETS = "pets",
  LETTER_POSTSCRIPTS = "letter-ps",
  TIMEZONES = "timezones",
  COUNTRIES = "countries",
}
