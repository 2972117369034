import {
  GetSantasQueryVariables,
  GetSantasQuery,
  GetSantasDocument,
} from "../../../../types/graphql";
import { ISantaRecord } from "./records";
import { IQueryGetListVariables, GetListQueryResolver } from "../types";

export interface IOrderFilter {}

export type OrderGetListVariables = IQueryGetListVariables<IOrderFilter>;

/**
 * Get orders query
 * @param variables query variables
 */
export const santaGetListQuery: GetListQueryResolver<
  GetSantasQuery,
  ISantaRecord,
  GetSantasQueryVariables,
  OrderGetListVariables
> = () => ({
  query: GetSantasDocument,
  variables: {},
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  parseResponse: response => ({
    data: response.data.santas.edges.map(({ node: s }) => ({
      id: s.urn,
      urn: s.urn,
      email: s.email,
      name: {
        first: s.firstName || "",
        last: s.lastName || "",
      },
    })),
    total: response.data.santas.edges.length,
  }),
});
