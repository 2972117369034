import { Typography } from "@material-ui/core";
import { useRecordContext } from "react-admin";

interface IRecord {
  pet?: {
    label?: string;
    name?: string;
  };
  pet1?: {
    label?: string;
    name?: string;
  };
  pet2?: {
    label?: string;
    name?: string;
  };
}

interface IProps {
  source?: keyof IRecord;
}

export const PetField: React.FC<IProps> = ({ source = "pet" }) => {
  const record: IRecord = useRecordContext();

  if (!record) {
    return null;
  }

  const pet = record[source];

  if (!pet) {
    return null;
  }

  const { name, label } = pet;

  return (
    <Typography component="span" variant="body2">
      {label && `${label}`} {name && ` called ${name}`}
    </Typography>
  );
};
