import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  Paper,
  TableRow,
  TableCell,
  makeStyles,
  Chip,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { IOrderRecord } from "../../../model/graphql/adaptor/order/records";
import { PaymentField } from "../payment-field";

const useStyles = makeStyles({
  table: {
    minWidth: "100%",
  },
});

type Locales = string | string[] | undefined;

interface IProps {
  record?: IOrderRecord;
  label?: string;
  addLabel?: boolean;
  className?: string;
  locales?: Locales;
}

/**
 * COmponent with outlined paper
 */
const OutlinedPaper: React.FC = ({ children }) => <Paper variant="outlined">{children}</Paper>;

/**
 * Format amount as a price
 * @param amount Amount to format
 * @param locales Locales
 */
const formatPrice = (amount: number, locales: Locales): string =>
  amount.toLocaleString(locales, { style: "currency", currency: "GBP" });

/**
 * Product table field component
 * @param props Component props
 */
export const ProductTableField: React.FC<IProps> = ({ record, locales }) => {
  const classes = useStyles();
  const history = useHistory();

  if (!record) {
    return null;
  }

  const refundTotal = record.refunds.reduce((acc, r) => acc + r.amount, 0);

  /**
   * Handle click of product
   * @param link Link to product
   */
  const handleClickProduct = (link: string) => (): void => history.push(link);

  return (
    <TableContainer component={OutlinedPaper}>
      <Table aria-label="simple table" className={classes.table}>
        <TableBody>
          {record.products.map((p, i) => (
            <TableRow key={i}>
              <TableCell>
                <Chip
                  label={`${p.title} ${p.id}`}
                  size="small"
                  clickable
                  onClick={handleClickProduct(p.link)}
                />
              </TableCell>
              <TableCell>
                {p.addOns.map((a, index) => (
                  <Chip label={a} key={`addon-${index}`} size="small" variant="outlined" />
                ))}
              </TableCell>
              <TableCell>{formatPrice(p.price, locales)}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={2} align="right">
              Subtotal
            </TableCell>
            <TableCell>{formatPrice(record.total, locales)}</TableCell>
          </TableRow>
          {record.payment && (
            <TableRow>
              <TableCell colSpan={2} align="right">
                Payment <PaymentField label="Payment" record={record} source="payment" addLabel />
              </TableCell>
              <TableCell>{formatPrice(record.payment.amount, locales)}</TableCell>
            </TableRow>
          )}
          {record.promoCode && (
            <TableRow>
              <TableCell colSpan={2} align="right">
                Code <Chip label={record.promoCode.code} size="small" />
              </TableCell>
              <TableCell>-{formatPrice(record.promoCode.discount, locales)}</TableCell>
            </TableRow>
          )}
          {refundTotal > 0 && (
            <TableRow>
              <TableCell colSpan={2} align="right">
                Refunds{" "}
                {record.refunds.map((r, i) => (
                  <PaymentField label="Payment" record={r} addLabel key={`refund-${i}`} />
                ))}
              </TableCell>
              <TableCell>-{formatPrice(refundTotal, locales)}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
