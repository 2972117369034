import { extractIdFromUrn, UrnResource } from "@santa/common/lib/utils/urn";
import * as datefns from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { BoyGirl, CallFragmentFragment } from "../../../../types/graphql";
import { IRecordObject } from "../types";

export interface ICallRecord extends IRecordObject {
  urn: string;
  user: {
    urn: string;
    email: string;
  };
  santa: {
    urn: string;
    name: {
      first: string;
      last: string;
    };
    email: string;
  };
  time: Date;
  timeLocal: Date;
  timeCreated: Date;
  type: string;
  phone?: {
    country: {
      urn: string;
      callingCode: string;
    };
    number: string;
  };
  timezone: {
    urn: string;
    timezone: string;
  };
  timezoneUrn: string;
  name: {
    first: string;
    last: string;
  };
  boyGirl: BoyGirl;
  age: {
    years?: number;
    months?: number;
  };
  frontDoor?: {
    id: string;
    label: string;
  };
  gift?: string;
  hobby?: string;
  friend?: string;
  pet1?: {
    id: string;
    label: string;
    name?: string;
  };
  pet2?: {
    id: string;
    label: string;
    name?: string;
  };
  notes?: string;
}

/**
 * Map a DB record to a call object
 * @param call Object to map from
 */
export const mapToCall = (call: CallFragmentFragment): ICallRecord => ({
  id: extractIdFromUrn(UrnResource.CALL, call.urn),
  urn: call.urn,
  type: call.type,
  user: {
    urn: call.user.urn,
    email: call.user.email,
  },
  time: datefns.parseISO(call.time),
  timeLocal: utcToZonedTime(datefns.parseISO(call.time), call.timezone.timezone),
  ...(call.phoneNumber &&
    call.phoneCountry && {
      phone: {
        country: {
          urn: call.phoneCountry.urn,
          callingCode: call.phoneCountry.callingCode,
        },
        number: call.phoneNumber,
      },
    }),
  name: {
    first: call.firstName,
    last: call.lastName,
  },
  timeCreated: datefns.parseISO(call.timeCreated),
  santa: {
    urn: call.santa.urn,
    name: {
      first: call.santa.firstName || "-",
      last: call.santa.lastName || "-",
    },
    email: call.santa.email,
  },
  timezone: {
    urn: call.timezone.urn,
    timezone: call.timezone.timezone,
  },
  timezoneUrn: call.timezone.urn,
  boyGirl: call.boyGirl,
  age: {
    years: call.ageYears || undefined,
    months: call.ageMonths || undefined,
  },
  ...(call.frontDoor && {
    frontDoor: {
      id: call.frontDoor.id,
      label: call.frontDoor.label || "",
    },
  }),
  gift: call.gift || undefined,
  hobby: call.hobby || undefined,
  friend: call.friend || undefined,
  ...(call.pet1 && {
    pet1: {
      id: call.pet1.id,
      label: call.pet1.label || "",
      ...(call.pet1Name && { name: call.pet1Name }),
    },
  }),
  ...(call.pet2 && {
    pet2: {
      id: call.pet2.id,
      label: call.pet2.label || "",
      ...(call.pet2Name && { name: call.pet2Name }),
    },
  }),
  notes: call.notes || undefined,
});
