import { orderGetListQuery, OrderGetListVariables } from "./order-get-list";
import { getOrderQuery } from "./order-get-one";
import { ProviderRequestMethod, IQueryGetOneVariables, AnyQueryArgs } from "../types";

export type OrderVariables = OrderGetListVariables | IQueryGetOneVariables;

/**
 * Get queriy for order operations
 * @param fetchType Type of fetch
 * @param variables Params for query
 */
export const orderQuery = (
  fetchType: ProviderRequestMethod,
  variables: OrderVariables,
): AnyQueryArgs => {
  // console.log(variables);
  switch (fetchType) {
    case "GET_LIST":
      return orderGetListQuery(variables as OrderGetListVariables);
    case "GET_ONE":
      return getOrderQuery(variables as IQueryGetOneVariables);
    default:
      throw new Error(`fetchType ${fetchType} not recognised`);
  }
};
