import { GetCountriesDocument, GetCountriesQuery } from "../../../../types/graphql";
import { AnyQueryArgs, ProviderRequestMethod, GetListQueryResolver, IRecordObject } from "../types";

export interface ICountryRecord extends IRecordObject {
  name: string;
  callingCode: string;
}

const timezoneListQuery: GetListQueryResolver<GetCountriesQuery, ICountryRecord> = () => ({
  query: GetCountriesDocument,
  variables: {},
  parseResponse: ({ data }) => ({
    data: data.countries.map(({ urn: id, name, callingCode }) => ({ id, name, callingCode })),
    total: data.countries.length,
  }),
});

export const countryQuery = (fetchType: ProviderRequestMethod): AnyQueryArgs => {
  switch (fetchType) {
    case ProviderRequestMethod.GET_LIST:
      return timezoneListQuery();
    default:
      throw new Error(`fetchType ${fetchType} not recognised for country`);
  }
};
