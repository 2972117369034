import React from "react";
import { Card as MuiCard, CardContent, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  FilterLiveSearch,
  FilterList,
  FilterListItem,
  useQuery,
  usePermissions,
} from "react-admin";
import PersonIcon from "@material-ui/icons/Person";

import { ICallFilter } from "../../../../model/graphql/adaptor/call/call-get-list";
import { ISantaRecord } from "../../../../model/graphql/adaptor/santa/records";
import { UserGroups } from "../../../../model/auth-provider";
import { Resource } from "../../../../model/graphql/adaptor/resources";

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1,
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

interface IUseData {
  isSantaFilterEnabled: boolean;
  santas?: ISantaRecord[];
  isLoading?: boolean;
}

const useData = (): IUseData => {
  const permissions = usePermissions();

  const { data: santas, loading: isLoading }: { data: ISantaRecord[]; loading: boolean } = useQuery(
    {
      type: "getList",
      resource: Resource.SANTAS,
    },
  );

  return {
    isSantaFilterEnabled: permissions.permissions?.includes(UserGroups.ADMINS),
    santas,
    isLoading,
  };
};

/**
 * Letter list filter component
 */
export const CallListFilter: React.FC<{ filter?: string | string[] | null }> = ({ filter }) => {
  const { isSantaFilterEnabled, santas, isLoading } = useData();
  const filterOptions: ICallFilter = filter && typeof filter === "string" ? JSON.parse(filter) : {};

  return (
    <Card>
      <CardContent>
        <FilterLiveSearch value={filterOptions.q} />
        {isSantaFilterEnabled && (
          <>
            {isLoading && !santas && <CircularProgress />}
            {!isLoading && santas && (
              <FilterList label="Santa" icon={<PersonIcon />}>
                {santas.map(s => (
                  <FilterListItem
                    key={s.email}
                    label={s.email}
                    value={{
                      santaUrn: s.urn,
                    }}
                  />
                ))}
              </FilterList>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};
