import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

interface ILoginParams {
  username: string;
  password: string;
}

/**
 * Login handler
 * @param params login params
 */
export const login = async ({ username, password }: ILoginParams): Promise<void> => {
  await Auth.signIn({ password, username });

  const user: CognitoUser = await Auth.currentAuthenticatedUser();

  // the array of groups that the user belongs to
  const groups = user.getSignInUserSession()?.getAccessToken()?.payload[
    "cognito:groups"
  ] as string[];

  if (!groups || !groups.some(g => g === "Admins" || g === "Santas")) {
    throw new Error("Incorrect username or password");
  }
};

interface IGraphQLResult {
  graphQLErrors?: {
    extensions: {
      code: "UNAUTHENTICATED" | "FORBIDDEN" | string;
    };
  }[];
}

/**
 * Check API response for auth failure
 * @param result Result from API
 */
export const checkError = async (result: IGraphQLResult): Promise<void> => {
  if (
    result.graphQLErrors &&
    result.graphQLErrors.some(
      e => e.extensions.code === "UNAUTHENTICATED" || e.extensions.code === "FORBIDDEN",
    )
  ) {
    throw new Error();
  }
};

/**
 * Log user out
 */
export const logout = async (): Promise<void> => {
  await Auth.signOut();
};

/**
 * Check if user is authenticated
 */
export const checkAuth = async (): Promise<void> => {
  await Auth.currentSession();
};

export enum UserGroups {
  ADMINS = "Admins",
  SANTAS = "Santas",
}

/**
 * Get roles of logged in user
 */
export const getPermissions = async (): Promise<string[]> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.getSignInUserSession()?.getAccessToken()?.payload[
      "cognito:groups"
    ] as string[];

    if (groups) {
      console.log({ groups });
      return groups;
    }
  } catch {
    // no catch
  }

  return [];
};
