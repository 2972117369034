import React, { useCallback } from "react";
import {
  Edit,
  FormWithRedirect,
  TextInput,
  Toolbar,
  SaveButton,
  DateTimeInput,
  SelectInput,
  NumberInput,
  ReferenceInput,
} from "react-admin";
import { Grid, Typography, Card, CardContent, GridSize } from "@material-ui/core";

import { ILetterRecord } from "../../model/graphql/adaptor/letter/records";
import { BoyGirl, CallType } from "../../types/graphql";
import { Resource } from "../../model/graphql/adaptor/resources";
import { ICallRecord } from "../../model/graphql/adaptor/call/record";

interface IProps {
  record: ILetterRecord;
}

const boyGirlChoices = [
  { id: BoyGirl.BOY, name: "Boy" },
  { id: BoyGirl.GIRL, name: "Girl" },
];

const Cell: React.FC<{ size?: GridSize }> = ({ children, size = 6 }) => (
  <Grid item md={size}>
    {children}
  </Grid>
);

const CallForm: React.FC<{ record?: ICallRecord }> = props => {
  const { record } = props;

  const render = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (formProps: any): React.ReactNode => (
      <Card>
        <form>
          <CardContent>
            <Grid container spacing={2}>
              <Cell>
                <ReferenceInput label="Santa" source="santa.urn" reference={Resource.SANTAS}>
                  <SelectInput optionText="email" fullWidth />
                </ReferenceInput>
              </Cell>
            </Grid>

            <Typography variant="h6" gutterBottom>
              Call Details
            </Typography>
            <Grid container spacing={2}>
              <Cell>
                <TextInput label="First name" source="name.first" fullWidth />
              </Cell>
              <Cell>
                <TextInput label="Last name" source="name.last" fullWidth />
              </Cell>
              <Cell>
                <DateTimeInput label="Time" source="time" fullWidth />
              </Cell>
              <Cell>
                <ReferenceInput
                  label="Timezone"
                  source="timezone.urn"
                  reference={Resource.TIMEZONES}
                >
                  <SelectInput optionText="timezone" fullWidth />
                </ReferenceInput>
              </Cell>

              {record?.type === CallType.AUDIO && (
                <>
                  <Cell>
                    <ReferenceInput
                      label="Dialling code"
                      source="phone.country.urn"
                      reference={Resource.COUNTRIES}
                    >
                      <SelectInput optionText="callingCode" fullWidth />
                    </ReferenceInput>
                  </Cell>
                  <Cell>
                    <TextInput label="Phone" source="phone.number" fullWidth />
                  </Cell>
                </>
              )}
            </Grid>

            <Typography variant="h6" gutterBottom>
              Personalisation
            </Typography>
            <Grid container spacing={2}>
              <Cell>
                <SelectInput
                  label="Boy / Girl"
                  source="boyGirl"
                  choices={boyGirlChoices}
                  fullWidth
                />
              </Cell>
              <Cell size={3}>
                <NumberInput label="Age Years" source="age.years" fullWidth />
              </Cell>
              <Cell size={3}>
                <NumberInput label="Age Months" source="age.months" fullWidth />
              </Cell>
              <Cell>
                <TextInput label="Hobby" source="hobby" fullWidth />
              </Cell>
              <Cell>
                <TextInput label="Gift" source="gift" fullWidth />
              </Cell>
              <Cell>
                <ReferenceInput label="Pet 1" source="pet1.id" reference={Resource.PETS}>
                  <SelectInput optionText="label" fullWidth />
                </ReferenceInput>
              </Cell>
              <Cell>
                <TextInput label="Pet 1 name" source="pet1.name" fullWidth />
              </Cell>
              <Cell>
                <ReferenceInput label="Pet 2" source="pet2.id" reference={Resource.PETS}>
                  <SelectInput optionText="label" fullWidth />
                </ReferenceInput>
              </Cell>
              <Cell>
                <TextInput label="Pet 2 name" source="pet2.name" fullWidth />
              </Cell>
              <Cell>
                <ReferenceInput
                  label="Front door"
                  source="frontDoor.id"
                  reference={Resource.FRONT_DOORS}
                >
                  <SelectInput optionText="label" fullWidth />
                </ReferenceInput>
              </Cell>
              <Cell size={12}>
                <TextInput label="Notes" source="notes" fullWidth />
              </Cell>
            </Grid>
          </CardContent>

          <Toolbar>
            <SaveButton
              saving={formProps.saving}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            />
          </Toolbar>
        </form>
      </Card>
    ),
    [],
  );

  return <FormWithRedirect {...props} render={render} />;
};

export const CallEdit: React.FC<IProps> = props => {
  return (
    <Edit component="div" {...props}>
      <CallForm />
    </Edit>
  );
};
