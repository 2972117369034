import React from "react";
import { List, Datagrid, TextField, Filter, DateInput, DateField, NumberField } from "react-admin";
import { parse } from "query-string";

import { UserField } from "../../fields/user-field";
import { ProductListField } from "../../fields/product-list-field";

import { OrderListFilter } from "./list-filter";

/**
 * List top filters
 * @param props component props
 */
const ListFilters: React.FC = props => (
  <Filter {...props}>
    <DateInput source="dateFrom" label="From" alwaysOn />
    <DateInput source="dateTo" label="To" alwaysOn />
  </Filter>
);

interface IProps {
  location: Location;
}

/**
 * List of orders
 * @param props Component props
 */
export const OrderList: React.FC<IProps> = props => (
  <List
    {...props}
    aside={<OrderListFilter filter={parse(props.location.search)?.filter} />}
    filters={<ListFilters />}
    perPage={25}
  >
    <Datagrid isRowSelectable={false} rowClick="show">
      <TextField source="id" label="ID" />
      <UserField />
      <ProductListField label="Products" />
      <NumberField source="total" label="Total" options={{ style: "currency", currency: "GBP" }} />
      <DateField source="timeCreated" label="Time Placed" showTime />
    </Datagrid>
  </List>
);
