import {
  UpdateCallDocument,
  UpdateCallMutation,
  UpdateCallMutationVariables,
} from "../../../../types/graphql";
import { UpdateMutationResolver } from "../types";

import { ICallRecord, mapToCall } from "./record";

export const callUpdate: UpdateMutationResolver<
  UpdateCallMutation,
  ICallRecord,
  UpdateCallMutationVariables
> = ({ data }) => ({
  query: UpdateCallDocument,
  variables: {
    callUrn: data.urn,
    userUrn: data.user.urn,
    time: data.time.toISOString(),
    firstName: data.name.first,
    lastName: data.name.last,
    santaUrn: data.santa.urn,
    timezoneUrn: data.timezone.urn,
    boyGirl: data.boyGirl,
    ageMonths: data.age.months || null,
    ageYears: data.age.years || null,
    frontDoorId: data.frontDoor?.id || null,
    gift: data.gift || null,
    hobby: data.hobby || null,
    friend: data.friend || null,
    pet1Id: data.pet1?.id || null,
    pet1Name: data.pet1?.name || null,
    pet2Id: data.pet2?.id || null,
    pet2Name: data.pet2?.name || null,
    notes: data.notes || null,
  },
  parseResponse: response => {
    const { updateCall } = response.data;

    if (!updateCall) {
      return Promise.reject(new Error("Call not updated"));
    }

    return { data: mapToCall(updateCall) };
  },
});
