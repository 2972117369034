export interface IConfig {
  version: string;
  env: "dev" | "staging" | "prod" | "test";
  graphql: {
    endpoint: string;
  };
  cognito: {
    region: string;
    userPoolId: string;
    appClientId: string;
  };
  braintree: {
    environment: string;
    accountId: string;
  };
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    SANTA_ADMIN_CONFIG: IConfig;
  }
}

export const config = window.SANTA_ADMIN_CONFIG;

if (!config.version) {
  // eslint-disable-next-line global-require
  config.version = require("../../package.json").version;
}
