import { orderQuery } from "./order";
import { ProviderRequestMethod, AnyQueryArgs } from "./types";
import { letterQuery } from "./letter";
import { petQuery } from "./pet";
import { letterPostscriptQuery } from "./letter-postscripts";
import { callQuery } from "./call";
import { santaQuery } from "./santa";
import { timezoneQuery } from "./timezone";
import { countryQuery } from "./country";
import { Resource } from "./resources";
import { letterSignatureQuery } from "./letter-signature";
import { letterTemplateQuery } from "./letter-template";
import { frontDoorQuery } from "./front-door";

/**
 * build query function
 */
export const buildQuery =
  () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (fetchType: ProviderRequestMethod, resourceName: string, params: any): AnyQueryArgs => {
    // eslint-disable-next-line no-console
    console.log(`Resource fetched: ${resourceName}-${fetchType}`);

    switch (resourceName) {
      case Resource.ORDERS:
        return orderQuery(fetchType, params);
      case Resource.LETTERS:
        return letterQuery(fetchType, params);
      case Resource.CALLS:
        return callQuery(fetchType, params);
      case Resource.SANTAS:
        return santaQuery(fetchType, params);
      case Resource.LETTER_SIGNATURES:
        return letterSignatureQuery(fetchType);
      case Resource.LETTER_TEMPLATES:
        return letterTemplateQuery(fetchType);
      case Resource.FRONT_DOORS:
        return frontDoorQuery(fetchType);
      case Resource.PETS:
        return petQuery(fetchType, params);
      case Resource.LETTER_POSTSCRIPTS:
        return letterPostscriptQuery(fetchType);
      case Resource.TIMEZONES:
        return timezoneQuery(fetchType);
      case Resource.COUNTRIES:
        return countryQuery(fetchType);
      default:
        throw new Error(`resource ${resourceName} not recognised`);
    }
  };
