import { Pagination, List, Datagrid, TextField, Filter, DateInput, DateField } from "react-admin";
import { parse } from "query-string";

import { UserGroups } from "../../../model/auth-provider";
import { CallTypeField, PhoneField } from "../../fields";

import { CallListFilter } from "./list-filter";

// import { OrderListFilter } from "./list-filter";

/**
 * List top filters
 * @param props component props
 */
const ListFilters: React.FC = props => (
  <Filter {...props}>
    <DateInput source="timeFrom" label="From" alwaysOn />
    <DateInput source="timeTo" label="To" alwaysOn />
  </Filter>
);
/**
 * Pagination component
 * @param props Component props
 */
const CallPagination: React.FC = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

interface IProps {
  location: Location;
  permissions: string[];
}

/**
 * List of times
 * @param props Component props
 */
export const CallList: React.FC<IProps> = props => {
  const isAdmin = props.permissions?.includes(UserGroups.ADMINS) || false;

  return (
    <List
      {...props}
      aside={<CallListFilter filter={parse(props.location.search)?.filter} />}
      filters={<ListFilters />}
      perPage={25}
      bulkActionButtons={false}
      pagination={<CallPagination />}
      filterDefaultValues={{ timeFrom: new Date().toISOString().substring(0, 10) }}
    >
      <Datagrid rowClick="show">
        <TextField source="id" label="ID" sortable={false} />
        {isAdmin && <TextField label="Santa" source="santa.email" sortable={false} />}
        <TextField label="Recipient" source="name.first" sortable={false} />
        <DateField label="Call time" source="time" showTime />
        <CallTypeField label="Type" sortable={false} />
        <PhoneField label="Phone number" sortable={false} />
      </Datagrid>
    </List>
  );
};
