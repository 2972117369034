import React, { useCallback } from "react";
import {
  Edit,
  FormWithRedirect,
  TextInput,
  Toolbar,
  SaveButton,
  DateInput,
  SelectInput,
  NumberInput,
  ReferenceInput,
} from "react-admin";

import { Grid, Typography, Card, CardContent } from "@material-ui/core";
import { ILetterRecord } from "../../../model/graphql/adaptor/letter/records";
import { Resource } from "../../../model/graphql/adaptor/resources";
import { BoyGirl } from "../../../types/graphql";

interface IProps {
  record: ILetterRecord;
}

const boyGirlChoices = [
  { id: BoyGirl.BOY, name: "Boy" },
  { id: BoyGirl.GIRL, name: "Girl" },
];

/**
 * Letter form component
 * @param props Component props
 */
const LetterForm: React.FC = props => {
  const render = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (formProps: any): JSX.Element => (
      <Card>
        <form>
          <CardContent>
            <TextInput disabled label="Id" source="id" />
            <Typography variant="h6" gutterBottom>
              Sending details
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextInput label="First name" source="firstName" fullWidth />
              </Grid>
              <Grid item md={6}>
                <TextInput label="Last name" source="lastName" fullWidth />
              </Grid>
              <Grid item md={6}>
                <TextInput label="Address Line 1" source="address.line1" fullWidth />
              </Grid>
              <Grid item md={6}>
                <TextInput label="Address Line 2" source="address.line2" fullWidth />
              </Grid>
              <Grid item md={6}>
                <TextInput label="Address Town" source="addresst.town" fullWidth />
              </Grid>
              <Grid item md={6}>
                <TextInput label="Address County" source="address.county" fullWidth />
              </Grid>
              <Grid item md={6}>
                <TextInput label="Address Postcode" source="address.postcode" fullWidth />
              </Grid>
              <Grid item md={6}>
                <DateInput label="Send Date" source="sendDate" fullWidth />
              </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom>
              Personalisation
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <ReferenceInput
                  label="Template"
                  source="templateId"
                  reference={Resource.LETTER_TEMPLATES}
                >
                  <SelectInput optionText="label" fullWidth />
                </ReferenceInput>
              </Grid>
              <Grid item md={6}>
                <SelectInput
                  label="Boy / Girl"
                  source="boyGirl"
                  choices={boyGirlChoices}
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <NumberInput label="Age Years" source="ageYears" fullWidth />
              </Grid>
              <Grid item md={6}>
                <NumberInput label="Age Months" source="ageMonths" fullWidth />
              </Grid>
              <Grid item md={6}>
                <TextInput label="Hobby" source="hobby" fullWidth />
              </Grid>
              <Grid item md={6}>
                <TextInput label="Gift" source="gift" fullWidth />
              </Grid>
              <Grid item md={6}>
                <TextInput label="Best friend" source="friend" fullWidth />
              </Grid>
              <Grid item md={6}>
                <ReferenceInput
                  label="Front door"
                  source="frontDoor.id"
                  reference={Resource.FRONT_DOORS}
                >
                  <SelectInput optionText="name" fullWidth />
                </ReferenceInput>
              </Grid>
              <Grid item md={6}>
                <ReferenceInput label="Pet 1" source="pet1Id" reference="pets">
                  <SelectInput optionText="name" fullWidth />
                </ReferenceInput>
              </Grid>
              <Grid item md={6}>
                <TextInput label="Pet 1 name" source="pet1Name" fullWidth />
              </Grid>
              <Grid item md={6}>
                <ReferenceInput label="Pet 2" source="pet2Id" reference="pets">
                  <SelectInput optionText="name" fullWidth />
                </ReferenceInput>
              </Grid>
              <Grid item md={6}>
                <TextInput label="Pet 2 name" source="pet2Name" fullWidth />
              </Grid>
            </Grid>

            <Typography variant="h6" gutterBottom>
              Sign off
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <ReferenceInput
                  label="Signature"
                  source="signatureId"
                  reference={Resource.LETTER_SIGNATURES}
                >
                  <SelectInput optionText="label" fullWidth />
                </ReferenceInput>
              </Grid>
              <ReferenceInput label="PS" source="psId" reference={Resource.LETTER_POSTSCRIPTS}>
                <SelectInput optionText="label" fullWidth />
              </ReferenceInput>
              <Grid item md={12}>
                <TextInput label="PS Custom" source="psCustom" fullWidth multiline />
              </Grid>
            </Grid>
          </CardContent>
          <Toolbar>
            <SaveButton
              saving={formProps.saving}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            />
          </Toolbar>
        </form>
      </Card>
    ),
    [],
  );

  return <FormWithRedirect {...props} render={render} />;
};

/**
 * Letter edit component
 * @param props component props
 */
export const LetterEdit: React.FC<IProps> = props => {
  return (
    <Edit component="div" {...props}>
      <LetterForm />
    </Edit>
  );
};
