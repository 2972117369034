import { santaGetListQuery, OrderGetListVariables } from "./santas-get-list";
import { ProviderRequestMethod, IQueryGetOneVariables, AnyQueryArgs } from "../types";

export type OrderVariables = OrderGetListVariables | IQueryGetOneVariables;

export const santaQuery = (
  fetchType: ProviderRequestMethod,
  variables: OrderVariables,
): AnyQueryArgs => {
  switch (fetchType) {
    case ProviderRequestMethod.GET_LIST:
      return santaGetListQuery(variables as OrderGetListVariables);
    default:
      throw new Error(`fetchType ${fetchType} not recognised on santas`);
  }
};
