import * as React from "react";
import { Chip, Avatar } from "@material-ui/core";

import { ProductAlphaId } from "../../../types/graphql";
import { IOrderRecord, IOrderProduct } from "../../../model/graphql/adaptor/order/records";

interface IProps {
  record?: IOrderRecord;
  label?: string;
}

const productMapping: Record<ProductAlphaId, string> = {
  COOKIE_MIX: "Cookie Mix",
  DANGLY_ELF: "Dangly Elf",
  SANTA_PLUSH: "Plush",
  SANTA_SACK: "Sack",
  SANTA_VIDEO_MESSAGE: "Video Message",
  MAGIC_SNOW: "Magic Snow",
  SANTA_LETTER: "Letter",
  INTERNATIONAL_SURCHARGE_LETTER: "International Surcharge (Letter)",
  INTERNATIONAL_SURCHARGE_PRODUCT: "International Surcharge (Product)",
  PANDP_SURCHARGE: "P&P Surcharge",
  SANTA_CALL: "Voice Call",
  SANTA_CALL_VIDEO: "Zoom Call",
  CALL_CERTIFICATE: "Call Certificate",
  SANTA_TEXT: "Text",
  SANTA_TEXT_BONUS: "Text Bonus",
  VIDEO_MESSAGE: "Video Message",
};

interface ILabel {
  label: string;
  count: number;
}

/**
 * Map product id list to normalised labels
 * @param products Product IDs
 */
const mapLabels = (products: IOrderProduct[]): ILabel[] =>
  Array.from(
    products.reduce<Map<ProductAlphaId, number>>((acc, p) => {
      const current = acc.get(p.alphaId);
      if (current) {
        acc.set(p.alphaId, current + 1);
      } else {
        acc.set(p.alphaId, 1);
      }

      return acc;
    }, new Map()),
  )
    .map<ILabel>(([label, count]) => ({ label: productMapping[label], count }))
    .sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      }
      if (b.label < a.label) {
        return -1;
      }
      return 0;
    });
// products.map(p => productMapping[p]).reduce((acc, p) => {}, [])

/**
 * Product list component
 * @param props component props
 */
export const ProductListField: React.FC<IProps> = ({ record }) =>
  record ? (
    <>
      {mapLabels(record.products).map((p, i) => (
        <Chip key={`${p}-${i}`} size="small" label={p.label} avatar={<Avatar>{p.count}</Avatar>} />
      ))}
    </>
  ) : null;
