import * as React from "react";
import { Chip } from "@material-ui/core";
import { useRecordContext } from "react-admin";
import { BoyGirl } from "../../types/graphql";

interface IRecord {
  boyGirl: BoyGirl;
}

export const BoyGirlField: React.FC = () => {
  const record: IRecord = useRecordContext();

  if (!record) {
    return null;
  }

  const { boyGirl } = record;

  return <Chip size="small" label={boyGirl.toUpperCase()} />;
};
