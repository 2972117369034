import React, { useCallback } from "react";
import { Chip } from "@material-ui/core";
import { Icon } from "@iconify/react";
import paypalIcon from "@iconify/icons-mdi/paypal";
import cardIcon from "@iconify/icons-mdi/credit-card-outline";
import refundIcon from "@iconify/icons-mdi/credit-card-refund-outline";
import { get } from "lodash";

import { ITransaction } from "../../../model/graphql/adaptor/order/records";

/**
 * Get icon for transaction type
 * @param type Transaction type
 */
const getIcon = (type: string): object => {
  switch (type) {
    case "PAYPAL":
      return paypalIcon;
    case "CARD":
      return cardIcon;
    case "REFUND":
      return refundIcon;
    default:
      throw new Error(`Unknown type ${type}`);
  }
};

interface IProps {
  record?: object;
  source?: string;
  label?: string;
  addLabel?: boolean;
  className?: string;
}

/**
 * Payment field component
 * @param props component props
 */
export const PaymentField: React.FC<IProps> = ({ record, source }) => {
  const payment: ITransaction = source && record ? get(record, source) : record;

  if (!payment) {
    return null;
  }

  /**
   * Handle click of chip
   */
  const handleClick = useCallback((): void => {
    window.open(payment.url);
  }, [payment]);

  return (
    <Chip
      size="small"
      icon={<Icon icon={getIcon(payment.type)} />}
      label={payment.description}
      clickable
      onClick={handleClick}
    />
  );
};
