import {
  ProviderRequestMethod,
  IQueryGetOneVariables,
  AnyQueryArgs,
  IUpdateVariables,
} from "../types";

import { callGetListQuery, CallGetListVariables } from "./call-get-list";
import { getCallQuery } from "./call-get-one";
import { callUpdate } from "./call-update";
import { ICallRecord } from "./record";

export type OrderVariables =
  | CallGetListVariables
  | IQueryGetOneVariables
  | IUpdateVariables<ICallRecord>;

/**
 * Get query for call operations
 * @param fetchType Type of fetch
 * @param variables Params for query
 */
export const callQuery = (
  fetchType: ProviderRequestMethod,
  variables: OrderVariables,
): AnyQueryArgs => {
  // console.log(variables);
  switch (fetchType) {
    case "GET_LIST":
      return callGetListQuery(variables as CallGetListVariables);
    case "GET_ONE":
      return getCallQuery(variables as IQueryGetOneVariables);
    case "UPDATE":
      return callUpdate(variables as IUpdateVariables<ICallRecord>);
    default:
      console.log({ variables });
      throw new Error(`fetchType ${fetchType} not recognised for calls`);
  }
};
