import { ProviderRequestMethod, IQueryGetOneVariables, AnyQueryArgs } from "../types";

import { letterGetListQuery, LetterGetListVariables } from "./letter-get-list";
import { getLetterQuery } from "./letter-get-one";

export type OrderVariables = LetterGetListVariables | IQueryGetOneVariables;

/**
 * Get queriy for letter operations
 * @param fetchType Type of fetch
 * @param variables Params for query
 */
export const letterQuery = (
  fetchType: ProviderRequestMethod,
  variables: OrderVariables,
): AnyQueryArgs => {
  // console.log(variables);
  switch (fetchType) {
    case "GET_LIST":
      return letterGetListQuery(variables as LetterGetListVariables);
    case "GET_ONE":
      return getLetterQuery(variables as IQueryGetOneVariables);
    default:
      throw new Error(`fetchType ${fetchType} not recognised for letters`);
  }
};
