import { extractIdFromUrn, createUrn, UrnResource } from "@santa/common/lib/utils/urn";
import * as datefns from "date-fns";

import { ILetterRecord } from "../records";
import { GetOneQueryResolver } from "../../types";
import {
  GetLetterDocument,
  GetLetterQuery,
  GetLetterQueryVariables,
} from "../../../../../types/graphql";

/**
 * Get letter query adaptor
 * @param variables query variables
 */
export const getLetterQuery: GetOneQueryResolver<
  GetLetterQuery,
  ILetterRecord,
  GetLetterQueryVariables
> = ({ id }) => ({
  query: GetLetterDocument,
  variables: {
    urn: createUrn(UrnResource.LETTER, id),
  },
  parseResponse: response => {
    const { letter } = response.data;

    if (!letter) {
      return Promise.reject(new Error("Letter not found"));
    }

    return {
      data: {
        id: extractIdFromUrn(UrnResource.LETTER, letter.urn),
        urn: letter.urn,
        user: {
          urn: letter.user.urn,
          firstName: letter.user.firstName || "",
          lastName: letter.user.lastName || "",
        },
        firstName: letter.firstName,
        lastName: letter.lastName,
        address: {
          line1: letter.addressLine1,
          line2: letter.addressLine2 || "",
          town: letter.addressTown,
          county: letter.addressCounty || "",
          postcode: letter.addressPostcode,
        },
        boyGirl: letter.boyGirl,
        ageYears: letter.ageYears?.toString() || "",
        ageMonths: letter.ageMonths?.toString() || "",
        hobbyId: letter.hobby || "",
        giftId: letter.gift || "",
        friend: letter.friend || "",
        frontDoorId: letter.frontDoor?.label || "",
        pet1Id: letter.pet1?.label || "",
        pet1Name: letter.pet1Name || "",
        pet2Id: letter.pet2?.label || "",
        pet2Name: letter.pet2Name || "",
        psId: letter.ps?.id || "",
        psCustom: letter.psCustom || "",
        signatureId: letter.signature.id,
        templateId: letter.template.id,
        sendDate: datefns.parseISO(letter.date),
        timeCreated: datefns.parseISO(letter.timeCreated),
      },
    };
  },
});
