import React from "react";
import { Admin, Resource, Login } from "react-admin";
import buildGraphQLProvider from "ra-data-graphql";
import { createBrowserHistory } from "history";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import {
  Apps,
  Mail,
  Phone,
  // Smartphone,
  // LocalMall,
  ShoppingCart,
  // PeopleAlt,
} from "@material-ui/icons";
import { Auth } from "aws-amplify";
import { BrowserRouter } from "react-router-dom";

import { buildQuery } from "../model/graphql/adaptor/build-query";
import { Resource as ResourceType } from "../model/graphql/adaptor/resources";
import { __schema as schema } from "../model/graphql/schema.json";
import * as authProvider from "../model/auth-provider";
import { config } from "../config";

import { OrderList } from "./orders/list";
import { Layout } from "./layout";
import { OrderShow } from "./orders/show";
import { LetterList } from "./letter/list";
import { LetterEdit } from "./letter/edit";
import { CallList } from "./call/list";
import { UserGroups } from "../model/auth-provider";
import { CallShow } from "./call/show";
import { CallEdit } from "./call/edit";

const httpLink = createHttpLink({
  uri: config.graphql.endpoint,
});

const authLink = setContext(async (__, { headers }) => {
  try {
    const session = await Auth.currentSession();

    if (session) {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${session.getAccessToken().getJwtToken()}`,
        },
      };
    }
  } catch {
    // no catch
  }

  return headers;
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
    },
  },
});

const history = createBrowserHistory();

/**
 * Login page component
 */
const MyLoginPage: React.FC = () => (
  <Login
    // A random image that changes everyday
    backgroundImage="https://source.unsplash.com/daily?christmas"
  />
);

/**
 * Root app
 */
export class App extends React.Component {
  public state = {
    provider: null,
  };

  public async componentDidMount(): Promise<void> {
    const provider = await buildGraphQLProvider({
      introspection: { schema },
      buildQuery,
      client,
    });

    this.setState({ provider });
  }

  public render(): JSX.Element {
    if (this.state.provider) {
      return (
        <BrowserRouter>
          <Admin
            dataProvider={this.state.provider}
            authProvider={authProvider}
            layout={Layout}
            history={history}
            loginPage={MyLoginPage}
          >
            {(permissions: UserGroups[]): (JSX.Element | null)[] => [
              permissions.includes(UserGroups.ADMINS) ? (
                <Resource
                  key={ResourceType.ORDERS}
                  name={ResourceType.ORDERS}
                  icon={ShoppingCart}
                  list={OrderList}
                  show={OrderShow}
                  options={{ label: "Orders" }}
                />
              ) : null,
              <Resource
                key="products"
                name="products"
                icon={Apps}
                options={{ label: "Products", isMenuParent: true, open: true }}
              />,
              permissions.includes(UserGroups.ADMINS) ? (
                <Resource
                  key={ResourceType.LETTERS}
                  name={ResourceType.LETTERS}
                  icon={Mail}
                  options={{ label: "Letters", menuParent: "products" }}
                  list={LetterList}
                  edit={LetterEdit}
                />
              ) : null,
              <Resource
                key={ResourceType.CALLS}
                name={ResourceType.CALLS}
                icon={Phone}
                options={{ label: "Calls", menuParent: "products" }}
                list={CallList}
                show={CallShow}
                edit={CallEdit}
              />,

              <Resource key={ResourceType.SANTAS} name={ResourceType.SANTAS} />,
              <Resource key={ResourceType.COUNTRIES} name={ResourceType.COUNTRIES} />,
              <Resource key={ResourceType.TIMEZONES} name={ResourceType.TIMEZONES} />,
              <Resource key={ResourceType.FRONT_DOORS} name={ResourceType.FRONT_DOORS} />,
              <Resource key={ResourceType.PETS} name={ResourceType.PETS} />,
              <Resource
                key={ResourceType.LETTER_POSTSCRIPTS}
                name={ResourceType.LETTER_POSTSCRIPTS}
              />,
              <Resource
                key={ResourceType.LETTER_SIGNATURES}
                name={ResourceType.LETTER_SIGNATURES}
              />,
              <Resource key={ResourceType.LETTER_TEMPLATES} name={ResourceType.LETTER_TEMPLATES} />,
              /* 
              <Resource
                name="texts"
                icon={Smartphone}
                options={{ label: "Texts", menuParent: "products" }}
              />
              <Resource
                name="sacks"
                icon={LocalMall}
                options={{ label: "Sacks", menuParent: "products" }}
              /> */
            ]}
          </Admin>
        </BrowserRouter>
      );
    }

    return <p>loading</p>;
  }
}

/* <Resource name="customers" icon={PeopleAlt} options={{ label: "Customers" }} /> */
