import { DocumentNode } from "graphql";

export enum ProviderRequestMethod {
  GET_LIST = "GET_LIST",
  GET_ONE = "GET_ONE",
  GET_MANY = "GET_MANY",
  GET_MANY_REFERENCE = "GET_MANY_REFERENCE",
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export interface IRecordObject {
  id: string;
}

export interface IQueryArgs<GraphQlResponse, QueryResponse, GraphQlQueryVariables = {}> {
  query: DocumentNode;
  variables: GraphQlQueryVariables;
  parseResponse(response: IGraphQlQueryResponse<GraphQlResponse>): QueryResponse | Promise<never>;
}

export type GetListQueryResolver<
  GraphQlResponse,
  DataRecord,
  GraphQlQueryVariables = {},
  QueryVariables = {},
> = (
  variables?: QueryVariables,
) => IQueryArgs<GraphQlResponse, IGetListResponse<DataRecord>, GraphQlQueryVariables>;

export type GetOneQueryResolver<GraphQlResponse, DataRecord, GraphQlQueryVariables = {}> = (
  variables: IQueryGetOneVariables,
) => IQueryArgs<GraphQlResponse, IGetOneResponse<DataRecord>, GraphQlQueryVariables>;

export interface IProviderGetManyVariables {
  ids: string[];
}

export interface IGetManyResponse<T> {
  data: T[];
  total: number;
  validUntil?: Date;
}

export type GetManyQueryResolver<GraphQlResponse, DataRecord, GraphQlQueryVariables = {}> = (
  variables: IProviderGetManyVariables,
) => IQueryArgs<GraphQlResponse, IGetManyResponse<DataRecord>, GraphQlQueryVariables>;

export interface IUpdateVariables<T> {
  id: string;
  data: T;
  previousData: T;
}

export type UpdateMutationResolver<GraphQlResponse, DataRecord, GraphQlQueryVariables> = (
  variables: IUpdateVariables<DataRecord>,
) => IQueryArgs<GraphQlResponse, IGetOneResponse<DataRecord>, GraphQlQueryVariables>;

export type AnyQueryArgs =
  | IQueryArgs<unknown, IGetOneResponse<IRecordObject>, unknown>
  | IQueryArgs<unknown, IGetListResponse<IRecordObject>, unknown>
  | IQueryArgs<unknown, IGetListResponse<IRecordObject>, unknown>
  | void;

export interface IGetListResponse<T> {
  data: T[];
  total: number;
  validUntil?: Date;
}

export interface IGetOneResponse<T> {
  data: T;
  validUntil?: Date;
}

export interface IQueryGetOneVariables {
  id: string;
}

export interface IQueryGetListVariables<T> {
  pagination: {
    perPage: number;
    page: number;
  };
  filter: T;
  sort: {
    field: string;
    order: "ASC" | "DESC";
  };
}

export interface IGraphQlQueryResponse<T> {
  data: T;
}
