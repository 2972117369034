import { Typography } from "@material-ui/core";
import { useRecordContext } from "react-admin";

interface IRecord {
  phone?: {
    country: {
      urn: string;
      callingCode: string;
    };
    number: string;
  };
}

interface IProps {
  source?: keyof IRecord;
  label?: string;
  sortable?: boolean;
}

export const PhoneField: React.FC<IProps> = ({ source = "phone" }) => {
  const record: IRecord | undefined = useRecordContext();
  const phone = record && record[source];

  if (!phone) {
    return null;
  }

  return (
    <Typography component="span" variant="body2">
      +{`${phone.country.callingCode} ${phone.number}`}
    </Typography>
  );
};
