import React from "react";
import { Card as MuiCard, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AppsIcon from "@material-ui/icons/Apps";
import { FilterList, FilterListItem, FilterLiveSearch } from "react-admin";

import { ProductAlphaId } from "../../../../types/graphql";
import { IOrderFilter } from "../../../../model/graphql/adaptor/order/order-get-list";

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1,
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

/**
 * Order list filter component
 */
export const OrderListFilter: React.FC<{ filter?: string | string[] | null }> = ({ filter }) => {
  const filterOptions: IOrderFilter =
    filter && typeof filter === "string" ? JSON.parse(filter) : {};

  return (
    <Card>
      <CardContent>
        <FilterLiveSearch value={filterOptions.q} />

        <FilterList label="Products" icon={<AppsIcon />}>
          <FilterListItem
            label="Letter"
            value={{
              product: ProductAlphaId.SANTA_LETTER,
            }}
          />
          <FilterListItem
            label="Call"
            value={{
              product: ProductAlphaId.SANTA_CALL,
            }}
          />
          <FilterListItem
            label="Text"
            value={{
              product: ProductAlphaId.SANTA_TEXT,
            }}
          />
          <FilterListItem
            label="Sack"
            value={{
              product: ProductAlphaId.SANTA_SACK,
            }}
          />
        </FilterList>
      </CardContent>
    </Card>
  );
};
