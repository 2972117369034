/* eslint-disable */
import gql from "graphql-tag";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A ISO 8601 compliant datetime value */
  DateTime: any;
  /** Represents `true` or `false` values. */
  BooleanType: any;
  ItemId: any;
  /** Represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
  IntType: any;
  UploadId: any;
  /** Represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point). */
  FloatType: any;
  CustomData: any;
  MetaTagAttributes: any;
};

export type ApplyPromoCodeResponse = {
  __typename?: "ApplyPromoCodeResponse";
  deduction: Scalars["Float"];
  promoCode: PromoCode;
};

export type AvailableCallDatesResponse = {
  __typename?: "AvailableCallDatesResponse";
  date: Scalars["String"];
};

export type AvailableCallSlotsResponse = {
  __typename?: "AvailableCallSlotsResponse";
  time: Scalars["String"];
  isNextSlotAvailable: Scalars["Boolean"];
};

/** Specifies how to filter Boolean fields */
export type BooleanFilter = {
  /** Search for records with an exact match */
  eq?: Maybe<Scalars["BooleanType"]>;
};

export type BooleanTypeMultiLocaleField = {
  __typename?: "BooleanTypeMultiLocaleField";
  locale?: Maybe<SiteLocale>;
  value?: Maybe<Scalars["BooleanType"]>;
};

export enum BoyGirl {
  BOY = "BOY",
  GIRL = "GIRL",
}

export type Call = {
  __typename?: "Call";
  urn: Scalars["String"];
  user: User;
  type: CallType;
  frontDoor?: Maybe<FrontDoorRecord>;
  orderItem: OrderItem;
  santa: User;
  timezone: Timezone;
  product: ProductRecord;
  phoneCountry?: Maybe<Country>;
  phoneNumber?: Maybe<Scalars["String"]>;
  time: Scalars["DateTime"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  boyGirl: BoyGirl;
  ageYears?: Maybe<Scalars["Int"]>;
  ageMonths?: Maybe<Scalars["Int"]>;
  hobby?: Maybe<Scalars["String"]>;
  gift?: Maybe<Scalars["String"]>;
  friend?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  pet1?: Maybe<PetRecord>;
  pet1Name?: Maybe<Scalars["String"]>;
  pet2?: Maybe<PetRecord>;
  pet2Name?: Maybe<Scalars["String"]>;
  addressLine1?: Maybe<Scalars["String"]>;
  addressLine2?: Maybe<Scalars["String"]>;
  addressTown?: Maybe<Scalars["String"]>;
  addressCounty?: Maybe<Scalars["String"]>;
  addressUsState?: Maybe<UsState>;
  addressPostcode?: Maybe<Scalars["String"]>;
  addressCountry?: Maybe<Country>;
  isEditable: Scalars["Boolean"];
  timeCreated: Scalars["DateTime"];
  timeUpdated: Scalars["DateTime"];
};

export type CallCreateInput = {
  callReservationUrn: Scalars["String"];
  phoneCountryUrn?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  timezoneUrn: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  boyGirl: BoyGirl;
  ageYears?: Maybe<Scalars["Int"]>;
  ageMonths?: Maybe<Scalars["Int"]>;
  hobby?: Maybe<Scalars["String"]>;
  gift?: Maybe<Scalars["String"]>;
  friend?: Maybe<Scalars["String"]>;
  frontDoorId?: Maybe<Scalars["String"]>;
  pet1Id?: Maybe<Scalars["String"]>;
  pet1Name?: Maybe<Scalars["String"]>;
  pet2Id?: Maybe<Scalars["String"]>;
  pet2Name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  addressLine1?: Maybe<Scalars["String"]>;
  addressLine2?: Maybe<Scalars["String"]>;
  addressTown?: Maybe<Scalars["String"]>;
  addressCounty?: Maybe<Scalars["String"]>;
  addressUsStateUrn?: Maybe<Scalars["String"]>;
  addressPostcode?: Maybe<Scalars["String"]>;
  addressCountryUrn?: Maybe<Scalars["String"]>;
};

export type CallSearchFilterInput = {
  userUrn?: Maybe<Scalars["String"]>;
  santaUrn?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["String"]>;
  timeFrom?: Maybe<Scalars["String"]>;
  timeTo?: Maybe<Scalars["String"]>;
  type?: Maybe<CallType>;
};

export type CallsSearchEdge = {
  __typename?: "CallsSearchEdge";
  node: Call;
  cursor?: Maybe<Scalars["String"]>;
};

export type CallsSearchResponse = {
  __typename?: "CallsSearchResponse";
  totalCount?: Maybe<Scalars["Int"]>;
  edges: Array<CallsSearchEdge>;
};

export enum CallType {
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
}

export type CallUpdateInput = {
  phoneCountryUrn?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  timezoneUrn?: Maybe<Scalars["String"]>;
  santaUrn?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["String"]>;
  boyGirl?: Maybe<BoyGirl>;
  ageYears?: Maybe<Scalars["Int"]>;
  ageMonths?: Maybe<Scalars["Int"]>;
  hobby?: Maybe<Scalars["String"]>;
  gift?: Maybe<Scalars["String"]>;
  friend?: Maybe<Scalars["String"]>;
  frontDoorId?: Maybe<Scalars["String"]>;
  pet1Id?: Maybe<Scalars["String"]>;
  pet1Name?: Maybe<Scalars["String"]>;
  pet2Id?: Maybe<Scalars["String"]>;
  pet2Name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
};

export type CollectionMetadata = {
  __typename?: "CollectionMetadata";
  count: Scalars["IntType"];
};

export enum ColorBucketType {
  RED = "red",
  ORANGE = "orange",
  PINK = "pink",
  CYAN = "cyan",
  PURPLE = "purple",
  BLUE = "blue",
  YELLOW = "yellow",
  GREEN = "green",
  BROWN = "brown",
  GREY = "grey",
  WHITE = "white",
  BLACK = "black",
}

export type ColorField = {
  __typename?: "ColorField";
  alpha?: Maybe<Scalars["IntType"]>;
  blue?: Maybe<Scalars["IntType"]>;
  green?: Maybe<Scalars["IntType"]>;
  hex?: Maybe<Scalars["String"]>;
  red?: Maybe<Scalars["IntType"]>;
};

export type Country = {
  __typename?: "Country";
  urn: Scalars["String"];
  name: Scalars["String"];
  iso: Scalars["String"];
  callingCode: Scalars["String"];
};

/** Specifies how to filter by creation datetime */
export type CreatedAtFilter = {
  /**
   * Filter records with a value that's strictly greater than the one specified.
   * Seconds and milliseconds are truncated from the argument.
   */
  gt?: Maybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: Maybe<Scalars["DateTime"]>;
  /**
   * Filter records with a value that's greater than or equal to than the one
   * specified. Seconds and milliseconds are truncated from the argument.
   */
  gte?: Maybe<Scalars["DateTime"]>;
  /**
   * Filter records with a value that's less or equal than the one specified.
   * Seconds and milliseconds are truncated from the argument.
   */
  lte?: Maybe<Scalars["DateTime"]>;
  /**
   * Filter records with a value that's within the specified minute range. Seconds
   * and milliseconds are truncated from the argument.
   */
  eq?: Maybe<Scalars["DateTime"]>;
  /**
   * Filter records with a value that's outside the specified minute range. Seconds
   * and milliseconds are truncated from the argument.
   */
  neq?: Maybe<Scalars["DateTime"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars["BooleanType"]>;
};

export type CreateVideoLoginResponse = {
  __typename?: "CreateVideoLoginResponse";
  username: Scalars["String"];
  password: Scalars["String"];
};

export enum FaviconType {
  ICON = "icon",
  APPLETOUCHICON = "appleTouchIcon",
  MSAPPLICATION = "msApplication",
}

/** Record of type Feature Flag (feature_flag) */
export type FeatureFlagRecord = {
  __typename?: "FeatureFlagRecord";
  _allHomePageHeroProductLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allLettersEnabledLocales?: Maybe<Array<Maybe<BooleanTypeMultiLocaleField>>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  homePageHeroProduct?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  lettersEnabled?: Maybe<Scalars["BooleanType"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Feature Flag (feature_flag) */
export type FeatureFlagRecord_AllHomePageHeroProductLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Feature Flag (feature_flag) */
export type FeatureFlagRecord_AllLettersEnabledLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Feature Flag (feature_flag) */
export type FeatureFlagRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Feature Flag (feature_flag) */
export type FeatureFlagRecordHomePageHeroProductArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Feature Flag (feature_flag) */
export type FeatureFlagRecordLettersEnabledArgs = {
  locale?: Maybe<SiteLocale>;
};

export type FileField = {
  __typename?: "FileField";
  _createdAt: Scalars["DateTime"];
  _updatedAt: Scalars["DateTime"];
  alt?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  basename: Scalars["String"];
  blurUpThumb?: Maybe<Scalars["String"]>;
  blurhash?: Maybe<Scalars["String"]>;
  colors: Array<Maybe<ColorField>>;
  copyright?: Maybe<Scalars["String"]>;
  customData?: Maybe<Scalars["CustomData"]>;
  exifInfo?: Maybe<Scalars["CustomData"]>;
  filename: Scalars["String"];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars["String"];
  height?: Maybe<Scalars["IntType"]>;
  id: Scalars["UploadId"];
  md5: Scalars["String"];
  mimeType: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars["IntType"];
  smartTags: Array<Maybe<Scalars["String"]>>;
  tags: Array<Maybe<Scalars["String"]>>;
  title?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars["IntType"]>;
};

export type FileFieldAltArgs = {
  locale?: Maybe<SiteLocale>;
};

export type FileFieldBlurUpThumbArgs = {
  punch?: Maybe<Scalars["Float"]>;
  size?: Maybe<Scalars["Int"]>;
  quality?: Maybe<Scalars["Int"]>;
  imgixParams?: Maybe<ImgixParams>;
};

export type FileFieldCustomDataArgs = {
  locale?: Maybe<SiteLocale>;
};

export type FileFieldFocalPointArgs = {
  locale?: Maybe<SiteLocale>;
};

export type FileFieldResponsiveImageArgs = {
  imgixParams?: Maybe<ImgixParams>;
  sizes?: Maybe<Scalars["String"]>;
};

export type FileFieldTitleArgs = {
  locale?: Maybe<SiteLocale>;
};

export type FileFieldUrlArgs = {
  imgixParams?: Maybe<ImgixParams>;
};

/** Specifies how to filter Single-file/image fields */
export type FileFilter = {
  /** Search for records with an exact match. The specified value must be an Upload ID */
  eq?: Maybe<Scalars["UploadId"]>;
  /** Exclude records with an exact match. The specified value must be an Upload ID */
  neq?: Maybe<Scalars["UploadId"]>;
  /** Filter records that have one of the specified uploads */
  in?: Maybe<Array<Maybe<Scalars["UploadId"]>>>;
  /** Filter records that do not have one of the specified uploads */
  notIn?: Maybe<Array<Maybe<Scalars["UploadId"]>>>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars["BooleanType"]>;
};

export type FirstNameModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  label?: Maybe<StringFilter>;
  showOnSite?: Maybe<BooleanFilter>;
  OR?: Maybe<Array<Maybe<FirstNameModelFilter>>>;
};

export enum FirstNameModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
}

/** Record of type First Name (first_name) */
export type FirstNameRecord = {
  __typename?: "FirstNameRecord";
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type First Name (first_name) */
export type FirstNameRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Specifies how to filter Floating-point fields */
export type FloatFilter = {
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: Maybe<Scalars["FloatType"]>;
  /** Filter records with a value that's less than the one specified */
  lt?: Maybe<Scalars["FloatType"]>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: Maybe<Scalars["FloatType"]>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: Maybe<Scalars["FloatType"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars["BooleanType"]>;
  /** Search for records with an exact match */
  eq?: Maybe<Scalars["FloatType"]>;
  /** Exclude records with an exact match */
  neq?: Maybe<Scalars["FloatType"]>;
};

export type FloatTypeMultiLocaleField = {
  __typename?: "FloatTypeMultiLocaleField";
  locale?: Maybe<SiteLocale>;
  value?: Maybe<Scalars["FloatType"]>;
};

export type FocalPoint = {
  __typename?: "focalPoint";
  x?: Maybe<Scalars["FloatType"]>;
  y?: Maybe<Scalars["FloatType"]>;
};

export type FrontDoorModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  showOnSite?: Maybe<BooleanFilter>;
  text?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<FrontDoorModelFilter>>>;
};

export enum FrontDoorModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  TEXT_ASC = "text_ASC",
  TEXT_DESC = "text_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
}

/** Record of type Front Door (front_door) */
export type FrontDoorRecord = {
  __typename?: "FrontDoorRecord";
  _allLabelLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allTextLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  text?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Front Door (front_door) */
export type FrontDoorRecord_AllLabelLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Front Door (front_door) */
export type FrontDoorRecord_AllTextLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Front Door (front_door) */
export type FrontDoorRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Front Door (front_door) */
export type FrontDoorRecordLabelArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Front Door (front_door) */
export type FrontDoorRecordTextArgs = {
  locale?: Maybe<SiteLocale>;
};

export type GiftModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  showOnSite?: Maybe<BooleanFilter>;
  text?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<GiftModelFilter>>>;
};

export enum GiftModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  TEXT_ASC = "text_ASC",
  TEXT_DESC = "text_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
}

/** Record of type Gift (gift) */
export type GiftRecord = {
  __typename?: "GiftRecord";
  _allLabelLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allTextLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  text?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Gift (gift) */
export type GiftRecord_AllLabelLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Gift (gift) */
export type GiftRecord_AllTextLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Gift (gift) */
export type GiftRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Gift (gift) */
export type GiftRecordLabelArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Gift (gift) */
export type GiftRecordTextArgs = {
  locale?: Maybe<SiteLocale>;
};

export type GlobalSeoField = {
  __typename?: "GlobalSeoField";
  facebookPageUrl?: Maybe<Scalars["String"]>;
  fallbackSeo?: Maybe<SeoField>;
  siteName?: Maybe<Scalars["String"]>;
  titleSuffix?: Maybe<Scalars["String"]>;
  twitterAccount?: Maybe<Scalars["String"]>;
};

export type HobbyModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  text?: Maybe<StringFilter>;
  showOnSite?: Maybe<BooleanFilter>;
  label?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<HobbyModelFilter>>>;
};

export enum HobbyModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  TEXT_ASC = "text_ASC",
  TEXT_DESC = "text_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
}

/** Record of type Hobby (hobby) */
export type HobbyRecord = {
  __typename?: "HobbyRecord";
  _allLabelLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allTextLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  text?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Hobby (hobby) */
export type HobbyRecord_AllLabelLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Hobby (hobby) */
export type HobbyRecord_AllTextLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Hobby (hobby) */
export type HobbyRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Hobby (hobby) */
export type HobbyRecordLabelArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Hobby (hobby) */
export type HobbyRecordTextArgs = {
  locale?: Maybe<SiteLocale>;
};

export type ImgixParams = {
  /**
   * Aspect Ratio
   *
   * Specifies an aspect ratio to maintain when resizing and cropping the image
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/ar)
   */
  ar?: Maybe<Scalars["String"]>;
  /**
   * Automatic
   *
   * Applies automatic enhancements to images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/auto)
   */
  auto?: Maybe<Array<ImgixParamsAuto>>;
  /**
   * Background Color
   *
   * Colors the background of padded and partially-transparent images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/bg)
   */
  bg?: Maybe<Scalars["String"]>;
  /**
   * Blend Align
   *
   * Changes the blend alignment relative to the parent image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-align)
   */
  blendAlign?: Maybe<Array<ImgixParamsBlendAlign>>;
  /**
   * Blend Alpha
   *
   * Changes the alpha of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-alpha)
   */
  blendAlpha?: Maybe<Scalars["IntType"]>;
  /**
   * Blend Color
   *
   * Specifies a color to use when applying the blend.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-color)
   */
  blendColor?: Maybe<Scalars["String"]>;
  /**
   * Blend Crop
   *
   * Specifies the type of crop for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-crop)
   */
  blendCrop?: Maybe<Array<ImgixParamsBlendCrop>>;
  /**
   * Blend Fit
   *
   * Specifies the fit mode for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-fit)
   */
  blendFit?: Maybe<ImgixParamsBlendFit>;
  /**
   * Blend Height
   *
   * Adjusts the height of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-h)
   */
  blendH?: Maybe<Scalars["FloatType"]>;
  /**
   * Blend Mode
   *
   * Sets the blend mode for a blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-mode)
   */
  blendMode?: Maybe<ImgixParamsBlendMode>;
  /**
   * Blend Padding
   *
   * Applies padding to the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-pad)
   */
  blendPad?: Maybe<Scalars["IntType"]>;
  /**
   * Blend Size
   *
   * Adjusts the size of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-size)
   */
  blendSize?: Maybe<ImgixParamsBlendSize>;
  /**
   * Blend Width
   *
   * Adjusts the width of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-w)
   */
  blendW?: Maybe<Scalars["FloatType"]>;
  /**
   * Blend X Position
   *
   * Adjusts the x-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-x)
   */
  blendX?: Maybe<Scalars["IntType"]>;
  /**
   * Blend Y Position
   *
   * Adjusts the y-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-y)
   */
  blendY?: Maybe<Scalars["IntType"]>;
  /**
   * Blend
   *
   * Specifies the location of the blend image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend)
   */
  blend?: Maybe<Scalars["String"]>;
  /**
   * Gaussian Blur
   *
   * Applies a gaussian blur to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/blur)
   */
  blur?: Maybe<Scalars["IntType"]>;
  /**
   * Border Bottom
   *
   * Sets bottom border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-bottom)
   */
  borderBottom?: Maybe<Scalars["IntType"]>;
  /**
   * Border Left
   *
   * Sets left border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-left)
   */
  borderLeft?: Maybe<Scalars["IntType"]>;
  /**
   * Inner Border Radius
   *
   * Sets the inner radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius-inner)
   */
  borderRadiusInner?: Maybe<Scalars["String"]>;
  /**
   * Outer Border Radius
   *
   * Sets the outer radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius)
   */
  borderRadius?: Maybe<Scalars["String"]>;
  /**
   * Border Right
   *
   * Sets right border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-right)
   */
  borderRight?: Maybe<Scalars["IntType"]>;
  /**
   * Border Top
   *
   * Sets top border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-top)
   */
  borderTop?: Maybe<Scalars["IntType"]>;
  /**
   * Border Size & Color
   *
   * Applies a border to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border)
   */
  border?: Maybe<Scalars["String"]>;
  /**
   * Brightness
   *
   * Adjusts the brightness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/bri)
   */
  bri?: Maybe<Scalars["IntType"]>;
  /**
   * Client Hints
   *
   * Sets one or more Client-Hints headers
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/ch)
   */
  ch?: Maybe<Array<ImgixParamsCh>>;
  /**
   * Chroma Subsampling
   *
   * Specifies the output chroma subsampling rate.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/chromasub)
   */
  chromasub?: Maybe<Scalars["IntType"]>;
  /**
   * Color Quantization
   *
   * Limits the number of unique colors in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/colorquant)
   */
  colorquant?: Maybe<Scalars["IntType"]>;
  /**
   * Palette Color Count
   *
   * Specifies how many colors to include in a palette-extraction response.
   *
   * Depends on: `palette`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/colors)
   */
  colors?: Maybe<Scalars["IntType"]>;
  /**
   * Contrast
   *
   * Adjusts the contrast of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/con)
   */
  con?: Maybe<Scalars["IntType"]>;
  /**
   * Mask Corner Radius
   *
   * Specifies the radius value for a rounded corner mask.
   *
   * Depends on: `mask=corners`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/corner-radius)
   */
  cornerRadius?: Maybe<Scalars["String"]>;
  /**
   * Crop Mode
   *
   * Specifies how to crop an image.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/crop)
   */
  crop?: Maybe<Array<ImgixParamsCrop>>;
  /**
   * Color Space
   *
   * Specifies the color space of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/cs)
   */
  cs?: Maybe<ImgixParamsCs>;
  /**
   * Download
   *
   * Forces a URL to use send-file in its response.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dl)
   */
  dl?: Maybe<Scalars["String"]>;
  /**
   * Dots Per Inch
   *
   * Sets the DPI value in the EXIF header.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dpi)
   */
  dpi?: Maybe<Scalars["IntType"]>;
  /**
   * Device Pixel Ratio
   *
   * Adjusts the device-pixel ratio of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/dpr)
   */
  dpr?: Maybe<Scalars["FloatType"]>;
  /**
   * Duotone Alpha
   *
   * Changes the alpha of the duotone effect atop the source image.
   *
   * Depends on: `duotone`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone-alpha)
   */
  duotoneAlpha?: Maybe<Scalars["IntType"]>;
  /**
   * Duotone
   *
   * Applies a duotone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone)
   */
  duotone?: Maybe<Scalars["String"]>;
  /**
   * Exposure
   *
   * Adjusts the exposure of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/exp)
   */
  exp?: Maybe<Scalars["IntType"]>;
  /**
   * Url Expiration Timestamp
   *
   * A Unix timestamp specifying a UTC time. Requests made to this URL after that time will output a 404 status code.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/expires)
   */
  expires?: Maybe<Scalars["IntType"]>;
  /**
   * Face Index
   *
   * Selects a face to crop to.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faceindex)
   */
  faceindex?: Maybe<Scalars["IntType"]>;
  /**
   * Face Padding
   *
   * Adjusts padding around a selected face.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/facepad)
   */
  facepad?: Maybe<Scalars["FloatType"]>;
  /**
   * Json Face Data
   *
   * Specifies that face data should be included in output when combined with `fm=json`.
   *
   * Depends on: `fm=json`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faces)
   */
  faces?: Maybe<Scalars["IntType"]>;
  /**
   * Fill Color
   *
   * Sets the fill color for images with additional space created by the fit setting
   *
   * Depends on: `fill=solid`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-color)
   */
  fillColor?: Maybe<Scalars["String"]>;
  /**
   * Fill Mode
   *
   * Determines how to fill in additional space created by the fit setting
   *
   * Depends on: `fit`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill)
   */
  fill?: Maybe<ImgixParamsFill>;
  /**
   * Resize Fit Mode
   *
   * Specifies how to map the source image to the output image dimensions.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/fit)
   */
  fit?: Maybe<ImgixParamsFit>;
  /**
   * Flip Axis
   *
   * Flips an image on a specified axis.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/flip)
   */
  flip?: Maybe<ImgixParamsFlip>;
  /**
   * Output Format
   *
   * Changes the format of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/fm)
   */
  fm?: Maybe<ImgixParamsFm>;
  /**
   * Focal Point Debug
   *
   * Displays crosshairs identifying the location of the set focal point
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-debug)
   */
  fpDebug?: Maybe<Scalars["BooleanType"]>;
  /**
   * Focal Point X Position
   *
   * Sets the relative horizontal value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-x)
   */
  fpX?: Maybe<Scalars["FloatType"]>;
  /**
   * Focal Point Y Position
   *
   * Sets the relative vertical value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-y)
   */
  fpY?: Maybe<Scalars["FloatType"]>;
  /**
   * Focal Point Zoom
   *
   * Sets the relative zoom value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-z)
   */
  fpZ?: Maybe<Scalars["FloatType"]>;
  /**
   * Gamma
   *
   * Adjusts the gamma of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/gam)
   */
  gam?: Maybe<Scalars["IntType"]>;
  /**
   * Grid Colors
   *
   * Sets grid colors for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridColors?: Maybe<Scalars["String"]>;
  /**
   * Grid Size
   *
   * Sets grid size for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridSize?: Maybe<Scalars["IntType"]>;
  /**
   * Image Height
   *
   * Adjusts the height of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/h)
   */
  h?: Maybe<Scalars["FloatType"]>;
  /**
   * Highlight
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/high)
   */
  high?: Maybe<Scalars["IntType"]>;
  /**
   * Halftone
   *
   * Applies a half-tone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/htn)
   */
  htn?: Maybe<Scalars["IntType"]>;
  /**
   * Hue Shift
   *
   * Adjusts the hue of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/hue)
   */
  hue?: Maybe<Scalars["IntType"]>;
  /**
   * Invert
   *
   * Inverts the colors on the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/invert)
   */
  invert?: Maybe<Scalars["BooleanType"]>;
  /**
   * Iptc Passthrough
   *
   * Determine if IPTC data should be passed for JPEG images.
   */
  iptc?: Maybe<ImgixParamsIptc>;
  /**
   * Lossless Compression
   *
   * Specifies that the output image should be a lossless variant.
   *
   * Depends on: `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/lossless)
   */
  lossless?: Maybe<Scalars["BooleanType"]>;
  /**
   * Watermark Alignment Mode
   *
   * Changes the watermark alignment relative to the parent image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-align)
   */
  markAlign?: Maybe<Array<ImgixParamsMarkAlign>>;
  /**
   * Watermark Alpha
   *
   * Changes the alpha of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-alpha)
   */
  markAlpha?: Maybe<Scalars["IntType"]>;
  /**
   * Watermark Base Url
   *
   * Changes base URL of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-base)
   */
  markBase?: Maybe<Scalars["String"]>;
  /**
   * Watermark Fit Mode
   *
   * Specifies the fit mode for watermark images.
   *
   * Depends on: `mark`, `markw`, `markh`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-fit)
   */
  markFit?: Maybe<ImgixParamsMarkFit>;
  /**
   * Watermark Height
   *
   * Adjusts the height of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-h)
   */
  markH?: Maybe<Scalars["FloatType"]>;
  /**
   * Watermark Padding
   *
   * Applies padding to the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-pad)
   */
  markPad?: Maybe<Scalars["IntType"]>;
  /**
   * Watermark Rotation
   *
   * Rotates a watermark or tiled watermarks by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-rot)
   */
  markRot?: Maybe<Scalars["FloatType"]>;
  /**
   * Watermark Scale
   *
   * Adjusts the scale of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-scale)
   */
  markScale?: Maybe<Scalars["IntType"]>;
  /**
   * Watermark Tile
   *
   * Adds tiled watermark.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-tile)
   */
  markTile?: Maybe<ImgixParamsMarkTile>;
  /**
   * Watermark Width
   *
   * Adjusts the width of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-w)
   */
  markW?: Maybe<Scalars["FloatType"]>;
  /**
   * Watermark X Position
   *
   * Adjusts the x-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-x)
   */
  markX?: Maybe<Scalars["IntType"]>;
  /**
   * Watermark Y Position
   *
   * Adjusts the y-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-y)
   */
  markY?: Maybe<Scalars["IntType"]>;
  /**
   * Watermark Image Url
   *
   * Specifies the location of the watermark image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark)
   */
  mark?: Maybe<Scalars["String"]>;
  /**
   * Mask Background Color
   *
   * Colors the background of the transparent mask area of images
   *
   * Depends on: `mask`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/mask-bg)
   */
  maskBg?: Maybe<Scalars["String"]>;
  /**
   * Mask Type
   *
   * Defines the type of mask and specifies the URL if that type is selected.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask)
   */
  mask?: Maybe<Scalars["String"]>;
  /**
   * Maximum Height
   *
   * Specifies the maximum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-height)
   */
  maxH?: Maybe<Scalars["IntType"]>;
  /**
   * Maximum Width
   *
   * Specifies the maximum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-width)
   */
  maxW?: Maybe<Scalars["IntType"]>;
  /**
   * Minimum Height
   *
   * Specifies the minimum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-height)
   */
  minH?: Maybe<Scalars["IntType"]>;
  /**
   * Minimum Width
   *
   * Specifies the minimum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-width)
   */
  minW?: Maybe<Scalars["IntType"]>;
  /**
   * Monochrome
   *
   * Applies a monochrome effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/monochrome)
   */
  monochrome?: Maybe<Scalars["String"]>;
  /**
   * Noise Reduction Bound
   *
   * Reduces the noise in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nr)
   */
  nr?: Maybe<Scalars["IntType"]>;
  /**
   * Noise Reduction Sharpen
   *
   * Provides a threshold by which to sharpen an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nrs)
   */
  nrs?: Maybe<Scalars["IntType"]>;
  /**
   * Orientation
   *
   * Changes the image orientation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/orient)
   */
  orient?: Maybe<Scalars["IntType"]>;
  /**
   * Padding Bottom
   *
   * Sets bottom padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-bottom)
   */
  padBottom?: Maybe<Scalars["IntType"]>;
  /**
   * Padding Left
   *
   * Sets left padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-left)
   */
  padLeft?: Maybe<Scalars["IntType"]>;
  /**
   * Padding Right
   *
   * Sets right padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-right)
   */
  padRight?: Maybe<Scalars["IntType"]>;
  /**
   * Padding Top
   *
   * Sets top padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-top)
   */
  padTop?: Maybe<Scalars["IntType"]>;
  /**
   * Padding
   *
   * Pads an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad)
   */
  pad?: Maybe<Scalars["IntType"]>;
  /**
   * Pdf Page Number
   *
   * Selects a page from a PDF for display.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/page)
   */
  page?: Maybe<Scalars["IntType"]>;
  /**
   * Color Palette Extraction
   *
   * Specifies an output format for palette-extraction.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/palette)
   */
  palette?: Maybe<ImgixParamsPalette>;
  /**
   * Pdf Annotation
   *
   * Enables or disables PDF annotation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/pdf-annotation)
   */
  pdfAnnotation?: Maybe<Scalars["BooleanType"]>;
  /**
   * Css Prefix
   *
   * Specifies a CSS prefix for all classes in palette-extraction.
   *
   * Depends on: `palette=css`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/prefix)
   */
  prefix?: Maybe<Scalars["String"]>;
  /**
   * Pixellate
   *
   * Applies a pixelation effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/px)
   */
  px?: Maybe<Scalars["IntType"]>;
  /**
   * Output Quality
   *
   * Adjusts the quality of an output image.
   *
   * Depends on: `fm=jpg`, `fm=pjpg`, `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/q)
   */
  q?: Maybe<Scalars["IntType"]>;
  /**
   * Source Rectangle Region
   *
   * Crops an image to a specified rectangle.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/rect)
   */
  rect?: Maybe<Scalars["String"]>;
  /**
   * Rotation
   *
   * Rotates an image by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/rot)
   */
  rot?: Maybe<Scalars["FloatType"]>;
  /**
   * Saturation
   *
   * Adjusts the saturation of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sat)
   */
  sat?: Maybe<Scalars["IntType"]>;
  /**
   * Sepia Tone
   *
   * Applies a sepia effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/sepia)
   */
  sepia?: Maybe<Scalars["IntType"]>;
  /**
   * Shadow
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/shad)
   */
  shad?: Maybe<Scalars["FloatType"]>;
  /**
   * Sharpen
   *
   * Adjusts the sharpness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sharp)
   */
  sharp?: Maybe<Scalars["FloatType"]>;
  /**
   * Transparency
   *
   * Adds checkerboard behind images which support transparency.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/transparency)
   */
  transparency?: Maybe<ImgixParamsTransparency>;
  /**
   * Trim Color
   *
   * Specifies a trim color on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-color)
   */
  trimColor?: Maybe<Scalars["String"]>;
  /**
   * Trim Mean Difference
   *
   * Specifies the mean difference on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-md)
   */
  trimMd?: Maybe<Scalars["FloatType"]>;
  /**
   * Trim Padding
   *
   * Pads the area of the source image before trimming.
   *
   * Depends on: `trim`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-pad)
   */
  trimPad?: Maybe<Scalars["IntType"]>;
  /**
   * Trim Standard Deviation
   *
   * Specifies the standard deviation on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-sd)
   */
  trimSd?: Maybe<Scalars["FloatType"]>;
  /**
   * Trim Tolerance
   *
   * Specifies the tolerance on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-tol)
   */
  trimTol?: Maybe<Scalars["FloatType"]>;
  /**
   * Trim Image
   *
   * Trims the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim)
   */
  trim?: Maybe<ImgixParamsTrim>;
  /**
   * Text Align
   *
   * Sets the vertical and horizontal alignment of rendered text relative to the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-align)
   */
  txtAlign?: Maybe<Array<ImgixParamsTxtAlign>>;
  /**
   * Text Clipping Mode
   *
   * Sets the clipping properties of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-clip)
   */
  txtClip?: Maybe<Array<ImgixParamsTxtClip>>;
  /**
   * Text Color
   *
   * Specifies the color of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-color)
   */
  txtColor?: Maybe<Scalars["String"]>;
  /**
   * Text Fit Mode
   *
   * Specifies the fit approach for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-fit)
   */
  txtFit?: Maybe<ImgixParamsTxtFit>;
  /**
   * Text Font
   *
   * Selects a font for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-font)
   */
  txtFont?: Maybe<Scalars["String"]>;
  /**
   * Text Leading
   *
   * Sets the leading (line spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-lead)
   */
  txtLead?: Maybe<Scalars["IntType"]>;
  /**
   * Text Ligatures
   *
   * Controls the level of ligature substitution
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-lig)
   */
  txtLig?: Maybe<Scalars["IntType"]>;
  /**
   * Text Outline Color
   *
   * Specifies a text outline color.
   *
   * Depends on: `txt`, `txtline`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line-color)
   */
  txtLineColor?: Maybe<Scalars["String"]>;
  /**
   * Text Outline
   *
   * Outlines the rendered text with a specified color.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line)
   */
  txtLine?: Maybe<Scalars["IntType"]>;
  /**
   * Text Padding
   *
   * Specifies the padding (in device-independent pixels) between a textbox and the edges of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-pad)
   */
  txtPad?: Maybe<Scalars["IntType"]>;
  /**
   * Text Shadow
   *
   * Applies a shadow to rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-shad)
   */
  txtShad?: Maybe<Scalars["FloatType"]>;
  /**
   * Text Font Size
   *
   * Sets the font size of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-size)
   */
  txtSize?: Maybe<Scalars["IntType"]>;
  /**
   * Text Tracking
   *
   * Sets the tracking (letter spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-track)
   */
  txtTrack?: Maybe<Scalars["IntType"]>;
  /**
   * Text Width
   *
   * Sets the width of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-width)
   */
  txtWidth?: Maybe<Scalars["IntType"]>;
  /**
   * Text String
   *
   * Sets the text string to render.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt)
   */
  txt?: Maybe<Scalars["String"]>;
  /**
   * Unsharp Mask
   *
   * Sharpens the source image using an unsharp mask.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usm)
   */
  usm?: Maybe<Scalars["IntType"]>;
  /**
   * Unsharp Mask Radius
   *
   * Specifies the radius for an unsharp mask operation.
   *
   * Depends on: `usm`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usmrad)
   */
  usmrad?: Maybe<Scalars["FloatType"]>;
  /**
   * Vibrance
   *
   * Adjusts the vibrance of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/vib)
   */
  vib?: Maybe<Scalars["IntType"]>;
  /**
   * Image Width
   *
   * Adjusts the width of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/w)
   */
  w?: Maybe<Scalars["FloatType"]>;
};

export enum ImgixParamsAuto {
  ENHANCE = "enhance",
  FORMAT = "format",
  REDEYE = "redeye",
  COMPRESS = "compress",
}

export enum ImgixParamsBlendAlign {
  TOP = "top",
  BOTTOM = "bottom",
  MIDDLE = "middle",
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}

export enum ImgixParamsBlendCrop {
  TOP = "top",
  BOTTOM = "bottom",
  LEFT = "left",
  RIGHT = "right",
  FACES = "faces",
}

export enum ImgixParamsBlendFit {
  CLAMP = "clamp",
  CLIP = "clip",
  CROP = "crop",
  SCALE = "scale",
  MAX = "max",
}

export enum ImgixParamsBlendMode {
  COLOR = "color",
  BURN = "burn",
  DODGE = "dodge",
  DARKEN = "darken",
  DIFFERENCE = "difference",
  EXCLUSION = "exclusion",
  HARDLIGHT = "hardlight",
  HUE = "hue",
  LIGHTEN = "lighten",
  LUMINOSITY = "luminosity",
  MULTIPLY = "multiply",
  OVERLAY = "overlay",
  SATURATION = "saturation",
  SCREEN = "screen",
  SOFTLIGHT = "softlight",
  NORMAL = "normal",
}

export enum ImgixParamsBlendSize {
  INHERIT = "inherit",
}

export enum ImgixParamsCh {
  WIDTH = "width",
  DPR = "dpr",
  SAVEDATA = "saveData",
}

export enum ImgixParamsCrop {
  TOP = "top",
  BOTTOM = "bottom",
  LEFT = "left",
  RIGHT = "right",
  FACES = "faces",
  ENTROPY = "entropy",
  EDGES = "edges",
  FOCALPOINT = "focalpoint",
}

export enum ImgixParamsCs {
  SRGB = "srgb",
  ADOBERGB1998 = "adobergb1998",
  TINYSRGB = "tinysrgb",
  STRIP = "strip",
}

export enum ImgixParamsFill {
  SOLID = "solid",
  BLUR = "blur",
}

export enum ImgixParamsFit {
  CLAMP = "clamp",
  CLIP = "clip",
  CROP = "crop",
  FACEAREA = "facearea",
  FILL = "fill",
  FILLMAX = "fillmax",
  MAX = "max",
  MIN = "min",
  SCALE = "scale",
}

export enum ImgixParamsFlip {
  H = "h",
  V = "v",
  HV = "hv",
}

export enum ImgixParamsFm {
  GIF = "gif",
  JPG = "jpg",
  JP2 = "jp2",
  JSON = "json",
  JXR = "jxr",
  PJPG = "pjpg",
  MP4 = "mp4",
  PNG = "png",
  PNG8 = "png8",
  PNG32 = "png32",
  WEBP = "webp",
  WEBM = "webm",
  BLURHASH = "blurhash",
  AVIF = "avif",
}

export enum ImgixParamsIptc {
  ALLOW = "allow",
  BLOCK = "block",
}

export enum ImgixParamsMarkAlign {
  TOP = "top",
  MIDDLE = "middle",
  BOTTOM = "bottom",
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum ImgixParamsMarkFit {
  CLIP = "clip",
  CROP = "crop",
  FILL = "fill",
  MAX = "max",
  SCALE = "scale",
}

export enum ImgixParamsMarkTile {
  GRID = "grid",
}

export enum ImgixParamsPalette {
  CSS = "css",
  JSON = "json",
}

export enum ImgixParamsTransparency {
  GRID = "grid",
}

export enum ImgixParamsTrim {
  AUTO = "auto",
  COLOR = "color",
}

export enum ImgixParamsTxtAlign {
  TOP = "top",
  MIDDLE = "middle",
  BOTTOM = "bottom",
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum ImgixParamsTxtClip {
  START = "start",
  MIDDLE = "middle",
  END = "end",
  ELLIPSIS = "ellipsis",
}

export enum ImgixParamsTxtFit {
  MAX = "max",
}

/** Specifies how to filter Integer fields */
export type IntegerFilter = {
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: Maybe<Scalars["IntType"]>;
  /** Filter records with a value that's less than the one specified */
  lt?: Maybe<Scalars["IntType"]>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: Maybe<Scalars["IntType"]>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: Maybe<Scalars["IntType"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars["BooleanType"]>;
  /** Search for records with an exact match */
  eq?: Maybe<Scalars["IntType"]>;
  /** Exclude records with an exact match */
  neq?: Maybe<Scalars["IntType"]>;
};

/** Specifies how to filter by usage */
export type InUseFilter = {
  /** Search uploads that are currently used by some record or not */
  eq?: Maybe<Scalars["BooleanType"]>;
};

/** Specifies how to filter by ID */
export type ItemIdFilter = {
  /** Search the record with the specified ID */
  eq?: Maybe<Scalars["ItemId"]>;
  /** Exclude the record with the specified ID */
  neq?: Maybe<Scalars["ItemId"]>;
  /** Search records with the specified IDs */
  in?: Maybe<Array<Maybe<Scalars["ItemId"]>>>;
  /** Search records that do not have the specified IDs */
  notIn?: Maybe<Array<Maybe<Scalars["ItemId"]>>>;
};

export enum ItemStatus {
  DRAFT = "draft",
  UPDATED = "updated",
  PUBLISHED = "published",
}

export type Letter = {
  __typename?: "Letter";
  urn: Scalars["String"];
  user: User;
  orderItem: OrderItem;
  product: ProductRecord;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  addressLine1: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  addressTown: Scalars["String"];
  addressCounty?: Maybe<Scalars["String"]>;
  addressUsState?: Maybe<UsState>;
  addressPostcode: Scalars["String"];
  addressCountry: Country;
  date: Scalars["DateTime"];
  boyGirl: BoyGirl;
  ageYears?: Maybe<Scalars["Int"]>;
  ageMonths?: Maybe<Scalars["Int"]>;
  hobby?: Maybe<Scalars["String"]>;
  gift?: Maybe<Scalars["String"]>;
  friend?: Maybe<Scalars["String"]>;
  signature: LetterSignatureRecord;
  frontDoor?: Maybe<FrontDoorRecord>;
  pet1?: Maybe<PetRecord>;
  pet1Name?: Maybe<Scalars["String"]>;
  pet2?: Maybe<PetRecord>;
  pet2Name?: Maybe<Scalars["String"]>;
  ps?: Maybe<LetterPostscriptRecord>;
  psCustom?: Maybe<Scalars["String"]>;
  template: LetterTemplateRecord;
  secretAccessLogin?: Maybe<Scalars["String"]>;
  secretAccessPassword?: Maybe<Scalars["String"]>;
  isEditable: Scalars["Boolean"];
  timeCreated: Scalars["DateTime"];
  timeUpdated: Scalars["DateTime"];
};

export type LetterTemplateArgs = {
  locale?: Maybe<SiteLocale>;
};

export type LetterCreateInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  addressLine1: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  addressTown: Scalars["String"];
  addressCounty?: Maybe<Scalars["String"]>;
  addressUsStateUrn?: Maybe<Scalars["String"]>;
  addressPostcode: Scalars["String"];
  addressCountryUrn: Scalars["String"];
  date: Scalars["String"];
  boyGirl: BoyGirl;
  ageYears?: Maybe<Scalars["Int"]>;
  ageMonths?: Maybe<Scalars["Int"]>;
  hobby?: Maybe<Scalars["String"]>;
  gift?: Maybe<Scalars["String"]>;
  friend?: Maybe<Scalars["String"]>;
  signatureId: Scalars["String"];
  frontDoorId?: Maybe<Scalars["String"]>;
  pet1Id?: Maybe<Scalars["String"]>;
  pet1Name?: Maybe<Scalars["String"]>;
  pet2Id?: Maybe<Scalars["String"]>;
  pet2Name?: Maybe<Scalars["String"]>;
  psId?: Maybe<Scalars["String"]>;
  psCustom?: Maybe<Scalars["String"]>;
  templateId: Scalars["String"];
};

export type LetterPostscriptModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  showOnSite?: Maybe<BooleanFilter>;
  text?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<LetterPostscriptModelFilter>>>;
};

export enum LetterPostscriptModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  TEXT_ASC = "text_ASC",
  TEXT_DESC = "text_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
}

/** Record of type Letter Postscript (letter_postscript) */
export type LetterPostscriptRecord = {
  __typename?: "LetterPostscriptRecord";
  _allLabelLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allTextLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  text?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Letter Postscript (letter_postscript) */
export type LetterPostscriptRecord_AllLabelLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Postscript (letter_postscript) */
export type LetterPostscriptRecord_AllTextLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Postscript (letter_postscript) */
export type LetterPostscriptRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Postscript (letter_postscript) */
export type LetterPostscriptRecordLabelArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Postscript (letter_postscript) */
export type LetterPostscriptRecordTextArgs = {
  locale?: Maybe<SiteLocale>;
};

export type LetterSearchFilterInput = {
  userUrn?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
  dateFrom?: Maybe<Scalars["String"]>;
  dateTo?: Maybe<Scalars["String"]>;
  products?: Maybe<Array<ProductAlphaId>>;
};

export type LetterSignatureModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  showOnSite?: Maybe<BooleanFilter>;
  text?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<LetterSignatureModelFilter>>>;
};

export enum LetterSignatureModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  TEXT_ASC = "text_ASC",
  TEXT_DESC = "text_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
}

/** Record of type Letter Signature (letter_signature) */
export type LetterSignatureRecord = {
  __typename?: "LetterSignatureRecord";
  _allLabelLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allTextLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  text?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Letter Signature (letter_signature) */
export type LetterSignatureRecord_AllLabelLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Signature (letter_signature) */
export type LetterSignatureRecord_AllTextLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Signature (letter_signature) */
export type LetterSignatureRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Signature (letter_signature) */
export type LetterSignatureRecordLabelArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Signature (letter_signature) */
export type LetterSignatureRecordTextArgs = {
  locale?: Maybe<SiteLocale>;
};

export type LettersSearchEdge = {
  __typename?: "LettersSearchEdge";
  node: Letter;
  cursor?: Maybe<Scalars["String"]>;
};

export type LettersSearchResponse = {
  __typename?: "LettersSearchResponse";
  totalCount?: Maybe<Scalars["Int"]>;
  edges: Array<LettersSearchEdge>;
};

export type LetterTemplateModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  sortGroup?: Maybe<IntegerFilter>;
  description?: Maybe<StringFilter>;
  showOnSite?: Maybe<BooleanFilter>;
  template?: Maybe<TextFilter>;
  label?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<LetterTemplateModelFilter>>>;
};

export enum LetterTemplateModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  SORTGROUP_ASC = "sortGroup_ASC",
  SORTGROUP_DESC = "sortGroup_DESC",
  DESCRIPTION_ASC = "description_ASC",
  DESCRIPTION_DESC = "description_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
}

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecord = {
  __typename?: "LetterTemplateRecord";
  _allDescriptionLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allLabelLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allShowOnSiteLocales?: Maybe<Array<Maybe<BooleanTypeMultiLocaleField>>>;
  _allTemplateLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  sortGroup?: Maybe<Scalars["IntType"]>;
  template?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecord_AllDescriptionLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecord_AllLabelLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecord_AllShowOnSiteLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecord_AllTemplateLocalesArgs = {
  markdown?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecordDescriptionArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecordLabelArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecordShowOnSiteArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Letter Template (letter_template) */
export type LetterTemplateRecordTemplateArgs = {
  markdown?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<SiteLocale>;
};

export type LetterUpdateInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  addressLine1: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  addressTown: Scalars["String"];
  addressCounty?: Maybe<Scalars["String"]>;
  addressUsStateUrn?: Maybe<Scalars["String"]>;
  addressPostcode: Scalars["String"];
  addressCountryUrn: Scalars["String"];
  date: Scalars["String"];
  boyGirl: BoyGirl;
  ageYears?: Maybe<Scalars["Int"]>;
  ageMonths?: Maybe<Scalars["Int"]>;
  hobby?: Maybe<Scalars["String"]>;
  gift?: Maybe<Scalars["String"]>;
  friend?: Maybe<Scalars["String"]>;
  signatureId: Scalars["String"];
  frontDoorId?: Maybe<Scalars["String"]>;
  pet1Id?: Maybe<Scalars["String"]>;
  pet1Name?: Maybe<Scalars["String"]>;
  pet2Id?: Maybe<Scalars["String"]>;
  pet2Name?: Maybe<Scalars["String"]>;
  psId?: Maybe<Scalars["String"]>;
  psCustom?: Maybe<Scalars["String"]>;
  templateId: Scalars["String"];
  secretAccessLogin?: Maybe<Scalars["String"]>;
  secretAccessPassword?: Maybe<Scalars["String"]>;
};

/** Specifies how to filter Single-link fields */
export type LinkFilter = {
  /** Search for records with an exact match. The specified value must be a Record ID */
  eq?: Maybe<Scalars["ItemId"]>;
  /** Exclude records with an exact match. The specified value must be a Record ID */
  neq?: Maybe<Scalars["ItemId"]>;
  /** Filter records linked to one of the specified records */
  in?: Maybe<Array<Maybe<Scalars["ItemId"]>>>;
  /** Filter records not linked to one of the specified records */
  notIn?: Maybe<Array<Maybe<Scalars["ItemId"]>>>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars["BooleanType"]>;
};

/** Specifies how to filter Multiple-links fields */
export type LinksFilter = {
  /** Search for records with an exact match. The specified values must be Record IDs */
  eq?: Maybe<Array<Maybe<Scalars["ItemId"]>>>;
  /** Filter records linked to all of the specified records. The specified values must be Record IDs */
  allIn?: Maybe<Array<Maybe<Scalars["ItemId"]>>>;
  /** Filter records linked to at least one of the specified records. The specified values must be Record IDs */
  anyIn?: Maybe<Array<Maybe<Scalars["ItemId"]>>>;
  /** Filter records not linked to any of the specified records. The specified values must be Record IDs */
  notIn?: Maybe<Array<Maybe<Scalars["ItemId"]>>>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars["BooleanType"]>;
};

export type MakeCallReservationResponse = {
  __typename?: "MakeCallReservationResponse";
  reservationUrn: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  placeOrder: Order;
  applyPromoCode: ApplyPromoCodeResponse;
  updateLetter: Letter;
  updateText: Text;
  updateSack: Sack;
  updateCall: Call;
  makeCallReservation: MakeCallReservationResponse;
  makeReservationFollowing: MakeCallReservationResponse;
  swapReservationForNonDoubleSlot: MakeCallReservationResponse;
  updateVideo: Video;
  createVideoLogin: CreateVideoLoginResponse;
  addToMailingList: SuccessResponse;
};

export type MutationPlaceOrderArgs = {
  paymentToken?: Maybe<Scalars["String"]>;
  items: Array<OrderItemInput>;
  promoCodeUrn?: Maybe<Scalars["String"]>;
  siteCountryCode: SiteCountryCode;
};

export type MutationApplyPromoCodeArgs = {
  code: Scalars["String"];
  total: Scalars["Float"];
};

export type MutationUpdateLetterArgs = {
  letterUrn: Scalars["String"];
  userUrn?: Maybe<Scalars["String"]>;
  details: LetterUpdateInput;
};

export type MutationUpdateTextArgs = {
  textUrn: Scalars["String"];
  userUrn?: Maybe<Scalars["String"]>;
  details: TextUpdateInput;
};

export type MutationUpdateSackArgs = {
  sackUrn: Scalars["String"];
  userUrn?: Maybe<Scalars["String"]>;
  details: SackUpdateInput;
};

export type MutationUpdateCallArgs = {
  callUrn: Scalars["String"];
  userUrn?: Maybe<Scalars["String"]>;
  details: CallUpdateInput;
};

export type MutationMakeCallReservationArgs = {
  time: Scalars["String"];
  timezoneUrn: Scalars["String"];
  type: CallType;
};

export type MutationMakeReservationFollowingArgs = {
  reservationUrn: Scalars["String"];
  type: CallType;
};

export type MutationSwapReservationForNonDoubleSlotArgs = {
  reservationUrn: Scalars["String"];
  callType: CallType;
};

export type MutationUpdateVideoArgs = {
  videoUrn: Scalars["String"];
  userUrn?: Maybe<Scalars["String"]>;
  details: VideoUpdateInput;
};

export type MutationCreateVideoLoginArgs = {
  videoUrn?: Maybe<Scalars["String"]>;
  letterUrn?: Maybe<Scalars["String"]>;
  usernamePrefix: Scalars["String"];
};

export type MutationAddToMailingListArgs = {
  email: Scalars["String"];
  name: Scalars["String"];
};

export enum MuxThumbnailFormatType {
  JPG = "jpg",
  PNG = "png",
  GIF = "gif",
}

export type Order = {
  __typename?: "Order";
  urn: Scalars["String"];
  user: User;
  siteCountryCode: SiteCountryCode;
  total: Scalars["Float"];
  orderItems: Array<OrderItem>;
  promoCode?: Maybe<PromoCode>;
  promoDiscount?: Maybe<Scalars["Float"]>;
  paymentTransaction?: Maybe<Transaction>;
  refundTransactions: Array<Transaction>;
  timeCreated: Scalars["DateTime"];
  timeUpdated: Scalars["DateTime"];
};

export type OrderItem = {
  __typename?: "OrderItem";
  urn: Scalars["String"];
  productAlphaId: ProductAlphaId;
  product: ProductRecord;
  price: Scalars["Float"];
  addOnProducts: Array<ProductRecord>;
  letter?: Maybe<Letter>;
  call?: Maybe<Call>;
  text?: Maybe<Text>;
  sack?: Maybe<Sack>;
  video?: Maybe<Video>;
  order: Order;
};

export type OrderItemProductArgs = {
  locale?: Maybe<SiteLocale>;
};

export type OrderItemAddOnProductsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type OrderItemInput = {
  alphaId: ProductAlphaId;
  addOns: Array<ProductAlphaId>;
  letterDetails?: Maybe<LetterCreateInput>;
  callDetails?: Maybe<CallCreateInput>;
  textDetails?: Maybe<TextCreateInput>;
  sackDetails?: Maybe<SackCreateInput>;
  videoDetails?: Maybe<VideoCreateInput>;
};

export type OrderSearchFilterInput = {
  userUrn?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
  dateFrom?: Maybe<Scalars["String"]>;
  dateTo?: Maybe<Scalars["String"]>;
  products?: Maybe<Array<ProductAlphaId>>;
};

export type OrdersSearchEdge = {
  __typename?: "OrdersSearchEdge";
  node: Order;
  cursor?: Maybe<Scalars["String"]>;
};

export type OrdersSearchResponse = {
  __typename?: "OrdersSearchResponse";
  totalCount?: Maybe<Scalars["Int"]>;
  edges: Array<OrdersSearchEdge>;
};

/** Specifies how to filter by image orientation */
export type OrientationFilter = {
  /** Search uploads with the specified orientation */
  eq?: Maybe<UploadOrientation>;
  /** Exclude uploads with the specified orientation */
  neq?: Maybe<UploadOrientation>;
};

export type PageModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  title?: Maybe<StringFilter>;
  alphaId?: Maybe<StringFilter>;
  sections?: Maybe<LinksFilter>;
  videoUrl?: Maybe<StringFilter>;
  headerImage?: Maybe<FileFilter>;
  videoFallbackImageUrl?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<PageModelFilter>>>;
};

export enum PageModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  TITLE_ASC = "title_ASC",
  TITLE_DESC = "title_DESC",
  ALPHAID_ASC = "alphaId_ASC",
  ALPHAID_DESC = "alphaId_DESC",
  VIDEOURL_ASC = "videoUrl_ASC",
  VIDEOURL_DESC = "videoUrl_DESC",
  VIDEOFALLBACKIMAGEURL_ASC = "videoFallbackImageUrl_ASC",
  VIDEOFALLBACKIMAGEURL_DESC = "videoFallbackImageUrl_DESC",
}

/** Record of type Page (page) */
export type PageRecord = {
  __typename?: "PageRecord";
  _allTitleLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  alphaId?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  headerImage?: Maybe<FileField>;
  id: Scalars["ItemId"];
  sections: Array<PageSectionRecord>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  videoFallbackImageUrl?: Maybe<Scalars["String"]>;
  videoUrl?: Maybe<Scalars["String"]>;
};

/** Record of type Page (page) */
export type PageRecord_AllTitleLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Page (page) */
export type PageRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Page (page) */
export type PageRecordTitleArgs = {
  locale?: Maybe<SiteLocale>;
};

export type PageSectionModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  body?: Maybe<TextFilter>;
  identifier?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<PageSectionModelFilter>>>;
};

export enum PageSectionModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  IDENTIFIER_ASC = "identifier_ASC",
  IDENTIFIER_DESC = "identifier_DESC",
}

/** Record of type Page Section (page_section) */
export type PageSectionRecord = {
  __typename?: "PageSectionRecord";
  _allBodyLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  body?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  identifier?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Page Section (page_section) */
export type PageSectionRecord_AllBodyLocalesArgs = {
  markdown?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<SiteLocale>;
};

/** Record of type Page Section (page_section) */
export type PageSectionRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Page Section (page_section) */
export type PageSectionRecordBodyArgs = {
  markdown?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<SiteLocale>;
};

export type PetModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  text?: Maybe<StringFilter>;
  textPlural?: Maybe<StringFilter>;
  showOnSite?: Maybe<BooleanFilter>;
  label?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<PetModelFilter>>>;
};

export enum PetModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  TEXT_ASC = "text_ASC",
  TEXT_DESC = "text_DESC",
  TEXTPLURAL_ASC = "textPlural_ASC",
  TEXTPLURAL_DESC = "textPlural_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
}

/** Record of type Pet (pet) */
export type PetRecord = {
  __typename?: "PetRecord";
  _allLabelLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allTextLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allTextPluralLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  text?: Maybe<Scalars["String"]>;
  textPlural?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Pet (pet) */
export type PetRecord_AllLabelLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Pet (pet) */
export type PetRecord_AllTextLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Pet (pet) */
export type PetRecord_AllTextPluralLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Pet (pet) */
export type PetRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Pet (pet) */
export type PetRecordLabelArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Pet (pet) */
export type PetRecordTextArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Pet (pet) */
export type PetRecordTextPluralArgs = {
  locale?: Maybe<SiteLocale>;
};

export type PlaceOrderResponse = {
  __typename?: "PlaceOrderResponse";
  orderUrn: Scalars["String"];
};

export enum ProductAlphaId {
  COOKIE_MIX = "COOKIE_MIX",
  DANGLY_ELF = "DANGLY_ELF",
  SANTA_PLUSH = "SANTA_PLUSH",
  SANTA_SACK = "SANTA_SACK",
  MAGIC_SNOW = "MAGIC_SNOW",
  SANTA_LETTER = "SANTA_LETTER",
  VIDEO_MESSAGE = "VIDEO_MESSAGE",
  INTERNATIONAL_SURCHARGE_LETTER = "INTERNATIONAL_SURCHARGE_LETTER",
  INTERNATIONAL_SURCHARGE_PRODUCT = "INTERNATIONAL_SURCHARGE_PRODUCT",
  PANDP_SURCHARGE = "PANDP_SURCHARGE",
  SANTA_CALL = "SANTA_CALL",
  SANTA_CALL_VIDEO = "SANTA_CALL_VIDEO",
  CALL_CERTIFICATE = "CALL_CERTIFICATE",
  SANTA_TEXT = "SANTA_TEXT",
  SANTA_TEXT_BONUS = "SANTA_TEXT_BONUS",
  SANTA_VIDEO_MESSAGE = "SANTA_VIDEO_MESSAGE",
}

export type ProductModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  productPageDescription?: Maybe<TextFilter>;
  image?: Maybe<FileFilter>;
  isAddOn?: Maybe<BooleanFilter>;
  isPopular?: Maybe<BooleanFilter>;
  addOns?: Maybe<LinksFilter>;
  postagePackingSurcharge?: Maybe<LinkFilter>;
  homePageDescription?: Maybe<TextFilter>;
  alphaId?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  internationalSurcharge?: Maybe<LinkFilter>;
  price?: Maybe<FloatFilter>;
  OR?: Maybe<Array<Maybe<ProductModelFilter>>>;
};

export enum ProductModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  ISADDON_ASC = "isAddOn_ASC",
  ISADDON_DESC = "isAddOn_DESC",
  ISPOPULAR_ASC = "isPopular_ASC",
  ISPOPULAR_DESC = "isPopular_DESC",
  ALPHAID_ASC = "alphaId_ASC",
  ALPHAID_DESC = "alphaId_DESC",
  DESCRIPTION_ASC = "description_ASC",
  DESCRIPTION_DESC = "description_DESC",
  TITLE_ASC = "title_ASC",
  TITLE_DESC = "title_DESC",
  PRICE_ASC = "price_ASC",
  PRICE_DESC = "price_DESC",
}

/** Record of type Product (product) */
export type ProductRecord = {
  __typename?: "ProductRecord";
  _allDescriptionLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allHomePageDescriptionLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allPriceLocales?: Maybe<Array<Maybe<FloatTypeMultiLocaleField>>>;
  _allProductPageDescriptionLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allTitleLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  addOns: Array<ProductRecord>;
  alphaId?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  homePageDescription?: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  image?: Maybe<FileField>;
  internationalSurcharge?: Maybe<ProductRecord>;
  isAddOn?: Maybe<Scalars["BooleanType"]>;
  isPopular?: Maybe<Scalars["BooleanType"]>;
  postagePackingSurcharge?: Maybe<ProductRecord>;
  price?: Maybe<Scalars["FloatType"]>;
  productPageDescription?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Product (product) */
export type ProductRecord_AllDescriptionLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecord_AllHomePageDescriptionLocalesArgs = {
  markdown?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecord_AllPriceLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecord_AllProductPageDescriptionLocalesArgs = {
  markdown?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecord_AllTitleLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecordDescriptionArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecordHomePageDescriptionArgs = {
  markdown?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecordPriceArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecordProductPageDescriptionArgs = {
  markdown?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecordTitleArgs = {
  locale?: Maybe<SiteLocale>;
};

export type PromoCode = {
  __typename?: "PromoCode";
  urn: Scalars["String"];
  code: Scalars["String"];
  type: Scalars["String"];
  amount?: Maybe<Scalars["Float"]>;
  dateExpires: Scalars["String"];
  isValid: Scalars["Boolean"];
  isSingleUseGlobally: Scalars["Boolean"];
  isSingleUseForCustomer: Scalars["Boolean"];
};

/** Specifies how to filter by publication datetime */
export type PublishedAtFilter = {
  /**
   * Filter records with a value that's strictly greater than the one specified.
   * Seconds and milliseconds are truncated from the argument.
   */
  gt?: Maybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: Maybe<Scalars["DateTime"]>;
  /**
   * Filter records with a value that's greater than or equal to than the one
   * specified. Seconds and milliseconds are truncated from the argument.
   */
  gte?: Maybe<Scalars["DateTime"]>;
  /**
   * Filter records with a value that's less or equal than the one specified.
   * Seconds and milliseconds are truncated from the argument.
   */
  lte?: Maybe<Scalars["DateTime"]>;
  /**
   * Filter records with a value that's within the specified minute range. Seconds
   * and milliseconds are truncated from the argument.
   */
  eq?: Maybe<Scalars["DateTime"]>;
  /**
   * Filter records with a value that's outside the specified minute range. Seconds
   * and milliseconds are truncated from the argument.
   */
  neq?: Maybe<Scalars["DateTime"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars["BooleanType"]>;
};

/** The query root for this schema */
export type Query = {
  __typename?: "Query";
  /** Returns meta information regarding a record collection */
  _allFirstNamesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allFrontDoorsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allGiftsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allHobbiesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allLetterPostscriptsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allLetterSignaturesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allLetterTemplatesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allPageSectionsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allPetsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allProductsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTextTemplatesMeta: CollectionMetadata;
  /** Returns meta information regarding an assets collection */
  _allUploadsMeta?: Maybe<CollectionMetadata>;
  /** Returns the single instance record */
  _site: Site;
  /** Returns a collection of records */
  allFirstNames: Array<FirstNameRecord>;
  /** Returns a collection of records */
  allFrontDoors: Array<FrontDoorRecord>;
  /** Returns a collection of records */
  allGifts: Array<GiftRecord>;
  /** Returns a collection of records */
  allHobbies: Array<HobbyRecord>;
  /** Returns a collection of records */
  allLetterPostscripts: Array<LetterPostscriptRecord>;
  /** Returns a collection of records */
  allLetterSignatures: Array<LetterSignatureRecord>;
  /** Returns a collection of records */
  allLetterTemplates: Array<LetterTemplateRecord>;
  /** Returns a collection of records */
  allPageSections: Array<PageSectionRecord>;
  /** Returns a collection of records */
  allPages: Array<PageRecord>;
  /** Returns a collection of records */
  allPets: Array<PetRecord>;
  /** Returns a collection of records */
  allProducts: Array<ProductRecord>;
  /** Returns a collection of records */
  allTextTemplates: Array<TextTemplateRecord>;
  /** Returns a collection of assets */
  allUploads: Array<FileField>;
  availableCallDates: Array<AvailableCallDatesResponse>;
  availableCallSlots: Array<AvailableCallSlotsResponse>;
  braintreeToken: Scalars["String"];
  call?: Maybe<Call>;
  calls: CallsSearchResponse;
  checkSlotFollowingReservation: Scalars["Boolean"];
  checkSlotHasSantaWithFollowingAvailable: Scalars["Boolean"];
  countries: Array<Country>;
  country?: Maybe<Country>;
  /** Returns the single instance record */
  featureFlag?: Maybe<FeatureFlagRecord>;
  /** Returns a specific record */
  firstName?: Maybe<FirstNameRecord>;
  /** Returns a specific record */
  frontDoor?: Maybe<FrontDoorRecord>;
  /** Returns a specific record */
  gift?: Maybe<GiftRecord>;
  /** Returns a specific record */
  hobby?: Maybe<HobbyRecord>;
  letter?: Maybe<Letter>;
  /** Returns a specific record */
  letterPostscript?: Maybe<LetterPostscriptRecord>;
  /** Returns a specific record */
  letterSignature?: Maybe<LetterSignatureRecord>;
  /** Returns a specific record */
  letterTemplate?: Maybe<LetterTemplateRecord>;
  letters: LettersSearchResponse;
  order?: Maybe<Order>;
  orderItem?: Maybe<OrderItem>;
  orders: OrdersSearchResponse;
  /** Returns a specific record */
  page?: Maybe<PageRecord>;
  /** Returns a specific record */
  pageSection?: Maybe<PageSectionRecord>;
  /** Returns a specific record */
  pet?: Maybe<PetRecord>;
  /** Returns a specific record */
  product?: Maybe<ProductRecord>;
  promoCode?: Maybe<PromoCode>;
  sack?: Maybe<Sack>;
  sacks: SacksSearchResponse;
  santas: UsersSearchResponse;
  text?: Maybe<Text>;
  /** Returns a specific record */
  textTemplate?: Maybe<TextTemplateRecord>;
  texts: TextsSearchResponse;
  timezone?: Maybe<Timezone>;
  timezones: Array<Timezone>;
  /** Returns a specific asset */
  upload?: Maybe<FileField>;
  usState?: Maybe<UsState>;
  usStates: Array<UsState>;
  user?: Maybe<User>;
  validatePhoneNumber?: Maybe<ValidatePhoneNumberResponse>;
  video?: Maybe<Video>;
  videoByLogin?: Maybe<Video>;
  videos: VideosSearchResponse;
};

/** The query root for this schema */
export type Query_AllFirstNamesMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<FirstNameModelFilter>;
};

/** The query root for this schema */
export type Query_AllFrontDoorsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<FrontDoorModelFilter>;
};

/** The query root for this schema */
export type Query_AllGiftsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<GiftModelFilter>;
};

/** The query root for this schema */
export type Query_AllHobbiesMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<HobbyModelFilter>;
};

/** The query root for this schema */
export type Query_AllLetterPostscriptsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<LetterPostscriptModelFilter>;
};

/** The query root for this schema */
export type Query_AllLetterSignaturesMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<LetterSignatureModelFilter>;
};

/** The query root for this schema */
export type Query_AllLetterTemplatesMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<LetterTemplateModelFilter>;
};

/** The query root for this schema */
export type Query_AllPageSectionsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<PageSectionModelFilter>;
};

/** The query root for this schema */
export type Query_AllPagesMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<PageModelFilter>;
};

/** The query root for this schema */
export type Query_AllPetsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<PetModelFilter>;
};

/** The query root for this schema */
export type Query_AllProductsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<ProductModelFilter>;
};

/** The query root for this schema */
export type Query_AllTextTemplatesMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<TextTemplateModelFilter>;
};

/** The query root for this schema */
export type Query_AllUploadsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<UploadFilter>;
};

/** The query root for this schema */
export type Query_SiteArgs = {
  locale?: Maybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryAllFirstNamesArgs = {
  locale?: Maybe<SiteLocale>;
  skip?: Maybe<Scalars["IntType"]>;
  first?: Maybe<Scalars["IntType"]>;
  filter?: Maybe<FirstNameModelFilter>;
  orderBy?: Maybe<Array<Maybe<FirstNameModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllFrontDoorsArgs = {
  locale?: Maybe<SiteLocale>;
  skip?: Maybe<Scalars["IntType"]>;
  first?: Maybe<Scalars["IntType"]>;
  filter?: Maybe<FrontDoorModelFilter>;
  orderBy?: Maybe<Array<Maybe<FrontDoorModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllGiftsArgs = {
  locale?: Maybe<SiteLocale>;
  skip?: Maybe<Scalars["IntType"]>;
  first?: Maybe<Scalars["IntType"]>;
  filter?: Maybe<GiftModelFilter>;
  orderBy?: Maybe<Array<Maybe<GiftModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllHobbiesArgs = {
  locale?: Maybe<SiteLocale>;
  skip?: Maybe<Scalars["IntType"]>;
  first?: Maybe<Scalars["IntType"]>;
  filter?: Maybe<HobbyModelFilter>;
  orderBy?: Maybe<Array<Maybe<HobbyModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllLetterPostscriptsArgs = {
  locale?: Maybe<SiteLocale>;
  skip?: Maybe<Scalars["IntType"]>;
  first?: Maybe<Scalars["IntType"]>;
  filter?: Maybe<LetterPostscriptModelFilter>;
  orderBy?: Maybe<Array<Maybe<LetterPostscriptModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllLetterSignaturesArgs = {
  locale?: Maybe<SiteLocale>;
  skip?: Maybe<Scalars["IntType"]>;
  first?: Maybe<Scalars["IntType"]>;
  filter?: Maybe<LetterSignatureModelFilter>;
  orderBy?: Maybe<Array<Maybe<LetterSignatureModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllLetterTemplatesArgs = {
  locale?: Maybe<SiteLocale>;
  skip?: Maybe<Scalars["IntType"]>;
  first?: Maybe<Scalars["IntType"]>;
  filter?: Maybe<LetterTemplateModelFilter>;
  orderBy?: Maybe<Array<Maybe<LetterTemplateModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllPageSectionsArgs = {
  locale?: Maybe<SiteLocale>;
  skip?: Maybe<Scalars["IntType"]>;
  first?: Maybe<Scalars["IntType"]>;
  filter?: Maybe<PageSectionModelFilter>;
  orderBy?: Maybe<Array<Maybe<PageSectionModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllPagesArgs = {
  locale?: Maybe<SiteLocale>;
  skip?: Maybe<Scalars["IntType"]>;
  first?: Maybe<Scalars["IntType"]>;
  filter?: Maybe<PageModelFilter>;
  orderBy?: Maybe<Array<Maybe<PageModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllPetsArgs = {
  locale?: Maybe<SiteLocale>;
  skip?: Maybe<Scalars["IntType"]>;
  first?: Maybe<Scalars["IntType"]>;
  filter?: Maybe<PetModelFilter>;
  orderBy?: Maybe<Array<Maybe<PetModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllProductsArgs = {
  locale?: Maybe<SiteLocale>;
  skip?: Maybe<Scalars["IntType"]>;
  first?: Maybe<Scalars["IntType"]>;
  filter?: Maybe<ProductModelFilter>;
  orderBy?: Maybe<Array<Maybe<ProductModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllTextTemplatesArgs = {
  locale?: Maybe<SiteLocale>;
  skip?: Maybe<Scalars["IntType"]>;
  first?: Maybe<Scalars["IntType"]>;
  filter?: Maybe<TextTemplateModelFilter>;
  orderBy?: Maybe<Array<Maybe<TextTemplateModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllUploadsArgs = {
  locale?: Maybe<SiteLocale>;
  skip?: Maybe<Scalars["IntType"]>;
  first?: Maybe<Scalars["IntType"]>;
  filter?: Maybe<UploadFilter>;
  orderBy?: Maybe<Array<Maybe<UploadOrderBy>>>;
};

/** The query root for this schema */
export type QueryAvailableCallDatesArgs = {
  start: Scalars["String"];
  end: Scalars["String"];
  timezoneUrn: Scalars["String"];
  type: CallType;
};

/** The query root for this schema */
export type QueryAvailableCallSlotsArgs = {
  date: Scalars["String"];
  timezoneUrn: Scalars["String"];
  type: CallType;
};

/** The query root for this schema */
export type QueryCallArgs = {
  callUrn: Scalars["String"];
  userUrn?: Maybe<Scalars["String"]>;
};

/** The query root for this schema */
export type QueryCallsArgs = {
  query?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Int"]>;
  searchAfter?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["String"]>;
  filter?: Maybe<CallSearchFilterInput>;
  from?: Maybe<Scalars["Int"]>;
};

/** The query root for this schema */
export type QueryCheckSlotFollowingReservationArgs = {
  reservationUrn: Scalars["String"];
  type: CallType;
};

/** The query root for this schema */
export type QueryCheckSlotHasSantaWithFollowingAvailableArgs = {
  time: Scalars["String"];
  timezoneUrn: Scalars["String"];
  type: CallType;
};

/** The query root for this schema */
export type QueryCountriesArgs = {
  isTextAccepted?: Maybe<Scalars["Boolean"]>;
};

/** The query root for this schema */
export type QueryCountryArgs = {
  countryUrn: Scalars["String"];
};

/** The query root for this schema */
export type QueryFeatureFlagArgs = {
  locale?: Maybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryFirstNameArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<FirstNameModelFilter>;
  orderBy?: Maybe<Array<Maybe<FirstNameModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryFrontDoorArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<FrontDoorModelFilter>;
  orderBy?: Maybe<Array<Maybe<FrontDoorModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryGiftArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<GiftModelFilter>;
  orderBy?: Maybe<Array<Maybe<GiftModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryHobbyArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<HobbyModelFilter>;
  orderBy?: Maybe<Array<Maybe<HobbyModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryLetterArgs = {
  letterUrn: Scalars["String"];
  userUrn?: Maybe<Scalars["String"]>;
};

/** The query root for this schema */
export type QueryLetterPostscriptArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<LetterPostscriptModelFilter>;
  orderBy?: Maybe<Array<Maybe<LetterPostscriptModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryLetterSignatureArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<LetterSignatureModelFilter>;
  orderBy?: Maybe<Array<Maybe<LetterSignatureModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryLetterTemplateArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<LetterTemplateModelFilter>;
  orderBy?: Maybe<Array<Maybe<LetterTemplateModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryLettersArgs = {
  query?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Int"]>;
  searchAfter?: Maybe<Scalars["String"]>;
  filter?: Maybe<LetterSearchFilterInput>;
};

/** The query root for this schema */
export type QueryOrderArgs = {
  orderUrn: Scalars["String"];
};

/** The query root for this schema */
export type QueryOrderItemArgs = {
  orderItemUrn: Scalars["String"];
};

/** The query root for this schema */
export type QueryOrdersArgs = {
  query?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Int"]>;
  searchAfter?: Maybe<Scalars["String"]>;
  filter?: Maybe<OrderSearchFilterInput>;
  sort?: Maybe<Scalars["String"]>;
  sortDirection?: Maybe<SortDirection>;
  from?: Maybe<Scalars["Int"]>;
  isAdmin?: Maybe<Scalars["Boolean"]>;
};

/** The query root for this schema */
export type QueryPageArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<PageModelFilter>;
  orderBy?: Maybe<Array<Maybe<PageModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryPageSectionArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<PageSectionModelFilter>;
  orderBy?: Maybe<Array<Maybe<PageSectionModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryPetArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<PetModelFilter>;
  orderBy?: Maybe<Array<Maybe<PetModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryProductArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<ProductModelFilter>;
  orderBy?: Maybe<Array<Maybe<ProductModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryPromoCodeArgs = {
  promoCodeUrn: Scalars["String"];
};

/** The query root for this schema */
export type QuerySackArgs = {
  sackUrn: Scalars["String"];
  userUrn?: Maybe<Scalars["String"]>;
};

/** The query root for this schema */
export type QuerySacksArgs = {
  query?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Int"]>;
  searchAfter?: Maybe<Scalars["String"]>;
  filter?: Maybe<SackSearchFilterInput>;
};

/** The query root for this schema */
export type QuerySantasArgs = {
  searchAfter?: Maybe<Scalars["String"]>;
};

/** The query root for this schema */
export type QueryTextArgs = {
  textUrn: Scalars["String"];
  userUrn?: Maybe<Scalars["String"]>;
};

/** The query root for this schema */
export type QueryTextTemplateArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<TextTemplateModelFilter>;
  orderBy?: Maybe<Array<Maybe<TextTemplateModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryTextsArgs = {
  query?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Int"]>;
  searchAfter?: Maybe<Scalars["String"]>;
  filter?: Maybe<TextSearchFilterInput>;
};

/** The query root for this schema */
export type QueryTimezoneArgs = {
  timezoneUrn: Scalars["String"];
};

/** The query root for this schema */
export type QueryUploadArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<UploadFilter>;
  orderBy?: Maybe<Array<Maybe<UploadOrderBy>>>;
};

/** The query root for this schema */
export type QueryUsStateArgs = {
  usStateUrn: Scalars["String"];
};

/** The query root for this schema */
export type QueryUserArgs = {
  userUrn: Scalars["String"];
};

/** The query root for this schema */
export type QueryValidatePhoneNumberArgs = {
  number: Scalars["String"];
  countryUrn: Scalars["String"];
};

/** The query root for this schema */
export type QueryVideoArgs = {
  videoUrn: Scalars["String"];
  userUrn?: Maybe<Scalars["String"]>;
};

/** The query root for this schema */
export type QueryVideoByLoginArgs = {
  username: Scalars["String"];
  password: Scalars["String"];
};

/** The query root for this schema */
export type QueryVideosArgs = {
  query?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Int"]>;
  searchAfter?: Maybe<Scalars["String"]>;
  filter?: Maybe<VideoSearchFilterInput>;
};

/** Specifies how to filter by upload type */
export type ResolutionFilter = {
  /** Search uploads with the specified resolution */
  eq?: Maybe<ResolutionType>;
  /** Exclude uploads with the specified resolution */
  neq?: Maybe<ResolutionType>;
  /** Search uploads with the specified resolutions */
  in?: Maybe<Array<Maybe<ResolutionType>>>;
  /** Search uploads without the specified resolutions */
  notIn?: Maybe<Array<Maybe<ResolutionType>>>;
};

export enum ResolutionType {
  ICON = "icon",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export type ResponsiveImage = {
  __typename?: "ResponsiveImage";
  alt?: Maybe<Scalars["String"]>;
  aspectRatio: Scalars["FloatType"];
  base64?: Maybe<Scalars["String"]>;
  bgColor?: Maybe<Scalars["String"]>;
  height: Scalars["IntType"];
  sizes: Scalars["String"];
  src: Scalars["String"];
  srcSet: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  webpSrcSet: Scalars["String"];
  width: Scalars["IntType"];
};

export type Sack = {
  __typename?: "Sack";
  urn: Scalars["String"];
  user: User;
  timeCreated: Scalars["DateTime"];
  timeUpdated: Scalars["DateTime"];
  orderItem: OrderItem;
  product: ProductRecord;
  addressUsState?: Maybe<UsState>;
  addressCountry: Country;
  name: Scalars["String"];
  addressLine1: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  addressTown: Scalars["String"];
  addressCounty?: Maybe<Scalars["String"]>;
  addressPostcode: Scalars["String"];
  date: Scalars["DateTime"];
};

export type SackCreateInput = {
  name: Scalars["String"];
  addressLine1: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  addressTown: Scalars["String"];
  addressCounty?: Maybe<Scalars["String"]>;
  addressUsStateUrn?: Maybe<Scalars["String"]>;
  addressPostcode: Scalars["String"];
  addressCountryUrn: Scalars["String"];
  date: Scalars["String"];
};

export type SackSearchFilterInput = {
  userUrn?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
  dateFrom?: Maybe<Scalars["String"]>;
  dateTo?: Maybe<Scalars["String"]>;
};

export type SacksSearchEdge = {
  __typename?: "SacksSearchEdge";
  node: Sack;
  cursor?: Maybe<Scalars["String"]>;
};

export type SacksSearchResponse = {
  __typename?: "SacksSearchResponse";
  totalCount?: Maybe<Scalars["Int"]>;
  edges: Array<SacksSearchEdge>;
};

export type SackUpdateInput = {
  name: Scalars["String"];
  addressLine1: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  addressTown: Scalars["String"];
  addressCounty?: Maybe<Scalars["String"]>;
  addressUsStateUrn?: Maybe<Scalars["String"]>;
  addressPostcode: Scalars["String"];
  addressCountryUrn: Scalars["String"];
  date: Scalars["String"];
};

export type SeoField = {
  __typename?: "SeoField";
  description?: Maybe<Scalars["String"]>;
  image?: Maybe<FileField>;
  title?: Maybe<Scalars["String"]>;
  twitterCard?: Maybe<Scalars["String"]>;
};

export type Site = {
  __typename?: "Site";
  favicon?: Maybe<FileField>;
  faviconMetaTags: Array<Tag>;
  globalSeo?: Maybe<GlobalSeoField>;
  locales: Array<SiteLocale>;
};

export type SiteFaviconMetaTagsArgs = {
  variants?: Maybe<Array<Maybe<FaviconType>>>;
};

export type SiteGlobalSeoArgs = {
  locale?: Maybe<SiteLocale>;
};

export enum SiteCountryCode {
  GB = "GB",
  US = "US",
}

export enum SiteLocale {
  EN_GB = "en_GB",
  EN_US = "en_US",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

/** Specifies how to filter by status */
export type StatusFilter = {
  /** Search the record with the specified status */
  eq?: Maybe<ItemStatus>;
  /** Exclude the record with the specified status */
  neq?: Maybe<ItemStatus>;
  /** Search records with the specified statuses */
  in?: Maybe<Array<Maybe<ItemStatus>>>;
  /** Search records without the specified statuses */
  notIn?: Maybe<Array<Maybe<ItemStatus>>>;
};

/** Specifies how to filter Single-line string fields */
export type StringFilter = {
  /** Filter records based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: Maybe<Scalars["BooleanType"]>;
  /** Search for records with an exact match */
  eq?: Maybe<Scalars["String"]>;
  /** Exclude records with an exact match */
  neq?: Maybe<Scalars["String"]>;
  /** Filter records that equal one of the specified values */
  in?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Filter records that do not equal one of the specified values */
  notIn?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars["BooleanType"]>;
};

export type StringMatchesFilter = {
  pattern: Scalars["String"];
  caseSensitive?: Maybe<Scalars["BooleanType"]>;
  regexp?: Maybe<Scalars["BooleanType"]>;
};

export type StringMultiLocaleField = {
  __typename?: "StringMultiLocaleField";
  locale?: Maybe<SiteLocale>;
  value?: Maybe<Scalars["String"]>;
};

export type SuccessResponse = {
  __typename?: "SuccessResponse";
  isSuccess: Scalars["Boolean"];
};

export type Tag = {
  __typename?: "Tag";
  attributes?: Maybe<Scalars["MetaTagAttributes"]>;
  content?: Maybe<Scalars["String"]>;
  tag: Scalars["String"];
};

export type Text = {
  __typename?: "Text";
  urn: Scalars["String"];
  user: User;
  timezone: Timezone;
  phoneCountry: Country;
  phoneNumber: Scalars["String"];
  template: TextTemplateRecord;
  firstName: Scalars["String"];
  boyGirl: BoyGirl;
  ageYears: Scalars["Int"];
  town: Scalars["String"];
  pet?: Maybe<PetRecord>;
  petName?: Maybe<Scalars["String"]>;
  text1Time: Scalars["DateTime"];
  text1Body: Scalars["String"];
  text2Time: Scalars["DateTime"];
  text2Body: Scalars["String"];
  text3Time: Scalars["DateTime"];
  text3Body: Scalars["String"];
  bonusBody?: Maybe<Scalars["String"]>;
  text1TransactionId?: Maybe<Scalars["String"]>;
  text2TransactionId?: Maybe<Scalars["String"]>;
  text3TransactionId?: Maybe<Scalars["String"]>;
  bonusTextTransactionId?: Maybe<Scalars["String"]>;
  orderItem: OrderItem;
  status?: Maybe<TextStatus>;
  product: ProductRecord;
  isEditable: Scalars["Boolean"];
  timeCreated: Scalars["DateTime"];
  timeUpdated: Scalars["DateTime"];
};

export type TextCreateInput = {
  firstName: Scalars["String"];
  boyGirl: BoyGirl;
  ageYears: Scalars["Int"];
  town: Scalars["String"];
  petId?: Maybe<Scalars["String"]>;
  petName?: Maybe<Scalars["String"]>;
  templateId: Scalars["String"];
  phoneCountryUrn: Scalars["String"];
  phoneNumber: Scalars["String"];
  timezoneUrn: Scalars["String"];
  text1Time: Scalars["String"];
  text1Body: Scalars["String"];
  text2Time: Scalars["String"];
  text2Body: Scalars["String"];
  text3Time: Scalars["String"];
  text3Body: Scalars["String"];
  bonusBody?: Maybe<Scalars["String"]>;
};

/** Specifies how to filter text fields */
export type TextFilter = {
  /** Filter records based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: Maybe<Scalars["BooleanType"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars["BooleanType"]>;
};

export type TextSearchFilterInput = {
  userUrn?: Maybe<Scalars["String"]>;
  text1TimeFrom?: Maybe<Scalars["String"]>;
  text1TimeTo?: Maybe<Scalars["String"]>;
};

export type TextsSearchEdge = {
  __typename?: "TextsSearchEdge";
  node: Text;
  cursor?: Maybe<Scalars["String"]>;
};

export type TextsSearchResponse = {
  __typename?: "TextsSearchResponse";
  totalCount?: Maybe<Scalars["Int"]>;
  edges: Array<TextsSearchEdge>;
};

export enum TextStatus {
  PENDING = "PENDING",
  PARTIALLY_SCHEDULED = "PARTIALLY_SCHEDULED",
  SCHEDULED = "SCHEDULED",
}

export type TextTemplateModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  template2?: Maybe<TextFilter>;
  template1?: Maybe<TextFilter>;
  showOnSite?: Maybe<BooleanFilter>;
  template3?: Maybe<TextFilter>;
  label?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<TextTemplateModelFilter>>>;
};

export enum TextTemplateModelOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  CREATEDAT_ASC = "createdAt_ASC",
  CREATEDAT_DESC = "createdAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
  _FIRSTPUBLISHEDAT_ASC = "_firstPublishedAt_ASC",
  _FIRSTPUBLISHEDAT_DESC = "_firstPublishedAt_DESC",
  _PUBLICATIONSCHEDULEDAT_ASC = "_publicationScheduledAt_ASC",
  _PUBLICATIONSCHEDULEDAT_DESC = "_publicationScheduledAt_DESC",
  _UNPUBLISHINGSCHEDULEDAT_ASC = "_unpublishingScheduledAt_ASC",
  _UNPUBLISHINGSCHEDULEDAT_DESC = "_unpublishingScheduledAt_DESC",
  _PUBLISHEDAT_ASC = "_publishedAt_ASC",
  _PUBLISHEDAT_DESC = "_publishedAt_DESC",
  _STATUS_ASC = "_status_ASC",
  _STATUS_DESC = "_status_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  UPDATEDAT_ASC = "updatedAt_ASC",
  UPDATEDAT_DESC = "updatedAt_DESC",
  _ISVALID_ASC = "_isValid_ASC",
  _ISVALID_DESC = "_isValid_DESC",
  SHOWONSITE_ASC = "showOnSite_ASC",
  SHOWONSITE_DESC = "showOnSite_DESC",
  LABEL_ASC = "label_ASC",
  LABEL_DESC = "label_DESC",
}

/** Record of type Text Template (text_template) */
export type TextTemplateRecord = {
  __typename?: "TextTemplateRecord";
  _allLabelLocales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allTemplate1Locales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allTemplate2Locales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _allTemplate3Locales?: Maybe<Array<Maybe<StringMultiLocaleField>>>;
  _createdAt: Scalars["DateTime"];
  _firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt?: Maybe<Scalars["DateTime"]>;
  _publishedAt?: Maybe<Scalars["DateTime"]>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  label?: Maybe<Scalars["String"]>;
  showOnSite?: Maybe<Scalars["BooleanType"]>;
  template1?: Maybe<Scalars["String"]>;
  template2?: Maybe<Scalars["String"]>;
  template3?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecord_AllLabelLocalesArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecord_AllTemplate1LocalesArgs = {
  markdown?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<SiteLocale>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecord_AllTemplate2LocalesArgs = {
  markdown?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<SiteLocale>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecord_AllTemplate3LocalesArgs = {
  markdown?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<SiteLocale>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecordLabelArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecordTemplate1Args = {
  markdown?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<SiteLocale>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecordTemplate2Args = {
  markdown?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<SiteLocale>;
};

/** Record of type Text Template (text_template) */
export type TextTemplateRecordTemplate3Args = {
  markdown?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<SiteLocale>;
};

export type TextUpdateInput = {
  firstName?: Maybe<Scalars["String"]>;
  boyGirl?: Maybe<BoyGirl>;
  ageYears?: Maybe<Scalars["Int"]>;
  town?: Maybe<Scalars["String"]>;
  petId?: Maybe<Scalars["String"]>;
  petName?: Maybe<Scalars["String"]>;
  templateId?: Maybe<Scalars["String"]>;
  phoneCountryUrn?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  timezoneUrn?: Maybe<Scalars["String"]>;
  text1Time?: Maybe<Scalars["String"]>;
  text1Body?: Maybe<Scalars["String"]>;
  text2Time?: Maybe<Scalars["String"]>;
  text2Body?: Maybe<Scalars["String"]>;
  text3Time?: Maybe<Scalars["String"]>;
  text3Body?: Maybe<Scalars["String"]>;
  bonusBody?: Maybe<Scalars["String"]>;
  text1TransactionId?: Maybe<Scalars["String"]>;
  text2TransactionId?: Maybe<Scalars["String"]>;
  text3TransactionId?: Maybe<Scalars["String"]>;
  bonusTextTransactionId?: Maybe<Scalars["String"]>;
};

export type Timezone = {
  __typename?: "Timezone";
  urn: Scalars["String"];
  name: Scalars["String"];
  timezone: Scalars["String"];
  country: Country;
};

export type Transaction = {
  __typename?: "Transaction";
  urn: Scalars["String"];
  amount: Scalars["Float"];
  type: Scalars["String"];
  description: Scalars["String"];
};

/** Specifies how to filter by upload type */
export type TypeFilter = {
  /** Search uploads with the specified type */
  eq?: Maybe<UploadType>;
  /** Exclude uploads with the specified type */
  neq?: Maybe<UploadType>;
  /** Search uploads with the specified types */
  in?: Maybe<Array<Maybe<UploadType>>>;
  /** Search uploads without the specified types */
  notIn?: Maybe<Array<Maybe<UploadType>>>;
};

/** Specifies how to filter by update datetime */
export type UpdatedAtFilter = {
  /**
   * Filter records with a value that's strictly greater than the one specified.
   * Seconds and milliseconds are truncated from the argument.
   */
  gt?: Maybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: Maybe<Scalars["DateTime"]>;
  /**
   * Filter records with a value that's greater than or equal to than the one
   * specified. Seconds and milliseconds are truncated from the argument.
   */
  gte?: Maybe<Scalars["DateTime"]>;
  /**
   * Filter records with a value that's less or equal than the one specified.
   * Seconds and milliseconds are truncated from the argument.
   */
  lte?: Maybe<Scalars["DateTime"]>;
  /**
   * Filter records with a value that's within the specified minute range. Seconds
   * and milliseconds are truncated from the argument.
   */
  eq?: Maybe<Scalars["DateTime"]>;
  /**
   * Filter records with a value that's outside the specified minute range. Seconds
   * and milliseconds are truncated from the argument.
   */
  neq?: Maybe<Scalars["DateTime"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars["BooleanType"]>;
};

/** Specifies how to filter by default alt */
export type UploadAltFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Search the uploads with the specified alt */
  eq?: Maybe<Scalars["String"]>;
  /** Exclude the uploads with the specified alt */
  neq?: Maybe<Scalars["String"]>;
  /** Search uploads with the specified values as default alt */
  in?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Search uploads that do not have the specified values as default alt */
  notIn?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars["BooleanType"]>;
};

/** Specifies how to filter by auhtor */
export type UploadAuthorFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars["BooleanType"]>;
};

/** Specifies how to filter by basename */
export type UploadBasenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
};

/** Specifies how to filter by colors */
export type UploadColorsFilter = {
  /** Filter uploads that have the specified colors */
  contains?: Maybe<ColorBucketType>;
  /** Filter uploads that have all of the specified colors */
  allIn?: Maybe<Array<Maybe<ColorBucketType>>>;
  /** Filter uploads that have at least one of the specified colors */
  anyIn?: Maybe<Array<Maybe<ColorBucketType>>>;
  /** Filter uploads that do not have any of the specified colors */
  notIn?: Maybe<Array<Maybe<ColorBucketType>>>;
  /** Search for uploads with an exact match */
  eq?: Maybe<Array<Maybe<ColorBucketType>>>;
};

/** Specifies how to filter by copyright */
export type UploadCopyrightFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars["BooleanType"]>;
};

/** Specifies how to filter by creation datetime */
export type UploadCreatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: Maybe<Scalars["DateTime"]>;
  /** Exclude uploads with an exact match */
  neq?: Maybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: Maybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: Maybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: Maybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: Maybe<Scalars["DateTime"]>;
};

/** Specifies how to filter by filename */
export type UploadFilenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
};

export type UploadFilter = {
  type?: Maybe<TypeFilter>;
  inUse?: Maybe<InUseFilter>;
  resolution?: Maybe<ResolutionFilter>;
  size?: Maybe<UploadSizeFilter>;
  tags?: Maybe<UploadTagsFilter>;
  smartTags?: Maybe<UploadTagsFilter>;
  colors?: Maybe<UploadColorsFilter>;
  orientation?: Maybe<OrientationFilter>;
  id?: Maybe<UploadIdFilter>;
  mimeType?: Maybe<UploadMimeTypeFilter>;
  format?: Maybe<UploadFormatFilter>;
  height?: Maybe<UploadHeightFilter>;
  width?: Maybe<UploadWidthFilter>;
  alt?: Maybe<UploadAltFilter>;
  title?: Maybe<UploadTitleFilter>;
  notes?: Maybe<UploadNotesFilter>;
  md5?: Maybe<UploadMd5Filter>;
  author?: Maybe<UploadAuthorFilter>;
  copyright?: Maybe<UploadCopyrightFilter>;
  basename?: Maybe<UploadBasenameFilter>;
  filename?: Maybe<UploadFilenameFilter>;
  _createdAt?: Maybe<UploadCreatedAtFilter>;
  _updatedAt?: Maybe<UploadUpdatedAtFilter>;
  OR?: Maybe<Array<Maybe<UploadFilter>>>;
};

/** Specifies how to filter by format */
export type UploadFormatFilter = {
  /** Search the asset with the specified format */
  eq?: Maybe<Scalars["String"]>;
  /** Exclude the asset with the specified format */
  neq?: Maybe<Scalars["String"]>;
  /** Search assets with the specified formats */
  in?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Search assets that do not have the specified formats */
  notIn?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

/** Specifies how to filter by height */
export type UploadHeightFilter = {
  /** Search all assets larger than the specified height */
  gt?: Maybe<Scalars["IntType"]>;
  /** Search all assets smaller than the specified height */
  lt?: Maybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified height */
  gte?: Maybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified height */
  lte?: Maybe<Scalars["IntType"]>;
  /** Search assets with the specified height */
  eq?: Maybe<Scalars["IntType"]>;
  /** Search assets that do not have the specified height */
  neq?: Maybe<Scalars["IntType"]>;
};

/** Specifies how to filter by ID */
export type UploadIdFilter = {
  /** Search the asset with the specified ID */
  eq?: Maybe<Scalars["UploadId"]>;
  /** Exclude the asset with the specified ID */
  neq?: Maybe<Scalars["UploadId"]>;
  /** Search assets with the specified IDs */
  in?: Maybe<Array<Maybe<Scalars["UploadId"]>>>;
  /** Search assets that do not have the specified IDs */
  notIn?: Maybe<Array<Maybe<Scalars["UploadId"]>>>;
};

/** Specifies how to filter by MD5 */
export type UploadMd5Filter = {
  /** Search the asset with the specified MD5 */
  eq?: Maybe<Scalars["String"]>;
  /** Exclude the asset with the specified MD5 */
  neq?: Maybe<Scalars["String"]>;
  /** Search assets with the specified MD5s */
  in?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Search assets that do not have the specified MD5s */
  notIn?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

/** Specifies how to filter by mime type */
export type UploadMimeTypeFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Search the asset with the specified mime type */
  eq?: Maybe<Scalars["String"]>;
  /** Exclude the asset with the specified mime type */
  neq?: Maybe<Scalars["String"]>;
  /** Search assets with the specified mime types */
  in?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Search assets that do not have the specified mime types */
  notIn?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

/** Specifies how to filter by notes */
export type UploadNotesFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars["BooleanType"]>;
};

export enum UploadOrderBy {
  _CREATEDAT_ASC = "_createdAt_ASC",
  _CREATEDAT_DESC = "_createdAt_DESC",
  SIZE_ASC = "size_ASC",
  SIZE_DESC = "size_DESC",
  RESOLUTION_ASC = "resolution_ASC",
  RESOLUTION_DESC = "resolution_DESC",
  FILENAME_ASC = "filename_ASC",
  FILENAME_DESC = "filename_DESC",
  BASENAME_ASC = "basename_ASC",
  BASENAME_DESC = "basename_DESC",
  MIMETYPE_ASC = "mimeType_ASC",
  MIMETYPE_DESC = "mimeType_DESC",
  FORMAT_ASC = "format_ASC",
  FORMAT_DESC = "format_DESC",
  _UPDATEDAT_ASC = "_updatedAt_ASC",
  _UPDATEDAT_DESC = "_updatedAt_DESC",
  ID_ASC = "id_ASC",
  ID_DESC = "id_DESC",
}

export enum UploadOrientation {
  LANDSCAPE = "landscape",
  PORTRAIT = "portrait",
  SQUARE = "square",
}

/** Specifies how to filter by size */
export type UploadSizeFilter = {
  /** Search all assets larger than the specified size (in bytes) */
  gt?: Maybe<Scalars["IntType"]>;
  /** Search all assets smaller than the specified size (in bytes) */
  lt?: Maybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  gte?: Maybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  lte?: Maybe<Scalars["IntType"]>;
  /** Search assets with the specified size (in bytes) */
  eq?: Maybe<Scalars["IntType"]>;
  /** Search assets that do not have the specified size (in bytes) */
  neq?: Maybe<Scalars["IntType"]>;
};

/** Specifies how to filter by tags */
export type UploadTagsFilter = {
  /** Filter uploads linked to the specified tag */
  contains?: Maybe<Scalars["String"]>;
  /** Filter uploads linked to all of the specified tags */
  allIn?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Filter uploads linked to at least one of the specified tags */
  anyIn?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Filter uploads not linked to any of the specified tags */
  notIn?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Search for uploads with an exact match */
  eq?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

/** Specifies how to filter by default title */
export type UploadTitleFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Search the asset with the specified title */
  eq?: Maybe<Scalars["String"]>;
  /** Exclude the asset with the specified title */
  neq?: Maybe<Scalars["String"]>;
  /** Search assets with the specified as default title */
  in?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Search assets that do not have the specified as default title */
  notIn?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Filter assets with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars["BooleanType"]>;
};

export enum UploadType {
  IMAGE = "image",
  AUDIO = "audio",
  VIDEO = "video",
  RICHTEXT = "richtext",
  PRESENTATION = "presentation",
  SPREADSHEET = "spreadsheet",
  PDFDOCUMENT = "pdfdocument",
  ARCHIVE = "archive",
}

/** Specifies how to filter by update datetime */
export type UploadUpdatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: Maybe<Scalars["DateTime"]>;
  /** Exclude uploads with an exact match */
  neq?: Maybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: Maybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: Maybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: Maybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: Maybe<Scalars["DateTime"]>;
};

export type UploadVideoField = {
  __typename?: "UploadVideoField";
  duration: Scalars["Int"];
  framerate: Scalars["Int"];
  mp4Url?: Maybe<Scalars["String"]>;
  muxAssetId: Scalars["String"];
  muxPlaybackId: Scalars["String"];
  streamingUrl: Scalars["String"];
  thumbnailUrl: Scalars["String"];
};

export type UploadVideoFieldMp4UrlArgs = {
  res?: Maybe<VideoMp4Res>;
  exactRes?: Maybe<VideoMp4Res>;
};

export type UploadVideoFieldThumbnailUrlArgs = {
  format?: Maybe<MuxThumbnailFormatType>;
};

/** Specifies how to filter by width */
export type UploadWidthFilter = {
  /** Search all assets larger than the specified width */
  gt?: Maybe<Scalars["IntType"]>;
  /** Search all assets smaller than the specified width */
  lt?: Maybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified width */
  gte?: Maybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified width */
  lte?: Maybe<Scalars["IntType"]>;
  /** Search assets with the specified width */
  eq?: Maybe<Scalars["IntType"]>;
  /** Search assets that do not have the specified width */
  neq?: Maybe<Scalars["IntType"]>;
};

export type User = {
  __typename?: "User";
  urn: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
};

export type UsersSearchEdge = {
  __typename?: "UsersSearchEdge";
  node: User;
  cursor?: Maybe<Scalars["String"]>;
};

export type UsersSearchResponse = {
  __typename?: "UsersSearchResponse";
  totalCount?: Maybe<Scalars["Int"]>;
  edges: Array<UsersSearchEdge>;
};

export type UsState = {
  __typename?: "UsState";
  urn: Scalars["String"];
  name: Scalars["String"];
  code: Scalars["String"];
};

export type ValidatePhoneNumberResponse = {
  __typename?: "ValidatePhoneNumberResponse";
  number?: Maybe<Scalars["String"]>;
};

export type Video = {
  __typename?: "Video";
  urn: Scalars["String"];
  user: User;
  timeCreated: Scalars["DateTime"];
  timeUpdated: Scalars["DateTime"];
  orderItem: OrderItem;
  product: ProductRecord;
  firstName: FirstNameRecord;
  frontDoor?: Maybe<FrontDoorRecord>;
  hobby?: Maybe<HobbyRecord>;
  gift?: Maybe<GiftRecord>;
  pet1?: Maybe<PetRecord>;
  pet2?: Maybe<PetRecord>;
  country?: Maybe<Country>;
  streamUrl?: Maybe<Scalars["String"]>;
  ageYears?: Maybe<Scalars["Int"]>;
  childImageId?: Maybe<Scalars["String"]>;
  houseImageId?: Maybe<Scalars["String"]>;
};

export type VideoCreateInput = {
  firstNameId: Scalars["String"];
  ageYears?: Maybe<Scalars["Int"]>;
  giftId?: Maybe<Scalars["String"]>;
  frontDoorId?: Maybe<Scalars["String"]>;
  pet1Id?: Maybe<Scalars["String"]>;
  pet2Id?: Maybe<Scalars["String"]>;
  hobbyId?: Maybe<Scalars["String"]>;
  countryUrn?: Maybe<Scalars["String"]>;
  childImageId?: Maybe<Scalars["String"]>;
  houseImageId?: Maybe<Scalars["String"]>;
};

export enum VideoMp4Res {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export type VideoSearchFilterInput = {
  userUrn?: Maybe<Scalars["String"]>;
};

export type VideosSearchEdge = {
  __typename?: "VideosSearchEdge";
  node: Video;
  cursor?: Maybe<Scalars["String"]>;
};

export type VideosSearchResponse = {
  __typename?: "VideosSearchResponse";
  totalCount?: Maybe<Scalars["Int"]>;
  edges: Array<VideosSearchEdge>;
};

export type VideoUpdateInput = {
  streamId?: Maybe<Scalars["String"]>;
};

export type CallFragmentFragment = { __typename?: "Call" } & Pick<
  Call,
  | "urn"
  | "type"
  | "firstName"
  | "lastName"
  | "boyGirl"
  | "friend"
  | "hobby"
  | "pet1Name"
  | "pet2Name"
  | "ageYears"
  | "ageMonths"
  | "time"
  | "phoneNumber"
  | "timeCreated"
  | "gift"
  | "notes"
> & {
    pet1?: Maybe<{ __typename?: "PetRecord" } & Pick<PetRecord, "id" | "label">>;
    pet2?: Maybe<{ __typename?: "PetRecord" } & Pick<PetRecord, "id" | "label">>;
    frontDoor?: Maybe<{ __typename?: "FrontDoorRecord" } & Pick<FrontDoorRecord, "id" | "label">>;
    user: { __typename?: "User" } & Pick<User, "urn" | "email" | "firstName" | "lastName">;
    santa: { __typename?: "User" } & Pick<User, "urn" | "email" | "firstName" | "lastName">;
    timezone: { __typename?: "Timezone" } & Pick<Timezone, "urn" | "timezone">;
    phoneCountry?: Maybe<{ __typename?: "Country" } & Pick<Country, "urn" | "callingCode">>;
    orderItem: { __typename?: "OrderItem" } & Pick<OrderItem, "price"> & {
        addOnProducts: Array<
          { __typename?: "ProductRecord" } & Pick<ProductRecord, "alphaId" | "title" | "price">
        >;
        product: { __typename?: "ProductRecord" } & Pick<ProductRecord, "title">;
      };
  };

export type SearchCallsQueryVariables = Exact<{
  query?: Maybe<Scalars["String"]>;
  timeFrom?: Maybe<Scalars["String"]>;
  timeTo?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Int"]>;
  from?: Maybe<Scalars["Int"]>;
  santaUrn?: Maybe<Scalars["String"]>;
}>;

export type SearchCallsQuery = { __typename?: "Query" } & {
  calls: { __typename?: "CallsSearchResponse" } & Pick<CallsSearchResponse, "totalCount"> & {
      edges: Array<
        { __typename?: "CallsSearchEdge" } & Pick<CallsSearchEdge, "cursor"> & {
            node: { __typename?: "Call" } & CallFragmentFragment;
          }
      >;
    };
};

export type GetCallQueryVariables = Exact<{
  urn: Scalars["String"];
}>;

export type GetCallQuery = { __typename?: "Query" } & {
  call?: Maybe<{ __typename?: "Call" } & CallFragmentFragment>;
};

export type UpdateCallMutationVariables = Exact<{
  callUrn: Scalars["String"];
  userUrn: Scalars["String"];
  time: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  santaUrn: Scalars["String"];
  timezoneUrn: Scalars["String"];
  boyGirl: BoyGirl;
  ageMonths?: Maybe<Scalars["Int"]>;
  ageYears?: Maybe<Scalars["Int"]>;
  frontDoorId?: Maybe<Scalars["String"]>;
  gift?: Maybe<Scalars["String"]>;
  hobby?: Maybe<Scalars["String"]>;
  friend?: Maybe<Scalars["String"]>;
  pet1Id?: Maybe<Scalars["String"]>;
  pet1Name?: Maybe<Scalars["String"]>;
  pet2Id?: Maybe<Scalars["String"]>;
  pet2Name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
}>;

export type UpdateCallMutation = { __typename?: "Mutation" } & {
  updateCall: { __typename?: "Call" } & CallFragmentFragment;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = { __typename?: "Query" } & {
  countries: Array<{ __typename?: "Country" } & Pick<Country, "urn" | "name" | "callingCode">>;
};

export type GetFrontDoorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetFrontDoorsQuery = { __typename?: "Query" } & {
  frontDoors: Array<{ __typename?: "FrontDoorRecord" } & Pick<FrontDoorRecord, "id" | "label">>;
};

export type GetLetterPostscriptsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLetterPostscriptsQuery = { __typename?: "Query" } & {
  letterPostscripts: Array<
    { __typename?: "LetterPostscriptRecord" } & Pick<LetterPostscriptRecord, "id" | "label">
  >;
};

export type GetLetterSignaturesQueryVariables = Exact<{ [key: string]: never }>;

export type GetLetterSignaturesQuery = { __typename?: "Query" } & {
  letterSignatures: Array<
    { __typename?: "LetterSignatureRecord" } & Pick<LetterSignatureRecord, "id" | "label">
  >;
};

export type GetLetterTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetLetterTemplatesQuery = { __typename?: "Query" } & {
  letterTemplates: Array<
    { __typename?: "LetterTemplateRecord" } & Pick<LetterTemplateRecord, "id" | "label">
  >;
};

export type GetLetterQueryVariables = Exact<{
  urn: Scalars["String"];
}>;

export type GetLetterQuery = { __typename?: "Query" } & {
  letter?: Maybe<
    { __typename?: "Letter" } & Pick<
      Letter,
      | "urn"
      | "date"
      | "timeCreated"
      | "firstName"
      | "lastName"
      | "addressLine1"
      | "addressLine2"
      | "addressTown"
      | "addressCounty"
      | "addressPostcode"
      | "boyGirl"
      | "ageYears"
      | "ageMonths"
      | "hobby"
      | "gift"
      | "friend"
      | "pet1Name"
      | "pet2Name"
      | "psCustom"
    > & {
        user: { __typename?: "User" } & Pick<User, "urn" | "email" | "firstName" | "lastName">;
        signature: { __typename?: "LetterSignatureRecord" } & Pick<
          LetterSignatureRecord,
          "id" | "label"
        >;
        frontDoor?: Maybe<
          { __typename?: "FrontDoorRecord" } & Pick<FrontDoorRecord, "id" | "label">
        >;
        pet1?: Maybe<{ __typename?: "PetRecord" } & Pick<PetRecord, "id" | "label">>;
        pet2?: Maybe<{ __typename?: "PetRecord" } & Pick<PetRecord, "id" | "label">>;
        ps?: Maybe<
          { __typename?: "LetterPostscriptRecord" } & Pick<LetterPostscriptRecord, "id" | "label">
        >;
        template: { __typename?: "LetterTemplateRecord" } & Pick<
          LetterTemplateRecord,
          "id" | "label"
        >;
      }
  >;
};

export type SearchLettersQueryVariables = Exact<{
  query?: Maybe<Scalars["String"]>;
  products?: Maybe<Array<ProductAlphaId> | ProductAlphaId>;
  dateFrom?: Maybe<Scalars["String"]>;
  dateTo?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Int"]>;
}>;

export type SearchLettersQuery = { __typename?: "Query" } & {
  letters: { __typename?: "LettersSearchResponse" } & Pick<LettersSearchResponse, "totalCount"> & {
      edges: Array<
        { __typename?: "LettersSearchEdge" } & Pick<LettersSearchEdge, "cursor"> & {
            node: { __typename?: "Letter" } & Pick<
              Letter,
              "urn" | "timeCreated" | "firstName" | "lastName" | "date"
            > & {
                template: { __typename?: "LetterTemplateRecord" } & Pick<
                  LetterTemplateRecord,
                  "label"
                >;
                user: { __typename?: "User" } & Pick<
                  User,
                  "urn" | "email" | "firstName" | "lastName"
                >;
                orderItem: { __typename?: "OrderItem" } & Pick<OrderItem, "price"> & {
                    addOnProducts: Array<
                      { __typename?: "ProductRecord" } & Pick<
                        ProductRecord,
                        "alphaId" | "title" | "price"
                      >
                    >;
                    product: { __typename?: "ProductRecord" } & Pick<ProductRecord, "title">;
                  };
              };
          }
      >;
    };
};

export type SearchOrdersQueryVariables = Exact<{
  query?: Maybe<Scalars["String"]>;
  products?: Maybe<Array<ProductAlphaId> | ProductAlphaId>;
  dateFrom?: Maybe<Scalars["String"]>;
  dateTo?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Int"]>;
  from?: Maybe<Scalars["Int"]>;
}>;

export type SearchOrdersQuery = { __typename?: "Query" } & {
  orders: { __typename?: "OrdersSearchResponse" } & Pick<OrdersSearchResponse, "totalCount"> & {
      edges: Array<
        { __typename?: "OrdersSearchEdge" } & Pick<OrdersSearchEdge, "cursor"> & {
            node: { __typename?: "Order" } & Pick<Order, "urn" | "total" | "timeCreated"> & {
                user: { __typename?: "User" } & Pick<
                  User,
                  "urn" | "email" | "firstName" | "lastName"
                >;
                orderItems: Array<{ __typename?: "OrderItem" } & Pick<OrderItem, "productAlphaId">>;
                paymentTransaction?: Maybe<
                  { __typename?: "Transaction" } & Pick<
                    Transaction,
                    "urn" | "type" | "description" | "amount"
                  >
                >;
              };
          }
      >;
    };
};

export type GetOrderQueryVariables = Exact<{
  urn: Scalars["String"];
}>;

export type GetOrderQuery = { __typename?: "Query" } & {
  order?: Maybe<
    { __typename?: "Order" } & Pick<Order, "urn" | "total" | "timeCreated" | "promoDiscount"> & {
        user: { __typename?: "User" } & Pick<User, "urn" | "email" | "firstName" | "lastName">;
        orderItems: Array<
          { __typename?: "OrderItem" } & Pick<OrderItem, "productAlphaId" | "price"> & {
              addOnProducts: Array<{ __typename?: "ProductRecord" } & Pick<ProductRecord, "title">>;
              product: { __typename?: "ProductRecord" } & Pick<ProductRecord, "title">;
              letter?: Maybe<{ __typename?: "Letter" } & Pick<Letter, "urn">>;
              call?: Maybe<{ __typename?: "Call" } & Pick<Call, "urn">>;
              text?: Maybe<{ __typename?: "Text" } & Pick<Text, "urn">>;
              sack?: Maybe<{ __typename?: "Sack" } & Pick<Sack, "urn">>;
              video?: Maybe<{ __typename?: "Video" } & Pick<Video, "urn">>;
            }
        >;
        paymentTransaction?: Maybe<
          { __typename?: "Transaction" } & Pick<
            Transaction,
            "urn" | "type" | "description" | "amount"
          >
        >;
        promoCode?: Maybe<{ __typename?: "PromoCode" } & Pick<PromoCode, "code">>;
        refundTransactions: Array<
          { __typename?: "Transaction" } & Pick<
            Transaction,
            "urn" | "type" | "description" | "amount"
          >
        >;
      }
  >;
};

export type GetPetsQueryVariables = Exact<{
  ids?: Maybe<Array<Maybe<Scalars["ItemId"]>> | Maybe<Scalars["ItemId"]>>;
}>;

export type GetPetsQuery = { __typename?: "Query" } & {
  pets: Array<{ __typename?: "PetRecord" } & Pick<PetRecord, "id" | "label">>;
};

export type GetSantasQueryVariables = Exact<{ [key: string]: never }>;

export type GetSantasQuery = { __typename?: "Query" } & {
  santas: { __typename?: "UsersSearchResponse" } & {
    edges: Array<
      { __typename?: "UsersSearchEdge" } & Pick<UsersSearchEdge, "cursor"> & {
          node: { __typename?: "User" } & Pick<User, "urn" | "email" | "firstName" | "lastName">;
        }
    >;
  };
};

export type GetTimezonesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTimezonesQuery = { __typename?: "Query" } & {
  timezones: Array<{ __typename?: "Timezone" } & Pick<Timezone, "urn" | "timezone">>;
};

export const CallFragmentFragmentDoc = gql`
  fragment callFragment on Call {
    urn
    type
    firstName
    lastName
    boyGirl
    friend
    hobby
    pet1 {
      id
      label
    }
    pet1Name
    pet2 {
      id
      label
    }
    pet2Name
    ageYears
    ageMonths
    frontDoor {
      id
      label
    }
    time
    user {
      urn
      email
      firstName
      lastName
    }
    santa {
      urn
      email
      firstName
      lastName
    }
    timezone {
      urn
      timezone
    }
    phoneNumber
    phoneCountry {
      urn
      callingCode
    }
    orderItem {
      price
      addOnProducts {
        alphaId
        title
        price
      }
      product {
        title
      }
    }
    timeCreated
    gift
    notes
  }
`;
export const SearchCallsDocument = gql`
  query SearchCalls(
    $query: String
    $timeFrom: String
    $timeTo: String
    $size: Int
    $from: Int
    $santaUrn: String
  ) {
    calls(
      query: $query
      size: $size
      filter: { timeFrom: $timeFrom, timeTo: $timeTo, santaUrn: $santaUrn }
      sort: "time"
      from: $from
    ) {
      totalCount
      edges {
        cursor
        node {
          ...callFragment
        }
      }
    }
  }
  ${CallFragmentFragmentDoc}
`;

/**
 * __useSearchCallsQuery__
 *
 * To run a query within a React component, call `useSearchCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCallsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      timeFrom: // value for 'timeFrom'
 *      timeTo: // value for 'timeTo'
 *      size: // value for 'size'
 *      from: // value for 'from'
 *      santaUrn: // value for 'santaUrn'
 *   },
 * });
 */
export function useSearchCallsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SearchCallsQuery, SearchCallsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SearchCallsQuery, SearchCallsQueryVariables>(
    SearchCallsDocument,
    options,
  );
}
export function useSearchCallsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchCallsQuery, SearchCallsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SearchCallsQuery, SearchCallsQueryVariables>(
    SearchCallsDocument,
    options,
  );
}
export type SearchCallsQueryHookResult = ReturnType<typeof useSearchCallsQuery>;
export type SearchCallsLazyQueryHookResult = ReturnType<typeof useSearchCallsLazyQuery>;
export type SearchCallsQueryResult = ApolloReactCommon.QueryResult<
  SearchCallsQuery,
  SearchCallsQueryVariables
>;
export const GetCallDocument = gql`
  query GetCall($urn: String!) {
    call(callUrn: $urn) {
      ...callFragment
    }
  }
  ${CallFragmentFragmentDoc}
`;

/**
 * __useGetCallQuery__
 *
 * To run a query within a React component, call `useGetCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCallQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetCallQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetCallQuery, GetCallQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetCallQuery, GetCallQueryVariables>(GetCallDocument, options);
}
export function useGetCallLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCallQuery, GetCallQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetCallQuery, GetCallQueryVariables>(
    GetCallDocument,
    options,
  );
}
export type GetCallQueryHookResult = ReturnType<typeof useGetCallQuery>;
export type GetCallLazyQueryHookResult = ReturnType<typeof useGetCallLazyQuery>;
export type GetCallQueryResult = ApolloReactCommon.QueryResult<GetCallQuery, GetCallQueryVariables>;
export const UpdateCallDocument = gql`
  mutation UpdateCall(
    $callUrn: String!
    $userUrn: String!
    $time: String!
    $firstName: String!
    $lastName: String!
    $santaUrn: String!
    $timezoneUrn: String!
    $boyGirl: BoyGirl!
    $ageMonths: Int
    $ageYears: Int
    $frontDoorId: String
    $gift: String
    $hobby: String
    $friend: String
    $pet1Id: String
    $pet1Name: String
    $pet2Id: String
    $pet2Name: String
    $notes: String
  ) {
    updateCall(
      callUrn: $callUrn
      userUrn: $userUrn
      details: {
        time: $time
        firstName: $firstName
        lastName: $lastName
        santaUrn: $santaUrn
        timezoneUrn: $timezoneUrn
        boyGirl: $boyGirl
        ageMonths: $ageMonths
        ageYears: $ageYears
        frontDoorId: $frontDoorId
        gift: $gift
        hobby: $hobby
        friend: $friend
        pet1Id: $pet1Id
        pet1Name: $pet1Name
        pet2Id: $pet2Id
        pet2Name: $pet2Name
        notes: $notes
      }
    ) {
      ...callFragment
    }
  }
  ${CallFragmentFragmentDoc}
`;
export type UpdateCallMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCallMutation,
  UpdateCallMutationVariables
>;

/**
 * __useUpdateCallMutation__
 *
 * To run a mutation, you first call `useUpdateCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallMutation, { data, loading, error }] = useUpdateCallMutation({
 *   variables: {
 *      callUrn: // value for 'callUrn'
 *      userUrn: // value for 'userUrn'
 *      time: // value for 'time'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      santaUrn: // value for 'santaUrn'
 *      timezoneUrn: // value for 'timezoneUrn'
 *      boyGirl: // value for 'boyGirl'
 *      ageMonths: // value for 'ageMonths'
 *      ageYears: // value for 'ageYears'
 *      frontDoorId: // value for 'frontDoorId'
 *      gift: // value for 'gift'
 *      hobby: // value for 'hobby'
 *      friend: // value for 'friend'
 *      pet1Id: // value for 'pet1Id'
 *      pet1Name: // value for 'pet1Name'
 *      pet2Id: // value for 'pet2Id'
 *      pet2Name: // value for 'pet2Name'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateCallMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCallMutation,
    UpdateCallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCallMutation, UpdateCallMutationVariables>(
    UpdateCallDocument,
    options,
  );
}
export type UpdateCallMutationHookResult = ReturnType<typeof useUpdateCallMutation>;
export type UpdateCallMutationResult = ApolloReactCommon.MutationResult<UpdateCallMutation>;
export type UpdateCallMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCallMutation,
  UpdateCallMutationVariables
>;
export const GetCountriesDocument = gql`
  query GetCountries {
    countries {
      urn
      name
      callingCode
    }
  }
`;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export function useGetCountriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesQueryResult = ApolloReactCommon.QueryResult<
  GetCountriesQuery,
  GetCountriesQueryVariables
>;
export const GetFrontDoorsDocument = gql`
  query GetFrontDoors {
    frontDoors: allFrontDoors(first: 50, orderBy: [label_ASC]) {
      id
      label
    }
  }
`;

/**
 * __useGetFrontDoorsQuery__
 *
 * To run a query within a React component, call `useGetFrontDoorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFrontDoorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFrontDoorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFrontDoorsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetFrontDoorsQuery, GetFrontDoorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetFrontDoorsQuery, GetFrontDoorsQueryVariables>(
    GetFrontDoorsDocument,
    options,
  );
}
export function useGetFrontDoorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFrontDoorsQuery,
    GetFrontDoorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetFrontDoorsQuery, GetFrontDoorsQueryVariables>(
    GetFrontDoorsDocument,
    options,
  );
}
export type GetFrontDoorsQueryHookResult = ReturnType<typeof useGetFrontDoorsQuery>;
export type GetFrontDoorsLazyQueryHookResult = ReturnType<typeof useGetFrontDoorsLazyQuery>;
export type GetFrontDoorsQueryResult = ApolloReactCommon.QueryResult<
  GetFrontDoorsQuery,
  GetFrontDoorsQueryVariables
>;
export const GetLetterPostscriptsDocument = gql`
  query GetLetterPostscripts {
    letterPostscripts: allLetterPostscripts(first: 50, orderBy: [label_ASC]) {
      id
      label
    }
  }
`;

/**
 * __useGetLetterPostscriptsQuery__
 *
 * To run a query within a React component, call `useGetLetterPostscriptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLetterPostscriptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLetterPostscriptsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLetterPostscriptsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetLetterPostscriptsQuery,
    GetLetterPostscriptsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetLetterPostscriptsQuery, GetLetterPostscriptsQueryVariables>(
    GetLetterPostscriptsDocument,
    options,
  );
}
export function useGetLetterPostscriptsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLetterPostscriptsQuery,
    GetLetterPostscriptsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetLetterPostscriptsQuery,
    GetLetterPostscriptsQueryVariables
  >(GetLetterPostscriptsDocument, options);
}
export type GetLetterPostscriptsQueryHookResult = ReturnType<typeof useGetLetterPostscriptsQuery>;
export type GetLetterPostscriptsLazyQueryHookResult = ReturnType<
  typeof useGetLetterPostscriptsLazyQuery
>;
export type GetLetterPostscriptsQueryResult = ApolloReactCommon.QueryResult<
  GetLetterPostscriptsQuery,
  GetLetterPostscriptsQueryVariables
>;
export const GetLetterSignaturesDocument = gql`
  query GetLetterSignatures {
    letterSignatures: allLetterSignatures(first: 50, orderBy: [label_ASC]) {
      id
      label
    }
  }
`;

/**
 * __useGetLetterSignaturesQuery__
 *
 * To run a query within a React component, call `useGetLetterSignaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLetterSignaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLetterSignaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLetterSignaturesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetLetterSignaturesQuery,
    GetLetterSignaturesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetLetterSignaturesQuery, GetLetterSignaturesQueryVariables>(
    GetLetterSignaturesDocument,
    options,
  );
}
export function useGetLetterSignaturesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLetterSignaturesQuery,
    GetLetterSignaturesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetLetterSignaturesQuery, GetLetterSignaturesQueryVariables>(
    GetLetterSignaturesDocument,
    options,
  );
}
export type GetLetterSignaturesQueryHookResult = ReturnType<typeof useGetLetterSignaturesQuery>;
export type GetLetterSignaturesLazyQueryHookResult = ReturnType<
  typeof useGetLetterSignaturesLazyQuery
>;
export type GetLetterSignaturesQueryResult = ApolloReactCommon.QueryResult<
  GetLetterSignaturesQuery,
  GetLetterSignaturesQueryVariables
>;
export const GetLetterTemplatesDocument = gql`
  query GetLetterTemplates {
    letterTemplates: allLetterTemplates(first: 50, orderBy: [label_ASC]) {
      id
      label
    }
  }
`;

/**
 * __useGetLetterTemplatesQuery__
 *
 * To run a query within a React component, call `useGetLetterTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLetterTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLetterTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLetterTemplatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetLetterTemplatesQuery,
    GetLetterTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetLetterTemplatesQuery, GetLetterTemplatesQueryVariables>(
    GetLetterTemplatesDocument,
    options,
  );
}
export function useGetLetterTemplatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLetterTemplatesQuery,
    GetLetterTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetLetterTemplatesQuery, GetLetterTemplatesQueryVariables>(
    GetLetterTemplatesDocument,
    options,
  );
}
export type GetLetterTemplatesQueryHookResult = ReturnType<typeof useGetLetterTemplatesQuery>;
export type GetLetterTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetLetterTemplatesLazyQuery
>;
export type GetLetterTemplatesQueryResult = ApolloReactCommon.QueryResult<
  GetLetterTemplatesQuery,
  GetLetterTemplatesQueryVariables
>;
export const GetLetterDocument = gql`
  query GetLetter($urn: String!) {
    letter(letterUrn: $urn) {
      urn
      user {
        urn
        email
        firstName
        lastName
      }
      date
      timeCreated
      firstName
      lastName
      addressLine1
      addressLine2
      addressTown
      addressCounty
      addressPostcode
      boyGirl
      ageYears
      ageMonths
      hobby
      gift
      friend
      signature {
        id
        label
      }
      frontDoor {
        id
        label
      }
      pet1 {
        id
        label
      }
      pet1Name
      pet2 {
        id
        label
      }
      pet2Name
      ps {
        id
        label
      }
      psCustom
      template {
        id
        label
      }
    }
  }
`;

/**
 * __useGetLetterQuery__
 *
 * To run a query within a React component, call `useGetLetterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLetterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLetterQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetLetterQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetLetterQuery, GetLetterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetLetterQuery, GetLetterQueryVariables>(
    GetLetterDocument,
    options,
  );
}
export function useGetLetterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLetterQuery, GetLetterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetLetterQuery, GetLetterQueryVariables>(
    GetLetterDocument,
    options,
  );
}
export type GetLetterQueryHookResult = ReturnType<typeof useGetLetterQuery>;
export type GetLetterLazyQueryHookResult = ReturnType<typeof useGetLetterLazyQuery>;
export type GetLetterQueryResult = ApolloReactCommon.QueryResult<
  GetLetterQuery,
  GetLetterQueryVariables
>;
export const SearchLettersDocument = gql`
  query SearchLetters(
    $query: String
    $products: [ProductAlphaId!]
    $dateFrom: String
    $dateTo: String
    $size: Int
  ) {
    letters(
      query: $query
      size: $size
      filter: { products: $products, dateFrom: $dateFrom, dateTo: $dateTo }
    ) {
      totalCount
      edges {
        cursor
        node {
          urn
          timeCreated
          firstName
          lastName
          template {
            label
          }
          date
          user {
            urn
            email
            firstName
            lastName
          }
          orderItem {
            price
            addOnProducts {
              alphaId
              title
              price
            }
            product {
              title
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSearchLettersQuery__
 *
 * To run a query within a React component, call `useSearchLettersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLettersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLettersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      products: // value for 'products'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useSearchLettersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SearchLettersQuery, SearchLettersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SearchLettersQuery, SearchLettersQueryVariables>(
    SearchLettersDocument,
    options,
  );
}
export function useSearchLettersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SearchLettersQuery,
    SearchLettersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SearchLettersQuery, SearchLettersQueryVariables>(
    SearchLettersDocument,
    options,
  );
}
export type SearchLettersQueryHookResult = ReturnType<typeof useSearchLettersQuery>;
export type SearchLettersLazyQueryHookResult = ReturnType<typeof useSearchLettersLazyQuery>;
export type SearchLettersQueryResult = ApolloReactCommon.QueryResult<
  SearchLettersQuery,
  SearchLettersQueryVariables
>;
export const SearchOrdersDocument = gql`
  query SearchOrders(
    $query: String
    $products: [ProductAlphaId!]
    $dateFrom: String
    $dateTo: String
    $size: Int
    $from: Int
  ) {
    orders(
      query: $query
      size: $size
      filter: { products: $products, dateFrom: $dateFrom, dateTo: $dateTo }
      sort: "timeCreated"
      from: $from
    ) {
      totalCount
      edges {
        cursor
        node {
          urn
          total
          timeCreated
          user {
            urn
            email
            firstName
            lastName
          }
          orderItems {
            productAlphaId
          }
          paymentTransaction {
            urn
            type
            description
            amount
          }
        }
      }
    }
  }
`;

/**
 * __useSearchOrdersQuery__
 *
 * To run a query within a React component, call `useSearchOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOrdersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      products: // value for 'products'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      size: // value for 'size'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useSearchOrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SearchOrdersQuery, SearchOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SearchOrdersQuery, SearchOrdersQueryVariables>(
    SearchOrdersDocument,
    options,
  );
}
export function useSearchOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SearchOrdersQuery,
    SearchOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SearchOrdersQuery, SearchOrdersQueryVariables>(
    SearchOrdersDocument,
    options,
  );
}
export type SearchOrdersQueryHookResult = ReturnType<typeof useSearchOrdersQuery>;
export type SearchOrdersLazyQueryHookResult = ReturnType<typeof useSearchOrdersLazyQuery>;
export type SearchOrdersQueryResult = ApolloReactCommon.QueryResult<
  SearchOrdersQuery,
  SearchOrdersQueryVariables
>;
export const GetOrderDocument = gql`
  query GetOrder($urn: String!) {
    order(orderUrn: $urn) {
      urn
      total
      timeCreated
      user {
        urn
        email
        firstName
        lastName
      }
      orderItems {
        productAlphaId
        price
        addOnProducts {
          title
        }
        product {
          title
        }
        letter {
          urn
        }
        call {
          urn
        }
        text {
          urn
        }
        sack {
          urn
        }
        video {
          urn
        }
      }
      paymentTransaction {
        urn
        type
        description
        amount
      }
      promoCode {
        code
      }
      promoDiscount
      refundTransactions {
        urn
        type
        description
        amount
      }
    }
  }
`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetOrderQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetOrderQuery, GetOrderQueryVariables>(
    GetOrderDocument,
    options,
  );
}
export function useGetOrderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(
    GetOrderDocument,
    options,
  );
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = ApolloReactCommon.QueryResult<
  GetOrderQuery,
  GetOrderQueryVariables
>;
export const GetPetsDocument = gql`
  query GetPets($ids: [ItemId]) {
    pets: allPets(first: 50, orderBy: [label_ASC], filter: { id: { in: $ids } }) {
      id
      label
    }
  }
`;

/**
 * __useGetPetsQuery__
 *
 * To run a query within a React component, call `useGetPetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPetsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetPetsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPetsQuery, GetPetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetPetsQuery, GetPetsQueryVariables>(GetPetsDocument, options);
}
export function useGetPetsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPetsQuery, GetPetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetPetsQuery, GetPetsQueryVariables>(
    GetPetsDocument,
    options,
  );
}
export type GetPetsQueryHookResult = ReturnType<typeof useGetPetsQuery>;
export type GetPetsLazyQueryHookResult = ReturnType<typeof useGetPetsLazyQuery>;
export type GetPetsQueryResult = ApolloReactCommon.QueryResult<GetPetsQuery, GetPetsQueryVariables>;
export const GetSantasDocument = gql`
  query GetSantas {
    santas {
      edges {
        cursor
        node {
          urn
          email
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useGetSantasQuery__
 *
 * To run a query within a React component, call `useGetSantasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSantasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSantasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSantasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSantasQuery, GetSantasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetSantasQuery, GetSantasQueryVariables>(
    GetSantasDocument,
    options,
  );
}
export function useGetSantasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSantasQuery, GetSantasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetSantasQuery, GetSantasQueryVariables>(
    GetSantasDocument,
    options,
  );
}
export type GetSantasQueryHookResult = ReturnType<typeof useGetSantasQuery>;
export type GetSantasLazyQueryHookResult = ReturnType<typeof useGetSantasLazyQuery>;
export type GetSantasQueryResult = ApolloReactCommon.QueryResult<
  GetSantasQuery,
  GetSantasQueryVariables
>;
export const GetTimezonesDocument = gql`
  query GetTimezones {
    timezones {
      urn
      timezone
    }
  }
`;

/**
 * __useGetTimezonesQuery__
 *
 * To run a query within a React component, call `useGetTimezonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimezonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimezonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTimezonesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetTimezonesQuery, GetTimezonesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetTimezonesQuery, GetTimezonesQueryVariables>(
    GetTimezonesDocument,
    options,
  );
}
export function useGetTimezonesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTimezonesQuery,
    GetTimezonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetTimezonesQuery, GetTimezonesQueryVariables>(
    GetTimezonesDocument,
    options,
  );
}
export type GetTimezonesQueryHookResult = ReturnType<typeof useGetTimezonesQuery>;
export type GetTimezonesLazyQueryHookResult = ReturnType<typeof useGetTimezonesLazyQuery>;
export type GetTimezonesQueryResult = ApolloReactCommon.QueryResult<
  GetTimezonesQuery,
  GetTimezonesQueryVariables
>;
