import { GetPetsDocument, GetPetsQuery, GetPetsQueryVariables } from "../../../../types/graphql";

import {
  AnyQueryArgs,
  ProviderRequestMethod,
  GetListQueryResolver,
  IRecordObject,
  GetManyQueryResolver,
  IProviderGetManyVariables,
} from "../types";

export interface IPetRecord extends IRecordObject {
  label: string;
}

const listQuery: GetListQueryResolver<GetPetsQuery, IPetRecord> = () => ({
  query: GetPetsDocument,
  variables: {},
  parseResponse: ({ data: { pets } }) => ({
    data: pets.map(({ id, label }) => ({ id, label: label || "" })),
    total: pets.length || 0,
  }),
});

const getManyQuery: GetManyQueryResolver<GetPetsQuery, IPetRecord, GetPetsQueryVariables> =
  query => ({
    query: GetPetsDocument,
    variables: {
      ids: query.ids,
    },
    parseResponse: ({ data: { pets } }) => ({
      data: pets.map(({ id, label }) => ({ id, label: label || "" })),
      total: pets.length || 0,
    }),
  });

export const petQuery = (
  fetchType: ProviderRequestMethod,
  variables: IProviderGetManyVariables,
): AnyQueryArgs => {
  switch (fetchType) {
    case ProviderRequestMethod.GET_LIST:
      return listQuery();
    case ProviderRequestMethod.GET_MANY:
      return getManyQuery(variables);
    default:
      throw new Error(`fetchType ${fetchType} not recognised for calls`);
  }
};
