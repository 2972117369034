import { Typography } from "@material-ui/core";
import { useRecordContext } from "react-admin";

interface IRecord {
  age: {
    years?: number;
    months?: number;
  };
}

export const AgeField: React.FC = () => {
  const record: IRecord = useRecordContext();

  if (!record) {
    return null;
  }

  const {
    age: { years, months },
  } = record;

  return (
    <Typography component="span" variant="body2">
      {years && `${years}y`} {months && `${months}m`}
    </Typography>
  );
};
