import React from "react";
import { TopToolbar, Show, DateField, TextField, Labeled, EditButton } from "react-admin";
import { CardContent, Grid, GridSize, Typography } from "@material-ui/core";

import { UserField, CallTypeField, BoyGirlField, AgeField, PetField, PhoneField } from "../fields";
import { ICallRecord } from "../../model/graphql/adaptor/call/record";
import { CallType } from "../../types/graphql";
import { UserGroups } from "../../model/auth-provider";

const Cell: React.FC<{ label: string; size?: GridSize }> = ({ label, children, size = 6 }) => {
  const child: React.ReactNode = label ? <Labeled label={label}>{children}</Labeled> : children;
  return (
    <Grid item md={size}>
      {child}
    </Grid>
  );
};

const Form: React.FC<{ record?: ICallRecord }> = ({ record }) => (
  <CardContent>
    <Typography variant="h6" gutterBottom>
      Call Details
    </Typography>
    <Grid container>
      <Cell label="Customer">
        <UserField />
      </Cell>
      <Cell label="Type">
        <CallTypeField />
      </Cell>
      <Cell label="Call time (local to this computer)">
        <DateField source="time" showTime />
      </Cell>
      <Cell label="Call time (local to recipient)">
        <DateField source="timeLocal" showTime />
      </Cell>
      <Cell label="Recipient time zone">
        <TextField source="timezone.timezone" />
      </Cell>
      {record?.type === CallType.AUDIO && (
        <Cell label="Phone number">
          <PhoneField />
        </Cell>
      )}
    </Grid>

    <Typography variant="h6" gutterBottom>
      Personalisation
    </Typography>
    <Grid container>
      <Cell label="First name">
        <TextField source="name.first" />
      </Cell>
      <Cell label="Last name">
        <TextField source="name.last" />
      </Cell>
      <Cell label="Boy / Girl">
        <BoyGirlField />
      </Cell>
      <Cell label="Age">
        <AgeField />
      </Cell>
      {record?.hobby && (
        <Cell label="Hobby">
          <TextField source="hobby" />
        </Cell>
      )}
      {record?.gift && (
        <Cell label="Gift">
          <TextField source="gift" />
        </Cell>
      )}
      {record?.friend && (
        <Cell label="Friend">
          <TextField source="friend" />
        </Cell>
      )}
      {record?.frontDoor && (
        <Cell label="Front door">
          <TextField source="frontDoor.label" />
        </Cell>
      )}
      {record?.pet1 && (
        <Cell label="Pet 1">
          <PetField source="pet1" />
        </Cell>
      )}
      {record?.pet2 && (
        <Cell label="Pet 2">
          <PetField source="pet2" />
        </Cell>
      )}
      {record?.notes && (
        <Cell label="Notes" size={12}>
          <TextField source="notes" />
        </Cell>
      )}
    </Grid>
  </CardContent>
);

interface IActionProps {
  permissions?: string[];
  basePath?: string;
  data?: ICallRecord;
  resource?: string;
}

const Actions: React.FC<IActionProps> = ({ permissions, basePath, data, resource }) => (
  <TopToolbar>
    {permissions?.includes(UserGroups.ADMINS) && (
      <EditButton basePath={basePath} record={data} resource={resource} />
    )}
  </TopToolbar>
);

interface IProps {
  permissions?: string[];
}

export const CallShow: React.FC<IProps> = props => (
  <Show actions={<Actions permissions={props.permissions} />} {...props}>
    <Form {...props} />
  </Show>
);
