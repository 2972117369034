import { GetLetterSignaturesDocument, GetLetterSignaturesQuery } from "../../../../types/graphql";
import { AnyQueryArgs, ProviderRequestMethod, GetListQueryResolver, IRecordObject } from "../types";

export interface ILetterSignatureRecord extends IRecordObject {
  label: string;
}

const listQuery: GetListQueryResolver<GetLetterSignaturesQuery, ILetterSignatureRecord> = () => ({
  query: GetLetterSignaturesDocument,
  variables: {},
  parseResponse: ({ data }) => ({
    data: data.letterSignatures.map(({ id, label }) => ({ id, label: label || "" })),
    total: data.letterSignatures.length,
  }),
});

export const letterSignatureQuery = (fetchType: ProviderRequestMethod): AnyQueryArgs => {
  switch (fetchType) {
    case ProviderRequestMethod.GET_LIST:
      return listQuery();
    default:
      throw new Error(`fetchType ${fetchType} not recognised for calls`);
  }
};
