import { extractIdFromUrn, createUrn, UrnResource } from "@santa/common/lib/utils/urn";
import * as datefns from "date-fns";

import {
  GetOrderQueryVariables,
  GetOrderQuery,
  GetOrderDocument,
} from "../../../../../types/graphql";
import { IOrderRecord, createBraintreeUrl, getProductLink, getProductId } from "../records";
import { ProductAlphaId } from "../../../../../types/graphql";
import { GetOneQueryResolver } from "../../types";

/**
 * Get order query
 * @param variables query variables
 */
export const getOrderQuery: GetOneQueryResolver<
  GetOrderQuery,
  IOrderRecord,
  GetOrderQueryVariables
> = ({ id }) => ({
  query: GetOrderDocument,
  variables: {
    urn: createUrn(UrnResource.ORDER, id),
  },
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  parseResponse: response => {
    const { order } = response.data;

    if (!order) {
      return Promise.reject(new Error("Order not found"));
    }

    return {
      data: {
        id: extractIdFromUrn(UrnResource.ORDER, order.urn),
        urn: order.urn,
        user: {
          urn: order.user.urn,
          email: order.user.email,
          name: {
            first: order.user.firstName || "",
            last: order.user.lastName || "",
          },
        },
        total: order.total,
        products: order.orderItems.map(i => ({
          alphaId: i.productAlphaId as ProductAlphaId,
          link: getProductLink(i),
          title: i.product?.title || "-",
          id: getProductId(i),
          price: i.price,
          addOns: i.addOnProducts.map(a => a.title || "-"),
        })),
        ...(order.paymentTransaction && {
          payment: {
            url: createBraintreeUrl(order.paymentTransaction.urn),
            type: order.paymentTransaction.type,
            description: order.paymentTransaction.description,
            amount: order.paymentTransaction.amount,
          },
        }),
        ...(order.promoCode &&
          order.promoDiscount && {
            promoCode: {
              code: order.promoCode.code,
              discount: order.promoDiscount,
            },
          }),
        refunds: order.refundTransactions.map(r => ({
          url: createBraintreeUrl(r.urn),
          type: r.type,
          description: r.description,
          amount: r.amount,
        })),
        timeCreated: datefns.parseISO(order.timeCreated),
      },
    };
  },
});
